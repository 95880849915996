import { capitalizeText } from '../utils/utils';
import MarkdownComponent from './MarkdownComponent';
import ArrowForwardIosSharpIcon from '@mui/icons-material/ArrowForwardIosSharp';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import * as React from 'react';

const Accordion = styled(props => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled(props => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: '0.9rem' }} />}
    sx={{ minHeight: '40px' }}
    {...props}
  />
))(({ theme }) => ({
  backgroundColor:
    theme.palette.mode === 'dark'
      ? 'rgba(255, 255, 255, .05)'
      : 'rgba(0, 0, 0, .03)',
  flexDirection: 'row-reverse',
  '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
    transform: 'rotate(90deg)',
  },
  '& .MuiAccordionSummary-content': {
    marginLeft: theme.spacing(1),
    fontWeight: 600,
  },
  '& .MuiAccordionSummary-content > p': {
    fontWeight: 500,
  },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

function JsonToAccordion({ data }) {
  if (!data) {
    return null;
  }

  let happinessEvaluation = '| Aspect | Score |\n';
  happinessEvaluation += '| --- | --- |\n';
  for (const key in data.happinessEvaluation) {
    if (key.toLowerCase() === 'score') {
      continue;
    }
    const aspect = capitalizeText(key.replace(/_/g, ' ')) + '   ';
    const score = data.happinessEvaluation[key];
    happinessEvaluation += `| ${aspect} | ${score} |\n`;
  }

  return (
    <>
      {/* Customer Question */}
      <Accordion expanded={true}>
        <AccordionSummary>
          <Typography fontSize={'0.8rem !important'}>
            Customer Inquiry
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownComponent
            style={{ color: 'black' }}
            content={data.question || data.subject}
          />
        </AccordionDetails>
      </Accordion>

      {/* Intent Issue */}
      <Accordion expanded={true}>
        <AccordionSummary>
          <Typography fontSize={'0.8rem !important'}>
            Problem summary
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownComponent
            style={{ color: 'black' }}
            content={`${capitalizeText(data.intentIssue)}\n `}
          />
        </AccordionDetails>
      </Accordion>

      {/* Intent Category */}
      <Accordion expanded={true}>
        <AccordionSummary>
          <Typography fontSize={'0.8rem !important'}>Issue Type</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownComponent
            style={{ color: 'black' }}
            content={`${capitalizeText(data.intentCategory)}\n `}
          />
        </AccordionDetails>
      </Accordion>

      {/* Intent Feature */}
      <Accordion expanded={true}>
        <AccordionSummary>
          <Typography fontSize={'0.8rem !important'}>
            Product/feature identified
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownComponent
            style={{ color: 'black' }}
            content={`${capitalizeText(data.intentFeature)}\n `}
          />
        </AccordionDetails>
      </Accordion>

      {/* Happiness Evaluation */}
      <Accordion expanded={true}>
        <AccordionSummary>
          <Typography fontSize={'0.8rem !important'}>
            Agent Assessment
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownComponent
            style={{ color: 'black' }}
            content={'Aspect Score Breakdown:'}
          />
          <MarkdownComponent
            style={{ color: 'black' }}
            content={happinessEvaluation}
          />
          <br />
          <MarkdownComponent
            style={{ color: 'black' }}
            content={`\n\n${data.happinessReason || ''}`}
          />
        </AccordionDetails>
      </Accordion>

      {/* Pain Point */}
      <Accordion expanded={true}>
        <AccordionSummary>
          <Typography fontSize={'0.8rem !important'}>
            Customer Pain Points
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownComponent
            style={{ color: 'black' }}
            content={`We were able to identify the pain-point belonging to **${data.painPointCategory}** category.`}
          />
          <br />
          <MarkdownComponent
            style={{ color: 'black' }}
            content={`\n\n${data.painPoint}`}
          />
        </AccordionDetails>
      </Accordion>

      {/* Support Improvement */}
      <Accordion expanded={true}>
        <AccordionSummary>
          <Typography fontSize={'0.8rem !important'}>
            Areas for Support Agent Improvement
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownComponent
            style={{ color: 'black' }}
            content={`We were able to identify the improvements belonging to **${data.supportImprovementCategory}** category.`}
          />
          <br />
          <MarkdownComponent
            style={{ color: 'black' }}
            content={data.supportImprovement}
          />
        </AccordionDetails>
      </Accordion>

      {/* Feature Improvement */}
      <Accordion expanded={true}>
        <AccordionSummary>
          <Typography fontSize={'0.8rem !important'}>
            Product/Feature Enhancement Opportunities
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <MarkdownComponent
            style={{ color: 'black' }}
            content={`We were able to identify the improvements belonging to **${data.featureImprovementCategory}** category.`}
          />
          <br />
          <MarkdownComponent
            style={{ color: 'black' }}
            content={data.featureImprovement}
          />
        </AccordionDetails>
      </Accordion>
    </>
  );
}

export default JsonToAccordion;
