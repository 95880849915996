import { Typography } from '@mui/material';
import React from 'react';

// import Typewriter from "typewriter-effect";

const InsightsPageEmptyState = () => {
  return (
    <div>
      <Typography
        component={'div'}
        className='subsubheader'
        mb={2}
        mt={2}
        ml={2}
      >
        Kwazii automatically surfaces trends, patterns, and opportunities in
        easy-to-interpret visualizations. Filter by time period, product, team -
        whatever you need to pinpoint insights.
      </Typography>
    </div>
  );
};

export default InsightsPageEmptyState;
