import feedback from '../service/feedback';
import AutoScrollContainer from './AutoScrollContainer';
import MarkdownComponent from './MarkdownComponent';
import TypingIndicator from './TypingIndicator';
import { ThumbDownOutlined, ThumbUpOutlined } from '@mui/icons-material';
import { CopyAll } from '@mui/icons-material';
import { Box, Button, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

const ContainerBox = ({
  conversationId,
  results,
  adjustHeight,
  heightRef,
  onSuggestionClick,
  section,
  hideFeedback,
}) => {
  const containerRef = useRef(null);
  const [copySuccess, setCopySuccess] = useState(false);
  const [thankYouShown, setThankYouShown] = useState({});

  const handleThumbsUp = async event => {
    const answerIndex = event.currentTarget.value;
    await feedback.submit2(conversationId, 1, answerIndex, section);
    setThankYouShown({
      ...thankYouShown,
      [answerIndex]: true,
    });
  };

  const handleThumbsDown = async event => {
    const answerIndex = event.currentTarget.value;
    await feedback.submit2(conversationId, 0, answerIndex, section);
    setThankYouShown({
      ...thankYouShown,
      [answerIndex]: true,
    });
  };

  useEffect(() => {
    setThankYouShown({});
    if (adjustHeight) {
      const updateHeight = () => {
        containerRef.current.style.height = `${
          Math.min(window.outerHeight, window.innerHeight) -
          containerRef.current.offsetTop -
          heightRef.current?.clientHeight
        }px`;
      };

      const observer = new ResizeObserver(updateHeight);
      observer.observe(heightRef.current);

      window.addEventListener('resize', updateHeight);
      return () => {
        observer.disconnect();
        window.removeEventListener('resize', updateHeight);
      };
    }
  }, [adjustHeight, heightRef]);

  return (
    <Box flexGrow={1} ref={containerRef} className='container-box'>
      {results?.length > 0 && (
        <AutoScrollContainer>
          {results
            .filter(result => !!result.question)
            .map((result, index) => (
              <div key={index} className='message-container'>
                <div className='question-box'>
                  <MarkdownComponent content={result.question} />
                </div>
                <div className='answer-box'>
                  {result.answer ? (
                    <>
                      <MarkdownComponent content={result.answer} />
                      <Box
                        display='flex'
                        justifyContent='flex-end'
                        className='feedback-buttons'
                      >
                        <CopyToClipboard
                          text={result.answer}
                          onCopy={() => {
                            setCopySuccess(true);
                            console.log(copySuccess);
                          }}
                        >
                          <Button
                            className='feedback-btn'
                            variant='text'
                            size='small'
                          >
                            <CopyAll />
                          </Button>
                        </CopyToClipboard>
                        {hideFeedback ? (
                          <></>
                        ) : thankYouShown[index] ? (
                          <Typography
                            variant='h5'
                            style={{ margin: '10px', textAlign: 'center' }}
                          >
                            Thank you for your feedback!
                          </Typography>
                        ) : (
                          <>
                            <Button
                              value={index}
                              className='feedback-btn'
                              variant='text'
                              size='small'
                              onClick={handleThumbsUp}
                            >
                              <ThumbUpOutlined />
                            </Button>
                            <Button
                              value={index}
                              className='feedback-btn'
                              variant='text'
                              size='small'
                              onClick={handleThumbsDown}
                            >
                              <ThumbDownOutlined />
                            </Button>
                          </>
                        )}
                      </Box>
                    </>
                  ) : (
                    <TypingIndicator />
                  )}
                </div>
                <div className='suggest-buttons'>
                  {result.suggestions?.map(suggestion => (
                    <Button
                      className='suggest-button-base suggest-button'
                      id={suggestion}
                      value={index}
                      onClick={onSuggestionClick}
                    >
                      {suggestion}
                    </Button>
                  ))}
                </div>
              </div>
            ))}
        </AutoScrollContainer>
      )}
    </Box>
  );
};

export default ContainerBox;
