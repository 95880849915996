import React, { useEffect, useRef } from 'react';

function AutoScrollContainer({ children }) {
  const containerRef = useRef(null);
  const parentRef = useRef(null);

  useEffect(() => {
    const scrollToBottom = () => {
      const { scrollTop, scrollHeight, clientHeight } = parentRef.current;
      if (Math.ceil(scrollTop + clientHeight) >= scrollHeight * 0.8) {
        parentRef.current.scrollTop = parentRef.current.scrollHeight;
      }
    };

    const containerElement = containerRef.current;
    const observer = new ResizeObserver(scrollToBottom);
    observer.observe(containerElement);

    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <div
      ref={parentRef}
      style={{
        overflowY: 'scroll',
        height: '100%',
      }}
    >
      <div ref={containerRef}>{children}</div>
    </div>
  );
}

export default AutoScrollContainer;
