import {
  FindInPageOutlined,
  InsightsOutlined,
  SearchOutlined,
  SupportAgentOutlined,
} from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NewApplicationPage = () => {
  const reAmazeApplications = [
    {
      id: 0,
      icon: (
        <InsightsOutlined
          sx={{
            width: '50px',
            height: '30px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Re:amaze: Data Insights',
      link: '/applications/new/historical_support_data?type=REAMAZE',
      description: 'Enable Analytics on historical conversation data',
    },
    {
      id: 1,
      icon: (
        <SearchOutlined
          sx={{
            width: '50px',
            height: '30px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Re:amaze: Knowledge Base Search ',
      link: '/applications/new/reamaze_search',
      description: 'Enable AI search on the top of your public knowledge base.',
    },
    {
      id: 2,
      icon: (
        <SupportAgentOutlined
          sx={{
            width: '50px',
            height: '30px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Re:amaze: Agent CoPilot',
      link: '/applications/new/reamaze_module',
      description: 'Enable Agent CoPilot',
    },
  ];

  // eslint-disable-next-line
  const customApplications = [
    {
      id: 0,
      icon: (
        <FindInPageOutlined
          sx={{
            width: '50px',
            height: '30px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Custom Knowledge Base Search ',
      link: '/applications/new/custom_search',
      description: 'Enable AI search on the top of your public knowledge base.',
    },
  ];

  const zendeskApplications = [
    {
      id: 0,
      icon: (
        <InsightsOutlined
          sx={{
            width: '50px',
            height: '30px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Zendesk: Data Insights',
      link: '/applications/new/historical_support_data?type=ZENDESK',
      description: 'Enable Analytics on historical conversation data',
    },
    {
      id: 1,
      icon: (
        <SearchOutlined
          sx={{
            width: '50px',
            height: '30px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Zendesk: Knowledge Base Search ',
      link: '/applications/new/zendesk_search',
      description: 'Enable AI search on the top of your public knowledge base.',
    },
  ];

  return (
    <Container maxWidth='100%'>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Link component={RouterLink} to='/applications' color='inherit'>
              Applications
            </Link>
            <Typography variant='h7' component='div'>
              New Application
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
          <Typography className='header' alignContent='center'>
            New Application
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography component={'div'} className='subsubheader'>
            Seamlessly integrate Kwazii AI with leading customer service
            providers
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography
            className='subheader2'
            alignContent='center'
            sx={{ display: 'flex', flexFlow: 'row' }}
          >
            <img
              alt='reamaze'
              src='/external/reamaze_logo.png'
              style={{
                width: '40px',
                height: '30px',
                paddingRight: '10px',
                color: '#444',
                display: 'inline',
              }}
            />
            Re:amaze
          </Typography>
        </Grid>

        {/* Data Source Cards */}
        {reAmazeApplications.map(application => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={application.id}>
            <Card
              sx={{ display: 'flex', flexDirection: 'column' }}
              component={RouterLink}
              to={application.link}
              color='inherit'
            >
              <CardContent
                sx={{ height: '150px', display: 'flex', flexFlow: 'row' }}
              >
                <div style={{ alignSelf: 'center' }}>{application.icon}</div>
                <Link
                  style={{ alignSelf: 'center' }}
                  component={RouterLink}
                  to={application.link}
                  color='inherit'
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography className='card-title'>
                      {application.title}
                    </Typography>
                  </Box>
                  <Typography variant='body2' color='text.secondary'>
                    {application.description}
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}

        <Grid item xs={12}>
          <br />
          <Typography
            className='subheader2'
            alignContent='center'
            sx={{ display: 'flex', flexFlow: 'row' }}
          >
            <img
              alt='zendesk'
              src='/external/zendesk_logo.png'
              style={{
                width: '40px',
                height: '30px',
                paddingRight: '10px',
                color: '#444',
                display: 'inline',
              }}
            />
            Zendesk
          </Typography>
        </Grid>

        {/* Data Source Cards */}
        {zendeskApplications.map(application => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={application.id}>
            <Card
              sx={{ display: 'flex', flexDirection: 'column' }}
              component={RouterLink}
              to={application.link}
              color='inherit'
            >
              <CardContent
                sx={{ height: '150px', display: 'flex', flexFlow: 'row' }}
              >
                <div style={{ alignSelf: 'center' }}>{application.icon}</div>
                <Link
                  style={{ alignSelf: 'center' }}
                  component={RouterLink}
                  to={application.link}
                  color='inherit'
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography className='card-title'>
                      {application.title}
                    </Typography>
                  </Box>
                  <Typography variant='body2' color='text.secondary'>
                    {application.description}
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}

        {/* <Grid item xs={12}>
          <br />
          <Typography className='subheader2' alignContent="center">
            Custom
          </Typography>
        </Grid> */}

        {/* Data Source Cards */}
        {/* {customApplications.map((application) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={application.id}>
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent sx={{ height: "150px", display: 'flex', flexFlow: 'row' }}>
                <div style={{ alignSelf: "center" }}>{application.icon}</div>
                <Link style={{ alignSelf: "center" }} component={RouterLink} to={application.link} color="inherit">
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography className='card-title'>
                      {application.title}
                    </Typography>
                  </Box>
                  <Typography variant="body2" color="text.secondary">
                    {application.description}
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))} */}
      </Grid>
    </Container>
  );
};

export default NewApplicationPage;
