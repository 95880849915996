import AlphaNumericTextField from '../../components/AlphaNumericTextField';
import TooltipIcon from '../../components/TooltipIcon';
import datasources from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NewS3DataSourcePage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [sourceBucketName, setSourceBucketName] = useState('');
  const [sourceFolderName, setSourceFolderName] = useState('');
  const [sourceRegion, setSourceRegion] = useState('');
  const [sourceAccessKeyId, setSourceAccessKeyId] = useState('');
  const [sourceSecretAccessKey, setSourceSecretAccessKey] = useState('');
  const [cronSchedule, setCronSchedule] = useState('once');

  const handleSubmit = event => {
    event.preventDefault();

    setIsSubmitting(true);
    console.log('Cron Schedule:', cronSchedule);

    datasources
      .create({
        name: projectName,
        type: 'S3',
        cronSchedule: cronSchedule,
        connectionInfo: {
          sourceBucketName: sourceBucketName,
          sourceFolderName: sourceFolderName,
          sourceRegion: sourceRegion,
          sourceAccessKeyId: sourceAccessKeyId,
          sourceSecretAccessKey: sourceSecretAccessKey,
        },
      })
      .then(() => {
        toastSuccess('Data source successfully created');

        window.location.href = '/data-sources';
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to create data source');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Container className='newDSMuiContainer-root'>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
        <Link component={RouterLink} to='/' color='inherit'>
          Home
        </Link>
        <Link component={RouterLink} to='/data-sources' color='inherit'>
          Data Sources
        </Link>
        <Link component={RouterLink} to='/data-sources/new' color='inherit'>
          New Data Sources
        </Link>
        <Typography variant='body1' color='text.primary'>
          S3
        </Typography>
      </Breadcrumbs>
      <Grid item xs={12}>
        <Typography className='header' alignContent='center'>
          S3
        </Typography>
      </Grid>

      <Grid container xs={12}>
        <Grid item xs={12} sm={6} md={6} lg={5} xl={5} mb={4}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
              <Typography>Name</Typography>
              <AlphaNumericTextField
                required
                fullWidth
                name='projectName'
                placeholder='My data source'
                sx={{ mb: 2 }}
                value={projectName}
                onChange={event => setProjectName(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>
                Bucket Name
                <TooltipIcon
                  timeout={2500}
                  tooltipText={'S3 bucket name'}
                  linkUrl={
                    'https://docs.aws.amazon.com/AmazonS3/latest/userguide/UsingBucket.html'
                  }
                />
              </Typography>
              <TextField
                required
                fullWidth
                name='sourceBucketName'
                placeholder='S3 bucket name'
                helperText='S3 bucket name'
                sx={{ mb: 2 }}
                value={sourceBucketName}
                onChange={event => setSourceBucketName(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>
                Folder Name
                <TooltipIcon
                  timeout={2500}
                  tooltipText={'S3 folder name'}
                  linkUrl={
                    'https://docs.aws.amazon.com/AmazonS3/latest/userguide/using-folders.html'
                  }
                />
              </Typography>
              <TextField
                required
                fullWidth
                name='sourceFolderName'
                placeholder='S3 folder name'
                helperText='S3 folder name'
                sx={{ mb: 2 }}
                value={sourceFolderName}
                onChange={event => setSourceFolderName(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>
                Region
                <TooltipIcon
                  timeout={2500}
                  tooltipText={'S3 folder name'}
                  linkUrl={
                    'https://docs.aws.amazon.com/AmazonS3/latest/userguide/using-folders.html'
                  }
                />
              </Typography>
              <TextField
                required
                fullWidth
                name='sourceRegion'
                placeholder='S3 folder name'
                helperText='S3 folder name'
                sx={{ mb: 2 }}
                value={sourceRegion}
                onChange={event => setSourceRegion(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>
                Access key ID
                <TooltipIcon
                  timeout={2500}
                  tooltipText={`AWS accessKeyId
                            {
                                "Version": "2012-10-17",
                                "Statement": [
                                    {
                                        "Effect": "Allow",
                                        "Action": "s3:GetObject",
                                        "Resource": "arn:aws:s3:::example-bucket/example-project/*"
                                    },
                                    {
                                        "Effect": "Deny",
                                        "Action": "s3:GetObject",
                                        "NotResource": "arn:aws:s3:::example-bucket/example-project/*"
                                    }
                                ]
                            }
                            `}
                  linkUrl={
                    'https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html'
                  }
                />
              </Typography>
              <TextField
                required
                fullWidth
                name='sourceAccessKeyId'
                placeholder='AWS accessKeyId'
                helperText='AWS accessKeyId'
                type='password'
                sx={{ mb: 2 }}
                value={sourceAccessKeyId}
                onChange={event => setSourceAccessKeyId(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>
                Secret access key
                <TooltipIcon
                  timeout={2500}
                  tooltipText={'AWS secretAccessKey'}
                  linkUrl={
                    'https://docs.aws.amazon.com/IAM/latest/UserGuide/id_credentials_access-keys.html'
                  }
                />
              </Typography>
              <TextField
                required
                fullWidth
                name='sourceSecretAccessKey'
                placeholder='AWS secretAccessKey'
                helperText='AWS secretAccessKey'
                type='password'
                sx={{ mb: 2 }}
                value={sourceSecretAccessKey}
                onChange={event => setSourceSecretAccessKey(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>Cron schedule</Typography>
              <Select
                labelId='cron-schedule-label'
                name='cronSchedule'
                value={cronSchedule}
                onChange={event => setCronSchedule(event.target.value)}
                disabled={isSubmitting}
              >
                <MenuItem value='once'>Once</MenuItem>
                <MenuItem value='daily'>Daily</MenuItem>
                <MenuItem value='weekly'>Weekly</MenuItem>
                <MenuItem value='monthly'>Monthly</MenuItem>
              </Select>
              <FormHelperText sx={{ mb: 2 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;The cron schedule determines when the
                scraping task will run.
              </FormHelperText>

              <Button
                type='submit'
                className='createButton'
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Create'}
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} xl={3}>
          &nbsp;
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewS3DataSourcePage;
