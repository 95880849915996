import './fonts/inter.css';
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#444', // Customize the primary color
    },
    secondary: {
      main: '#ff9800', // Customize the secondary color
    },
    background: {
      default: '#ffffff', // Customize the default background color
    },
  },
  typography: {
    fontFamily: 'Inter var',
    fontSize: '0.9rem',
  },
  // overrides: {
  //     MuiButton: {
  //         root: {
  //             borderRadius: '8px', // Customize button border radius
  //             textTransform: 'none', // Disable button text capitalization
  //         },
  //         containedPrimary: {
  //             color: '#fff', // Customize the text color of primary contained button
  //         },
  //         containedSecondary: {
  //             color: '#fff', // Customize the text color of secondary contained button
  //         },
  //     },
  //     MuiTextField: {
  //         root: {
  //             borderRadius: '8px', // Customize text field border radius
  //         },
  //     },
  //     MuiAppBar: {
  //         root: {
  //             boxShadow: 'none', // Remove app bar box shadow
  //         },
  //     },
  //     MuiDrawer: {
  //         paper: {
  //             boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.1)', // Customize drawer box shadow
  //         },
  //     },
  //     MuiTableCell: {
  //         head: {
  //             fontWeight: 'bold', // Add font weight to table cell headers
  //         },
  //     },
  //     MuiTablePagination: {
  //         select: {
  //             borderRadius: '4px', // Customize table pagination select border radius
  //         },
  //     },
  //     MuiTabs: {
  //         indicator: {
  //             height: '4px', // Customize the height of the tabs indicator
  //         },
  //     },

  //     MuiPaper: {
  //         styleOverrides: {
  //             root: {
  //                 paddingLeft: '30px'
  //             },
  //         },
  //     },
  // },
});

// theme.spacing(1);

export default theme;
