import datasources from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
import ReamazeHelpPane from './components/ReamazeHelpPane';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

const EditReamazeSupportDataSourcePage = () => {
  const { datasourceId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitType, setSubmitType] = useState('');
  const [datasourceName, setDatasourceName] = useState('');
  const [brand, setBrand] = useState('');
  const [reamazeEmail, setReamazeEmail] = useState('');
  const [reamazeAPIToken, setReamazeAPIToken] = useState('');
  const [cronSchedule, setCronSchedule] = useState('once');

  // Fetch datasources
  useEffect(() => {
    datasources
      .get(datasourceId)
      .then(datasource => {
        setDatasourceName(datasource.name);
        setBrand(datasource.connectionInfo.brand);
        setReamazeEmail(datasource.connectionInfo.reamazeEmail);
        setReamazeAPIToken(datasource.connectionInfo.reamazeAPIToken);
        setCronSchedule(datasource.cronSchedule);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch data sources');
      });
  }, [datasourceId]);

  const handleSubmit = event => {
    event.preventDefault();

    if (submitType === 'delete') {
      const result = window.confirm('Are you sure you want to proceed?');
      if (result) {
        setIsSubmitting(true);

        datasources
          .remove(datasourceId)
          .then(() => {
            toastSuccess('Data source successfully marked for deletion');

            setTimeout(() => {
              window.location.href = '/data-sources';
            }, 500);
          })
          .catch(error => {
            console.log('error', error.message);
            toastError(error.message ?? 'Failed to delete data sources');
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        // User clicked "Cancel"
        console.log('Cancelled.');
      }
    } else {
      setIsSubmitting(true);

      datasources
        .update({
          id: datasourceId,
          name: datasourceName,
          cronSchedule,
          connectionInfo: {
            brand,
            reamazeEmail,
            reamazeAPIToken,
            cronSchedule,
          },
        })
        .then(() => {
          toastSuccess('Datasource successfully updated');
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to update datasource');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };
  return (
    <Container className='newDSMuiContainer-root'>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
        <Link component={RouterLink} to='/' color='inherit'>
          Home
        </Link>
        <Link component={RouterLink} to='/data-sources' color='inherit'>
          Data Sources
        </Link>
        <Typography variant='body1' color='text.primary'>
          Re:amaze Support
        </Typography>
      </Breadcrumbs>
      <Grid item xs={12}>
        <Typography className='header' alignContent='center'>
          {datasourceName || 'Re:amaze Support'}
        </Typography>
      </Grid>

      <Grid container xs={12}>
        <Grid item xs={12} sm={6} md={6} lg={5} xl={5} mb={4}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
              <Typography>Name</Typography>
              <TextField
                required
                fullWidth
                name='projectName'
                placeholder='Application name'
                sx={{ mb: 2 }}
                value={datasourceName}
                onChange={event => setDatasourceName(event.target.value)}
                disabled
              />

              <Typography>Brand</Typography>
              <TextField
                required
                fullWidth
                name='brand'
                placeholder='Application name'
                sx={{ mb: 2 }}
                value={brand}
                onChange={event => setBrand(event.target.value)}
              />

              <Typography>Email</Typography>
              <TextField
                fullWidth
                name='reamazeEmail'
                placeholder='Account email'
                sx={{ mb: 2 }}
                value={reamazeEmail}
                onChange={event => setReamazeEmail(event.target.value)}
              />

              <Typography>API Token</Typography>
              <TextField
                fullWidth
                name='reamazeAPIToken'
                placeholder='API Token'
                sx={{ mb: 2 }}
                value={reamazeAPIToken}
                type='password'
                onChange={event => setReamazeAPIToken(event.target.value)}
              />

              <Typography>Cron schedule</Typography>
              <Select
                labelId='cron-schedule-label'
                name='cronSchedule'
                value={cronSchedule}
                onChange={event => setCronSchedule(event.target.value)}
              >
                <MenuItem value='once'>Once</MenuItem>
                <MenuItem value='daily'>Daily</MenuItem>
                <MenuItem value='weekly'>Weekly</MenuItem>
                <MenuItem value='monthly'>Monthly</MenuItem>
              </Select>
              <FormHelperText sx={{ mb: 2 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;The cron schedule determines when the
                scraping task will run.
              </FormHelperText>

              <Button
                type='submit'
                className='createButton'
                value='update'
                onClick={() => setSubmitType('update')}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Update'}
              </Button>
              <br />

              <Button
                type='submit'
                className='deleteButton'
                value='delete'
                onClick={() => setSubmitType('delete')}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Delete'}
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} xl={3}>
          <ReamazeHelpPane />
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditReamazeSupportDataSourcePage;
