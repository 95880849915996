import StatusPill from '../../components/StatusPill';
import datasources from '../../service/datasources';
import { toastError } from '../../utils/toastUI';
import DataSourceEmptyStateWithNew from './DataSourceEmptyStateWithNew';
import {
  Add,
  Article,
  CloudOutlined,
  FileUploadOutlined,
  LibraryBooksOutlined,
} from '@mui/icons-material';
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
// Analytics, ChatBubble, Cloud, Language
import { Link as RouterLink } from 'react-router-dom';

const SOURCE_ICON = {
  WEB: (
    <LibraryBooksOutlined
      sx={{
        width: '45px',
        height: '25px',
        paddingRight: '20px',
        color: '#444',
      }}
    />
  ),
  S3: (
    <CloudOutlined
      sx={{
        width: '45px',
        height: '25px',
        paddingRight: '20px',
        color: '#444',
      }}
    />
  ),
  REAMAZE: (
    <img
      alt='reamaze'
      src='/external/reamaze_logo.png'
      style={{ width: '25px', height: '25px', marginRight: '20px' }}
    />
  ),
  ZENDESK: (
    <img
      alt='zendesk'
      src='/external/zendesk_logo.png'
      style={{ width: '25px', height: '25px', marginRight: '20px' }}
    />
  ),
  FILES: (
    <FileUploadOutlined
      sx={{
        width: '45px',
        height: '25px',
        paddingRight: '20px',
        color: '#444',
      }}
    />
  ),
  CONFLUENCE: (
    <Article
      sx={{
        width: '45px',
        height: '25px',
        paddingRight: '20px',
        color: '#444',
      }}
    />
  ),
};

const DataSourcesPage = () => {
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch datasources
  useEffect(() => {
    const fetch = () => {
      datasources
        .list()
        .then(data => {
          setSources(data);
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to fetch data sources');
        })
        .finally(() => setLoading(false));
    };
    const tid = setInterval(fetch, 15 * 1000);
    fetch();

    return () => {
      clearInterval(tid);
    };
  }, [setSources]);

  return (
    <Container maxWidth='100%'>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Typography variant='h7' component='div'>
              Data Sources
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={6}>
          <Typography className='header' alignContent='center'>
            {sources.length ? 'Data Sources' : 'Add a Data Source'}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          {sources.length ? (
            <Button
              className='createButton'
              component={RouterLink}
              to='new'
              startIcon={<Add />}
              variant='contained'
            >
              Add
            </Button>
          ) : (
            <></>
          )}
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Box>
              <Skeleton />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
            </Box>
          </Grid>
        ) : sources.length ? (
          sources.map(datasource => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={datasource.id}>
              <Card
                component={RouterLink}
                to={`/data-sources/${datasource.type.toLowerCase()}/${
                  datasource.id
                }`}
                color='inherit'
              >
                <CardContent
                  sx={{
                    display: 'flex',
                    flexFlow: 'row',
                    alignItems: 'center',
                    height: '75px',
                  }}
                >
                  {SOURCE_ICON[datasource.type]}
                  {datasource.status === 'DELETING' ? (
                    <div>
                      <Typography className='card-title'>
                        {datasource.name}
                      </Typography>
                    </div>
                  ) : (
                    <Link
                      component={RouterLink}
                      to={`/data-sources/${datasource.type.toLowerCase()}/${
                        datasource.id
                      }`}
                      color='inherit'
                    >
                      <Typography className='card-title'>
                        {datasource.name}
                      </Typography>
                    </Link>
                  )}
                  &nbsp;&nbsp;&nbsp;
                  <StatusPill
                    actual={
                      datasource.status === 'STARTED'
                        ? 'PROCESSING'
                        : datasource.status
                    }
                    status={
                      datasource.status === 'CREATED'
                        ? 'pending'
                        : datasource.status === 'SUCCESS'
                          ? 'completed'
                          : 'inProgress'
                    }
                  />
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <DataSourceEmptyStateWithNew />
        )}
      </Grid>
    </Container>
  );
};

export default DataSourcesPage;
