import { getUrlParam } from './utils/utils';

export const ERROR_TRY_AGAIN = `Oops! Looks like our API call took a detour into the Bermuda Triangle and got lost in the sea of errors. Don't worry though, we're on it! Please try again in a bit, and we'll make sure everything is back up and running smoothly. Thank you for your patience!`;
export const ERROR_STREAM_FIX = `Please fix the issue!`;

export const ACCOUNT_INFO_KEY = 'user-org-account-info';
export const GLOBAL_LEFT_NAV_OPEN_STATE = 'global-left-nav-open-state';
export const TICKETING_INSIGHT_TABLE_FILTERS =
  'ticketing_insight_table_filters';
export const TICKETING_INSIGHT_TABLE_ORDER = 'ticketing_insight_table_order';
export const TICKETING_INSIGHT_TABLE_ORDER_BY =
  'ticketing_insight_table_orderBy';

export const SEARCH_INSIGHT_TABLE_FILTERS = 'search_insight_table_filters';
export const SEARCH_INSIGHT_TABLE_ORDER = 'search_insight_table_order';
export const SEARCH_INSIGHT_TABLE_ORDER_BY = 'search_insight_table_orderBy';

export const ACCOUNT_TYPE = {
  TRIAL: 'TRIAL',
  BASIC: 'BASIC',
  STANDARD: 'STANDARD',
  ENTERPRISE: 'ENTERPRISE',
};

export const DATASOURCE_TYPES = {
  WEB: 'WEB',
  S3: 'S3',
  REAMAZE: 'REAMAZE',
  ZENDESK: 'ZENDESK',
  FILES: 'FILES',
  CONFLUENCE: 'CONFLUENCE',
};

export const APPLICATION_TYPES_MAP = {
  CUSTOM_SEARCH: 'CUSTOM_SEARCH',
  REAMAZE_SEARCH: 'REAMAZE_SEARCH',
  ZENDESK_SEARCH: 'ZENDESK_SEARCH',
  REAMAZE_MODULE: 'REAMAZE_MODULE',
  HISTORICAL_SUPPORT_DATA: 'HISTORICAL_SUPPORT_DATA',
};

export const PREVIEW_ENABLED = !!getUrlParam('preview');
