import auth from '../service/auth';
import React, { useState } from 'react';
import { useLocation } from 'react-router-dom';

const ProceedPage = () => {
  const [message, setMessage] = useState('');
  const location = useLocation();

  // Access query parameters
  const queryParams = new URLSearchParams(location.search);
  const ec = queryParams.get('ec');

  // Proceed with login or registration logic
  auth
    .verifyCode(ec)
    .then(data => {
      localStorage.setItem('auth_token', data.token);
      window.location.href = '/home';
    })
    .catch(error => {
      console.log('error', error.message);
      setMessage(
        "Your verification link has expired. Please <a href='/login'>login</a> again"
      );
    });

  return (
    <div>
      {message ? (
        <span>
          Your verification link has expired. Please <a href='/login'>login</a>{' '}
          again
        </span>
      ) : (
        'Logging...'
      )}
    </div>
  );
};

export default ProceedPage;
