export function findMinMax(arr, property) {
  return arr.reduce(
    (acc, current) => {
      const value = current[property];
      if (value < acc.min) {
        acc.min = value;
      }
      if (value > acc.max) {
        acc.max = value;
      }
      return acc;
    },
    { min: Infinity, max: -Infinity }
  );
}

// Function to try parsing a JSON string into an object
export function tryParse(jsonString, print) {
  try {
    print && console.log(`Raw json: ${jsonString}`);
    const parsedObject = JSON.parse(jsonString);
    // If parsing succeeds, return the parsed object
    return parsedObject;
  } catch (error) {
    console.warn(error);
    // If parsing fails, return null
    return null;
  }
}

export function maskSensitiveString(inputString) {
  const maskedString =
    inputString.substring(0, 4) + inputString.substring(4).replace(/./g, '•');
  return maskedString.substring(0, 10);
}

export function snakeCasetoTitlecase(str) {
  // Split the string by underscores and convert each word to lowercase
  const words = str.split('_').map(word => word.toLowerCase());

  // Capitalize the first letter of each word
  const capitalizedWords = words.map(
    word => word.charAt(0).toUpperCase() + word.slice(1)
  );

  // Join the capitalized words back together with spaces
  const capitalizedString = capitalizedWords.join(' ');

  return capitalizedString;
}

export function capitalizeText(inputText) {
  return (
    inputText &&
    inputText.replace(/\b\w/g, firstLetter => firstLetter.toUpperCase())
  );
}

export function getUrlParam(paramName, defaultValue) {
  const url = new URL(window.location.href);
  console.log(url);
  const params = new URLSearchParams(url.search);
  return params.get(paramName) ?? defaultValue;
}

export function getProtocol() {
  return window.location.protocol;
}

export function getDomainName(href) {
  const url = new URL(href || window.location.href);
  const port = url.port ? `:${url.port}` : '';
  return `${url.hostname}${port}`;
}

export function getCDNDomainName(href) {
  const domainToCDNMap = {
    'stag-platform.kwazii.app': 'stag-cdn.kwazii.app',
    'platform.kwazii.app': 'cdn.kwazii.app',
    localhost: 'localhost:3001',
    // Add more mappings if needed
  };

  const url = new URL(href || window.location.href);
  return domainToCDNMap[url.hostname];
}

export function getServiceMode() {
  const isDebugMode = process.env.NODE_ENV === 'development';
  const serviceEnvOverride = getUrlParam('service_env');
  const domainName = getDomainName();

  const domainToServiceEnvMap = {
    'stag-platform.kwazii.app': 'staging',
    'platform.kwazii.app': 'prod',
    // Add more mappings if needed
  };

  if (serviceEnvOverride === 'staging' || serviceEnvOverride === 'prod') {
    return serviceEnvOverride;
  }

  if (isDebugMode) {
    return 'local';
  }

  const mappedEnv = domainToServiceEnvMap[domainName];
  return mappedEnv || 'local';
}

function getServiceEndpoint() {
  const isDebugMode = process.env.NODE_ENV === 'development';
  const serviceEnvOverride = getUrlParam('service_env');
  const domainName = getDomainName();

  let url;

  if (serviceEnvOverride === 'staging') {
    url = 'https://staging-api.kwazii.app/v1';
  } else if (serviceEnvOverride === 'prod') {
    url = 'https://api.kwazii.app/v1';
  } else if (isDebugMode) {
    url = 'http://localhost/v1';
  } else if (domainName === 'stag-platform.kwazii.app') {
    url = 'https://staging-api.kwazii.app/v1';
  } else {
    url = 'https://api.kwazii.app/v1';
  }

  console.log(`Service endpoint: ${url}`);

  return url;
}

export function removeElementFromArray(array, predicate) {
  const indexToRemove = array.findIndex(predicate);

  if (indexToRemove !== -1) {
    array.splice(indexToRemove, 1);
  }
}

// Example usage:
// const array = [1, 2, 3, 4, 5];

// function isEven(element) {
//     return element % 2 === 0;
// }

// removeElementFromArray(array, isEven);
// console.log(array); // Output: [1, 3, 5]

export function checkScore(string) {
  const regex = /## Score: (\d+)$/;
  const match = string.match(regex);

  if (match) {
    const score = parseInt(match[1], 10);
    const modifiedString = string.replace(regex, '');
    return { score, modifiedString };
  } else {
    return { score: 0, modifiedString: string };
  }
}

// Test cases
// console.log(checkScore("Some text ##Score: 42")); // Output: { score: 42, modifiedString: "Some text " }
// console.log(checkScore("No score here.")); // Output: { score: 0, modifiedString: "No score here." }

export function relatedQuestionsMatched(answer) {
  const regex = /Related Questions:/gs;
  const match = regex.exec(answer);
  return match;
}

export function remToPx(rem) {
  // Get the root font size in pixels (usually 16px)
  const rootFontSize = parseFloat(
    getComputedStyle(document.documentElement).fontSize
  );

  // Convert rem to px
  const pxValue = rem * rootFontSize;

  return pxValue;
}

export function truncateText(text = '', maxLength = 20) {
  if (maxLength < 6) {
    return '...';
  } else if (text.length > maxLength) {
    const halfLength = Math.floor((maxLength - 3) / 2); // Subtract 3 for the ellipsis
    const truncatedText =
      text.slice(0, halfLength) + '...' + text.slice(-halfLength);
    return truncatedText;
  }

  return text;
}

export function truncateTextEnd(text = '', maxLength = 50) {
  if (maxLength < 6) {
    return '...';
  } else if (text.length > maxLength) {
    const truncatedText = text.slice(0, maxLength - 3) + '...';
    return truncatedText;
  }

  return text;
}

export function suggstedAnswerMatched(answer) {
  const regex = /Suggested:/gs;
  const match = regex.exec(answer);
  return match;
}

export function extractRelatedQuestions(input) {
  const regex = /- (.+)/g;
  const matches = input.match(regex);
  if (matches) {
    return matches.map(match => match.replace('- ', '').trim());
  }
  return [];
}

export function splitAndFilterEmptyLines(inputString) {
  const lines = (inputString ?? '')
    .split('\n')
    .filter(line => line.trim() !== '');
  return lines;
}

export function getAuthEndpoint() {
  return `${getServiceEndpoint()}/auth`;
}

export function getKeysEndpoint() {
  return `${getServiceEndpoint()}/keys`;
}

export function getUsageEndpoint() {
  return `${getServiceEndpoint()}/usage`;
}

export function getApplicationsEndpoint() {
  return `${getServiceEndpoint()}/applications`;
}

export function getWorkflowsEndpoint() {
  return `${getServiceEndpoint()}/workflows`;
}

export function getAnalyticsEndpoint() {
  return `${getServiceEndpoint()}/analytics`;
}

export function getReamazeEndpoint() {
  return `${getServiceEndpoint()}/reamaze`;
}

export function getDatasourcesEndpoint() {
  return `${getServiceEndpoint()}/datasources`;
}

export function getFeedbackEndpoint() {
  return `${getServiceEndpoint()}/feedback`;
}

export function getContentEndpoint() {
  return `${getServiceEndpoint()}/content`;
}

export function jsonToMarkdown(data) {
  let markdown = '';

  if (!data) {
    return markdown;
  }

  // Customer Support Interaction Report
  markdown += '**Customer Support Interaction Report**';

  // Customer Question
  markdown += `\n\n**Customer Question:**\n`;

  markdown += `\n\n"${data.question}"\n`;

  // Happiness Evaluation
  markdown += '\n\n**Happiness Evaluation:**\n';
  markdown += '| Aspect | Score |\n';
  markdown += '| --- | --- |\n';
  for (const key in data.happinessEvaluation) {
    const aspect = capitalizeText(key.replace(/_/g, ' ')) + '   ';
    const score = data.happinessEvaluation[key];
    markdown += `| ${aspect} | ${score} |\n`;
  }
  markdown += '___';

  // Pain Point Category
  markdown += `\n\n**Pain Point Category:**`;
  markdown += `\n\n${data.painPointCategory}\n\n`;

  // Pain Point
  markdown += '\n\n**Pain Point:**\n';
  markdown += `\n\n${data.painPoint}\n\n`;

  // Support Improvement Category
  markdown += `\n\n**Support Improvement Category:**`;
  markdown += `\n\n${data.supportImprovementCategory}\n\n`;

  // Support Improvement
  markdown += '\n\n**Support Improvement:**\n';
  markdown += `\n\n${data.supportImprovement}\n\n`;

  // Feature Improvement
  markdown += '\n\n**Feature Improvement:**\n';
  markdown += `\n\n${data.featureImprovement}\n\n`;

  // Feature Improvement Category
  markdown += `\n\n**Feature Improvement Category:** ${data.featureImprovementCategory}\n\n`;

  return markdown;
}

export function convertToXStateVizJSON(data) {
  const xstateVizJSON = {
    states: {},
    initial: '',
    statesMap: {},
  };

  // Create state definitions
  data.states.forEach(state => {
    xstateVizJSON.states[state.name] = {
      on: {},
    };

    // Set initial state
    if (state.name === data.stateMachine[0].name) {
      xstateVizJSON.initial = state.name;
    }

    // Create actions
    const action = data.actions.find(a => a.name === state.name);
    if (action) {
      xstateVizJSON.states[state.name].entry = action.name;
    }
  });

  // Create transitions
  data.stateMachine.forEach(transition => {
    const fromState = transition.name;
    xstateVizJSON.states[fromState].on = {};

    transition.transition.forEach(t => {
      xstateVizJSON.states[fromState].on[t.triggered_by] = t.next_state;
    });
  });

  // Create annotations
  xstateVizJSON.annotations = data.annotations.map(annotation => ({
    id: annotation.persona,
    text: annotation.message,
    from: annotation.current_state,
    to: annotation.current_state,
  }));

  return xstateVizJSON;
}

// Debounce function to limit the rate of functionf calls
export function debounce(func, delay) {
  let timeoutId;
  return function () {
    clearTimeout(timeoutId);
    timeoutId = setTimeout(() => {
      func.apply(this, arguments);
    }, delay);
  };
}

export function generateShades(hexColor, numShades) {
  // Convert hex to RGB
  let r = parseInt(hexColor.substring(1, 3), 16);
  let g = parseInt(hexColor.substring(3, 5), 16);
  let b = parseInt(hexColor.substring(5, 7), 16);

  // Calculate the decrement for each channel
  let rDec = Math.floor(r / numShades);
  let gDec = Math.floor(g / numShades);
  let bDec = Math.floor(b / numShades);

  let shades = [];

  for (let i = 0; i < numShades; i++) {
    // Generate new shade
    let newR = r + i * rDec;
    let newG = g + i * gDec;
    let newB = b + i * bDec;

    // Convert RGB to hex and push to the shades array
    let newHex = `#${newR.toString(16).padStart(2, '0')}${newG
      .toString(16)
      .padStart(2, '0')}${newB.toString(16).padStart(2, '0')}`;
    shades.push(newHex);
  }

  return shades;
}
