import { NewWorkflowContext } from '../Context';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  Typography,
} from '@mui/material';
import React, { useContext } from 'react';

const ACTIONS = [
  {
    description: 'Fetch Data',
    type: 'actionNode',
    component: 'fetch_data',
  },
  {
    description: 'Format Data',
    type: 'formatterNode',
    component: 'format_data',
  },
];

const ActionPicker = () => {
  const { onNodeAdd } = useContext(NewWorkflowContext);

  const onAdd = action => {
    const node = {
      id: `${Date.now()}`,
      type: action.type,
      data: {
        component: action.component,
      },
    };
    onNodeAdd(node);
  };

  return (
    <Grid container xs={12} pl={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
          <Typography mb={1.5}>Select Action</Typography>
          {ACTIONS.map(action => (
            <Grid item xs={12} mb={1}>
              <Card>
                <CardContent>
                  <Button
                    style={{
                      border: 'none',
                      display: 'flex',
                      flexFlow: 'column',
                      alignItems: 'flex-start',
                    }}
                    onClick={() => onAdd(action)}
                  >
                    <Typography className='card-title'>
                      {action.description}
                    </Typography>
                    <Typography className='card-sub-title'>
                      {action.component}
                    </Typography>
                  </Button>
                </CardContent>
              </Card>
            </Grid>
          ))}
          <br />
        </Box>
      </Grid>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
    </Grid>
  );
};

export default ActionPicker;
