import ContainerBox from '../../../../components/ContainerBox';
import { fetchArticle } from '../../../../service/fetchArticle';
import {
  BoltOutlined,
  CleaningServicesOutlined,
  Edit,
} from '@mui/icons-material';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useRef, useState } from 'react';
import { removeStopwords } from 'stopword';
import { v4 as uuid } from 'uuid';

function GenerateKB({ application, question }) {
  const [searchValue, setSearchValue] = useState(
    `Create a knowledge base article on - ${question}`
  );
  const [keywords, setKeywords] = useState(
    question ? removeStopwords(question.split(' ')).join(',') : ''
  );
  const [systemPromptValue, setSystemPromptValue] = useState('');

  const [showSystemPrompt, setShowSystemPrompt] = useState(false);
  const [conversationId, setConversationId] = useState(uuid());
  const [selectedSources] = useState(application.datasourceNames);
  const [results, setResults] = useState([]);

  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const handleShowSystemPrompt = () => {
    setShowSystemPrompt(!showSystemPrompt);
  };

  const handleReset = () => {
    setResults([]);
    setConversationId(uuid());
  };

  const handleInputChange = e => {
    setSearchValue(e.target.value);
  };

  const handleSystemPromptInputChange = e => {
    setSystemPromptValue(e.target.value);
  };

  const handleKeywordInputChange = e => {
    setKeywords(e.target.value);
  };

  const handleSearch = async event => {
    event.preventDefault();
    const userQuestion = searchValue ?? '';
    await performSearch(userQuestion);
  };

  const performSearch = async userQuestion => {
    const updatedResults = [
      ...results,
      {
        question: userQuestion,
        answer: '',
        placeholder: 'Creating blog post...',
      },
    ];

    setResults(updatedResults);

    setSearchValue('');

    await fetchArticle(
      userQuestion,
      keywords,
      selectedSources,
      conversationId,
      systemPromptValue,
      answer => {
        const lastResult = updatedResults[updatedResults.length - 1];
        lastResult.answer += answer;

        setResults([...updatedResults]);
      }
    );
  };

  useEffect(() => {
    // if (question) {
    //   performSearch(`Create a knowledge base article on - ${question}`);
    //   setSearchValue("");
    // }
    // eslint-disable-next-line
  }, [question]);

  return (
    <>
      <Box
        sx={{
          height: '35px',
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
          background: '#fafafa',
        }}
      >
        <Typography variant='h6' sx={{ fontWeight: 'bold', padding: '5px' }}>
          Generate KB Article
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className='wrk-panel'
          style={{
            height: 'calc(100vh - 35px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ContainerBox
            conversationId={conversationId}
            results={results}
            adjustHeight={true}
            heightRef={containerRef}
          />

          <form onSubmit={handleSearch}>
            <Box
              className='editor-options-box'
              display='flex'
              alignItems='center'
              px={1}
              py={2}
              ref={containerRef}
            >
              <Box
                width={'100%'}
                display='flex'
                flexDirection={'column'}
                justifyContent='stretch'
              >
                <TextField
                  label='Custom system prompt'
                  variant='outlined'
                  multiline
                  value={systemPromptValue}
                  onChange={handleSystemPromptInputChange}
                  sx={{ flexGrow: 1 }}
                  className={`custom-system-prompt ${
                    showSystemPrompt ? '' : 'display-none'
                  }`}
                  required={false}
                  size='small'
                />
                <Box
                  width={'100%'}
                  display='flex'
                  justifyContent='stretch'
                  flexDirection={'column'}
                >
                  <TextField
                    label='Keywords e.g. product, feature, quality...'
                    placeholder='product, feature, quality...'
                    variant='outlined'
                    value={keywords}
                    onChange={handleKeywordInputChange}
                    sx={{ flexGrow: 1, marginBottom: '10px' }}
                    size='small'
                    inputRef={inputRef}
                    required={true}
                  />
                  <TextField
                    label='Ask e.g. Create a knowledge base article on - '
                    placeholder='Create a knowledge base article on -'
                    required={true}
                    variant='outlined'
                    value={searchValue}
                    onChange={handleInputChange}
                    // onKeyPress={handleKeyPress}
                    sx={{ flexGrow: 1 }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position='end'>
                          <IconButton type='submit'>
                            <BoltOutlined sx={{ fontSize: 25 }} />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    size='small'
                  />
                </Box>
              </Box>
              <Box
                display='flex'
                justifyContent='stretch'
                flexDirection={'column'}
              >
                <Tooltip title='Start new topic'>
                  <Button
                    className='save-button'
                    onClick={handleReset}
                    variant='contained'
                    startIcon={<CleaningServicesOutlined />}
                  ></Button>
                </Tooltip>
                <Tooltip title='Specify custom system prompt'>
                  <Button
                    className='save-button'
                    onClick={handleShowSystemPrompt}
                    variant='contained'
                    startIcon={<Edit />}
                  ></Button>
                </Tooltip>
              </Box>
            </Box>
          </form>
        </div>
      </Box>
    </>
  );
}

export default GenerateKB;
