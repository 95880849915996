import { fetchChat } from '../service/fetchChat';
import { useSessionStorage } from '../utils/storage';
import { toastError } from '../utils/toastUI';
import { extractRelatedQuestions, suggstedAnswerMatched } from '../utils/utils';
import ContainerBox from './ContainerBox';
import {
  BoltOutlined,
  CleaningServicesOutlined,
  Edit,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

const ChatPanel = ({ sessionId, selectedSources }) => {
  const [searchValue, setSearchValue] = useSessionStorage(
    `search_value_${sessionId}`,
    ''
  );
  const [systemPromptValue, setSystemPromptValue] = useSessionStorage(
    `system_prompt_${sessionId}`,
    ''
  );
  const [showSystemPrompt, setShowSystemPrompt] = useSessionStorage(
    `show_system_prompt_${sessionId}`,
    false
  );

  const [conversationId, setConversationId] = useSessionStorage(
    `conversationId_${sessionId}`,
    uuid()
  );

  const [results, setResults] = useSessionStorage(`results_${sessionId}`, []);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const handleShowSystemPrompt = () => {
    setShowSystemPrompt(!showSystemPrompt);
  };

  const handleReset = () => {
    setResults([]);
    setConversationId(uuid());
  };

  const handleSuggestions = async event => {
    setSearchValue(event.target.id);
    if (!selectedSources || !selectedSources.length) {
      toastError('Select data sources');
      return;
    }

    // setSearchValue(event.target.id)
    setSearchValue('');
    await performSearch(event.target.id);
  };

  const handleInputChange = e => {
    setSearchValue(e.target.value);
  };

  const handleSystemPromptInputChange = e => {
    setSystemPromptValue(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch(e);
    }
  };

  const handleSearch = async e => {
    e && e.preventDefault();

    if (!selectedSources || !selectedSources.length) {
      toastError('Select data sources');
      return;
    }

    if (searchValue.trim() !== '') {
      const userQuestion = searchValue;
      setSearchValue('');
      await performSearch(userQuestion);
    }
  };

  const performSearch = async userQuestion => {
    const updatedResults = [
      ...results,
      {
        question: userQuestion,
        answer: '',
        placeholder: 'Thinking...',
        suggestions: [],
      },
    ];

    setResults([...updatedResults]);

    let followupQuestions = '';
    let followupQuestionsFound = false;

    const { responseStream } = await fetchChat({
      userQuestion,
      selectedSources,
      conversationId,
      systemPrompt: systemPromptValue,
      onData: answer => {
        if (!followupQuestionsFound) {
          const lastResult = updatedResults[updatedResults.length - 1];
          lastResult.answer += answer;

          followupQuestionsFound = suggstedAnswerMatched(lastResult.answer);
          if (followupQuestionsFound) {
            const startIndex = lastResult.answer.indexOf('Suggested:');
            followupQuestions = lastResult.answer.substring(startIndex);
            lastResult.answer = lastResult.answer.replace(
              followupQuestions,
              ''
            );
          }
        } else {
          followupQuestions += answer;
        }

        setResults([...updatedResults]);
      },
    });

    await responseStream;
    if (followupQuestions) {
      const relatedQuestionsModified =
        extractRelatedQuestions(followupQuestions);
      const lastResult = updatedResults[updatedResults.length - 1];
      lastResult.suggestions = relatedQuestionsModified;
      setResults([...updatedResults]);
    }
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <div
        className='wrk-panel chat-panel'
        style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
      >
        <ContainerBox
          conversationId={conversationId}
          results={results}
          adjustHeight={true}
          heightRef={containerRef}
          onSuggestionClick={handleSuggestions}
        />

        <form onSubmit={handleSearch}>
          <Box
            className='editor-options-box'
            display='flex'
            alignItems='center'
            px={0}
            py={2}
            ref={containerRef}
          >
            <Box
              width={'100%'}
              display='flex'
              flexDirection={'column'}
              justifyContent='stretch'
            >
              <TextField
                label='Custom system prompt'
                variant='outlined'
                multiline
                value={systemPromptValue}
                onChange={handleSystemPromptInputChange}
                sx={{ flexGrow: 1 }}
                className={`custom-system-prompt ${
                  showSystemPrompt ? '' : 'display-none'
                }`}
                required={false}
                size='small'
              />
              <TextField
                label='How can I help?'
                variant='outlined'
                value={searchValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                sx={{ flexGrow: 1 }}
                required={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton type='submit'>
                        <BoltOutlined sx={{ fontSize: 25 }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                inputRef={inputRef}
              />
            </Box>
            <Tooltip title='Start new topic'>
              <Button
                className='save-button'
                onClick={handleReset}
                variant='contained'
                startIcon={<CleaningServicesOutlined />}
              ></Button>
            </Tooltip>
            <Tooltip title='Specify custom system prompt'>
              <Button
                className='save-button'
                onClick={handleShowSystemPrompt}
                variant='contained'
                startIcon={<Edit />}
              ></Button>
            </Tooltip>
          </Box>
        </form>
      </div>
    </>
  );
};

export default ChatPanel;
