import TooltipIcon from '../../../../components/TooltipIcon';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment-timezone';
import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

function TicketSentimentScore({ answerScoreDistribution, application }) {
  const dispatchRenderAIEvent = ({ question, application, date, payload }) => {
    // Handle the click event here, you can access the clicked data and index
    const mmDate = moment.tz(date, 'UTC').format('MMMM DD, YYYY');

    window.dispatchEvent(new CustomEvent('info-pane-open'));
    var customEvent = new CustomEvent('render-info-pane', {
      detail: {
        data: payload,
        type: 'ask-ai',
        application,
        question: question.replace('{{DATE}}', mmDate),
      },
    });
    // Dispatch the custom event on the window
    window.dispatchEvent(customEvent);

    console.log('Clicked data:', payload);
  };

  const AnswerScoreDistributionChart = ({
    answerScoreDistribution,
    application,
  }) => {
    const handleBarClick = selected => {
      dispatchRenderAIEvent({
        question: `List all the tickets on {{DATE}}`,
        application: application,
        date: selected.activePayload[0]?.payload._idDate,
        payload: selected.activePayload[0]?.payload,
      });
    };

    return (
      <ResponsiveContainer debounce='0' width='99%' height={300}>
        <LineChart data={answerScoreDistribution} onClick={handleBarClick}>
          <XAxis dataKey='_id' tick={{ fontSize: '0.8rem' }} />
          <YAxis width={40} tick={{ fontSize: '0.8rem' }} />
          <CartesianGrid strokeDasharray='3 3' />
          <Tooltip />
          <Line
            type='monotone'
            dataKey='score'
            stroke='#555555'
            strokeWidth={2}
            activeDot={{ r: 8 }}
            name='Score'
          />
        </LineChart>
      </ResponsiveContainer>
    );
  };

  return (
    <>
      <Grid item xs={12} sm={12} md={12}>
        <Card>
          <CardContent>
            <Typography color='textSecondary' gutterBottom>
              Ticket Sentiment Score{' '}
              <TooltipIcon
                timeout={2500}
                tooltipText={`Score is a reflection of your customer support quality, taking into account:
                    Sentiment, Personalization, Empathy, Resolution Speed, Clarity`}
              />
            </Typography>
            <AnswerScoreDistributionChart
              answerScoreDistribution={answerScoreDistribution}
              application={application}
            />
          </CardContent>
        </Card>
      </Grid>
    </>
  );
}

export default TicketSentimentScore;
