import { CUSTOM_WORKFLOW_TEMPLATES, WORKFLOW_TEMPLATES } from './common';
import {
  Box,
  Breadcrumbs,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NewWorkflowPage = () => {
  return (
    <Container maxWidth='100%'>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Link component={RouterLink} to='/workflow' color='inherit'>
              Workflows
            </Link>
            <Typography variant='h7' component='div'>
              New Workflow
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Typography className='header' alignContent='center'>
            New Workflow
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ paddingTop: '8px !important' }}>
          <Typography className='subsubheader'>
            Workflows are designed to empower users to automate various actions
            based on specific events within their system.
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Typography className='subheader2' alignContent='center'>
            Custom
          </Typography>
        </Grid>

        {/* Data Source Cards */}
        {CUSTOM_WORKFLOW_TEMPLATES.map(workflow => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={workflow.id}>
            <Card
              sx={{ height: '80px', display: 'flex', flexDirection: 'column' }}
              component={RouterLink}
              to={workflow.link}
              color='inherit'
            >
              <CardContent sx={{ display: 'flex', flexFlow: 'row' }}>
                <div style={{ alignSelf: 'center' }}>{workflow.icon}</div>
                <Link
                  style={{ alignSelf: 'center' }}
                  component={RouterLink}
                  to={workflow.link}
                  color='inherit'
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography className='card-title'>
                      {workflow.title}
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {workflow.description}
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}

        <Grid item xs={12}>
          <Typography className='subheader2' alignContent='center'>
            Pre-defined Template
          </Typography>
        </Grid>

        {/* Data Source Cards */}
        {WORKFLOW_TEMPLATES.map(workflow => (
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={4}
            xl={3}
            key={workflow.id}
            component={RouterLink}
            to={workflow.link}
            color='inherit'
          >
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent
                sx={{ height: '150px', display: 'flex', flexFlow: 'row' }}
              >
                <div style={{ alignSelf: 'center' }}>{workflow.icon}</div>
                <Link
                  style={{ alignSelf: 'center' }}
                  component={RouterLink}
                  to={workflow.link}
                  color='inherit'
                >
                  <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                    <Typography className='card-title'>
                      {workflow.title}
                    </Typography>
                  </Box>
                  <Typography
                    variant='body2'
                    color='text.secondary'
                    sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                  >
                    {workflow.description}
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default NewWorkflowPage;
