import ContainerBox from '../../../../components/ContainerBox';
import { fetchSearch } from '../../../../service/fetchSearch';
import { relatedQuestionsMatched } from '../../../../utils/utils';
import { BoltOutlined, CleaningServicesOutlined } from '@mui/icons-material';
import {
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

function TryChat({ application, question }) {
  const [searchValue, setSearchValue] = useState(question);
  const [conversationId, setConversationId] = useState(uuid());
  const [selectedSources] = useState(application.datasourceNames);
  const [results, setResults] = useState([]);

  const inputRef = useRef(null);

  const handleReset = () => {
    setResults([]);
    setConversationId(uuid());
  };

  const handleInputChange = e => {
    setSearchValue(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch(e);
    }
  };

  const handleSearch = async event => {
    event.preventDefault();
    const userQuestion = searchValue ?? '';
    setSearchValue('');
    await performSearch(userQuestion);
  };

  const handleSuggestions = async event => {
    await performSearch(event.target.id);
  };

  const performSearch = async userQuestion => {
    const updatedResults = [
      ...results,
      {
        question: userQuestion,
        answer: '',
        placeholder: 'Thinking...',
        suggestions: [],
      },
    ];

    setResults([...updatedResults]);

    let relatedQuestions = '';
    let relatedQuestionsFound = false;

    const { responseStream } = await fetchSearch({
      userQuestion,
      selectedSources,
      conversationId,
      onData: answer => {
        if (!relatedQuestionsFound) {
          const lastResult = updatedResults[updatedResults.length - 1];
          lastResult.answer += answer;

          relatedQuestionsFound = relatedQuestionsMatched(lastResult.answer);
          if (relatedQuestionsFound) {
            const startIndex = lastResult.answer.indexOf('Related Questions:');
            relatedQuestions = lastResult.answer.substring(startIndex);
            lastResult.answer = lastResult.answer.replace(relatedQuestions, '');
          }
        } else {
          relatedQuestions += answer;
        }

        setResults([...updatedResults]);
      },
    });

    await responseStream;
    // if (relatedQuestions) {
    //   const relatedQuestionsModified = extractRelatedQuestions(
    //     relatedQuestions
    //   );
    //   const lastResult = updatedResults[updatedResults.length - 1];
    //   lastResult.suggestions = relatedQuestionsModified;
    //   setResults([...updatedResults]);
    // }
  };

  useEffect(() => {
    // if (question) {
    //   performSearch(question);
    //   setSearchValue("");
    // }
    // eslint-disable-next-line
  }, [question]);

  return (
    <>
      <Box
        sx={{
          height: '35px',
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
          background: '#fafafa',
        }}
      >
        <Typography variant='h6' sx={{ fontWeight: 'bold', padding: '5px' }}>
          Search
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          flexGrow: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div
          className='wrk-panel wrk-chat-panel'
          style={{
            height: 'calc(100vh - 35px)',
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          <ContainerBox
            conversationId={conversationId}
            results={results}
            adjustHeight={false}
            onSuggestionClick={handleSuggestions}
          />

          <form onSubmit={handleSearch}>
            <Box
              className='editor-options-box'
              display='flex'
              alignItems='center'
              px={1}
              py={2}
            >
              <Box
                width={'100%'}
                display='flex'
                flexDirection={'column'}
                justifyContent='stretch'
              >
                <TextField
                  label='Ask AI'
                  variant='outlined'
                  value={searchValue}
                  onChange={handleInputChange}
                  onKeyPress={handleKeyPress}
                  sx={{ flexGrow: 1 }}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton type='submit'>
                          <BoltOutlined sx={{ fontSize: 25 }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                  inputRef={inputRef}
                />
              </Box>
              <Tooltip title='Start new topic'>
                <Button
                  className='save-button'
                  onClick={handleReset}
                  variant='contained'
                  startIcon={<CleaningServicesOutlined />}
                ></Button>
              </Tooltip>
            </Box>
          </form>
        </div>
      </Box>
    </>
  );
}

export default TryChat;
