// import {
//   Search, ListAltOutlined, TrendingUpOutlined, MoveDownOutlined, AccountTreeOutlined,
//   AlignVerticalBottomOutlined, WarningOutlined, LightbulbOutlined, MessageOutlined, InfoOutlined,
//   LockClock, AssignmentOutlined, SearchOutlined, ArticleOutlined
// } from '@mui/icons-material';
import { CustomTemplate } from './templates/custom';
import { Template0 } from './templates/template_0';
import { Template1 } from './templates/template_1';
import { Template2 } from './templates/template_2';
import { Template3 } from './templates/template_3';
import {
  AccountTreeOutlined,
  AlignVerticalBottomOutlined,
  ArticleOutlined,
  TrendingUpOutlined,
} from '@mui/icons-material';

export const WORKFLOW_TEMPLATES = [
  {
    template: 0,
    icon: (
      <ArticleOutlined
        sx={{
          width: '50px',
          height: '30px',
          paddingRight: '20px',
          color: '#444',
        }}
      />
    ),
    title: 'Monthly Product Improvement Report',
    link: '/workflow/design/?template=0',
    description:
      'Send email or Slack with a product improvement summary from the tickets.',
    ...Template0,
  },
  {
    template: 1,
    icon: (
      <TrendingUpOutlined
        sx={{
          width: '50px',
          height: '30px',
          paddingRight: '20px',
          color: '#444',
        }}
      />
    ),
    title: 'Notify on Trending Pain Points Categories',
    link: '/workflow/design/?template=1',
    description:
      'Send out notifications about Pain Points categories that are trending.',
    ...Template1,
  },
  {
    template: 2,
    icon: (
      <TrendingUpOutlined
        sx={{
          width: '50px',
          height: '30px',
          paddingRight: '20px',
          color: '#444',
        }}
      />
    ),
    title: 'Notify on Trending Product Categories',
    link: '/workflow/design/?template=2',
    description:
      'Send out notifications about product categories that are trending over the past 7 days.',
    ...Template2,
  },
  {
    template: 3,
    icon: (
      <AlignVerticalBottomOutlined
        sx={{
          width: '50px',
          height: '30px',
          paddingRight: '20px',
          color: '#444',
        }}
      />
    ),
    title: 'Alert on Happiness Score',
    link: '/workflow/design/?template=3',
    description:
      'Notify the support team when a conversation has a happiness score below a certain threshold.',
    ...Template3,
  },
  // {
  //   "template": 1,
  //   "icon": <Search sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Notify on Significant Search Activity",
  //   "link": "/workflow/design/?template=1",
  //   "description": "Send email or Slack notifications when there's a spike in search activity on your platform."
  // },
  // {
  //   "template": 2,
  //   "icon": <ListAltOutlined sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Alert on No Search Results",
  //   "link": "/workflow/design/?template=2",
  //   "description": "Automatically notify teams when users make queries that return no search results."
  // },

  // {
  //   "template": 4,
  //   "icon": <MoveDownOutlined sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Distribute Transformed Analytics Data",
  //   "link": "/workflow/design/?template=4",
  //   "description": "Automatically send out transformed analytics data to CDP platform of your choice."
  // }
  // {
  //   "template": 6,
  //   "icon": <WarningOutlined sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Notify on Unaddressed Pain Points",
  //   "link": "/workflow/design/?template=6",
  //   "description": "Send notifications if a customer's pain point hasn't been addressed within a certain time frame."
  // },
  // {
  //   "template": 7,
  //   "icon": <TrendingUpOutlined sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Feedback Trend Alert",
  //   "link": "/workflow/design/?template=7",
  //   "description": "Notify teams if there's a spike in a specific painPointCategory or supportImprovementCategory."
  // },
  // {
  //   "template": 8,
  //   "icon": <LightbulbOutlined sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Feature Improvement Suggestions",
  //   "link": "/workflow/design/?template=8",
  //   "description": "Send notifications about recurring feature improvement suggestions to the product team."
  // },
  // {
  //   "template": 9,
  //   "icon": <MessageOutlined sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Alert on High Volume Conversations",
  //   "link": "/workflow/design/?template=9",
  //   "description": "If the messageCount for a conversation exceeds a certain number, notify a manager or supervisor."
  // },
  // {
  //   "template": 10,
  //   "icon": <InfoOutlined sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Notify on Specific Intent Categories",
  //   "link": "/workflow/design/?template=10",
  //   "description": "Send out notifications based on certain intentCategory values."
  // },
  // {
  //   "template": 11,
  //   "icon": <LockClock sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Unattended Conversations Alert",
  //   "link": "/workflow/design/?template=11",
  //   "description": "Notify if a conversation hasn't been updated within a certain timeframe."
  // },
  // // {
  // //   "template":  11,
  // //   "icon": <ArrowUpwardOutlined sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  // //   "title": "Escalation Alerts",
  // //   "link": "/workflow/design/",
  // //   "description": "Notify of multiple escalations in a conversation."
  // // },
  // {
  //   "template": 12,
  //   "icon": <AssignmentOutlined sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Alert on Unassigned Conversations",
  //   "link": "/workflow/design/?template=12",
  //   "description": "Notify if a conversation is unassigned for a certain period after creation."
  // },
  // {
  //   "template": 13,
  //   "icon": <SearchOutlined sx={{ width: "50px", height: '30px', paddingRight: '20px', color: "#444" }} />,
  //   "title": "Product Feature Query Trend",
  //   "link": "/workflow/design/?template=13",
  //   "description": "Notify the product team of an increase in queries related to a specific intentFeature."
  // }
];

export const CUSTOM_WORKFLOW_TEMPLATES = [
  {
    id: 1,
    icon: (
      <AccountTreeOutlined
        sx={{
          width: '50px',
          height: '30px',
          paddingRight: '20px',
          color: '#444',
        }}
      />
    ),
    title: 'My Workflow',
    link: '/workflow/design/',
    description: '',
    ...CustomTemplate,
  },
];
