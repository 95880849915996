import ContainerBox from '../../components/ContainerBox';
import { fetchAIAnswer } from '../../service/fetchAIAnswer';
import { useSessionStorage } from '../../utils/storage';
import { toastError } from '../../utils/toastUI';
import { BoltOutlined, CleaningServicesOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { v4 as uuid } from 'uuid';

const ConverseWithAI = ({ question, application, id }) => {
  const [searchValue, setSearchValue] = useSessionStorage(
    `converse_with_ai`,
    ''
  );
  const [conversationId, setConversationId] = useSessionStorage(
    `converse_with_ai_conversation_id`,
    id || uuid()
  );
  const [results, setResults] = useSessionStorage(
    `converse_with_ai_results`,
    []
  );
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const handleReset = () => {
    setResults([]);
    setConversationId(uuid());
  };

  const handleSuggestions = async event => {
    setSearchValue(event.target.id);
    if (!application) {
      toastError('Application is missing');
      return;
    }

    setSearchValue('');
    await performSearch(event.target.id);
  };

  const handleInputChange = e => {
    setSearchValue(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch(e);
    }
  };

  const handleSearch = async e => {
    e && e.preventDefault();

    if (!application) {
      toastError('Application is missing');
      return;
    }

    if (searchValue.trim() !== '') {
      const userQuestion = searchValue;
      setSearchValue('');
      await performSearch(userQuestion);
    }
  };

  const performSearch = async userQuestion => {
    const updatedResults = [
      ...results,
      {
        question: userQuestion,
        answer: '',
        placeholder: 'Thinking...',
        suggestions: [],
      },
    ];

    setResults([...updatedResults]);

    const { responseStream } = await fetchAIAnswer({
      userQuestion,
      application,
      conversationId,
      onData: answer => {
        const lastResult = updatedResults[updatedResults.length - 1];
        lastResult.answer += answer;
        setResults([...updatedResults]);
      },
    });

    await responseStream;
  };

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (question) {
      performSearch(question);
    }
    // eslint-disable-next-line
  }, [question]);

  const handleGreetings = async event => {
    await performSearch(event.target.id);
  };

  return (
    <div
      className='wrk-panel'
      style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
    >
      <ContainerBox
        conversationId={conversationId}
        results={results}
        adjustHeight={true}
        heightRef={containerRef}
        onSuggestionClick={handleSuggestions}
      />

      <div ref={containerRef}>
        {!results?.length && application?.greetingQuestions?.length ? (
          <Box
            display='flex'
            justifyContent='center'
            flexWrap={'wrap'}
            px={0}
            py={0}
          >
            {application.greetingQuestions.map((greeting, index) => (
              <Button
                key={greeting}
                className='suggest-button-base suggest-button'
                sx={{ fontFamily: 'monospace', fontSize: '0.85rem !important' }}
                id={greeting}
                value={index}
                onClick={handleGreetings}
              >
                {greeting}
              </Button>
            ))}
          </Box>
        ) : (
          <></>
        )}

        <form onSubmit={handleSearch}>
          <Box
            className='editor-options-box'
            height={'60px'}
            display='flex'
            alignItems='center'
            px={0}
            py={0}
          >
            <Box
              width={'100%'}
              display='flex'
              flexDirection={'column'}
              justifyContent='stretch'
            >
              <TextField
                label='Ask AI'
                variant='outlined'
                value={searchValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                sx={{ flexGrow: 1 }}
                required={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton type='submit'>
                        <BoltOutlined sx={{ fontSize: 25 }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                inputRef={inputRef}
              />
            </Box>
            <Tooltip title='Start new topic'>
              <Button
                className='save-button'
                onClick={handleReset}
                variant='contained'
                startIcon={<CleaningServicesOutlined />}
              ></Button>
            </Tooltip>
          </Box>
        </form>
      </div>
    </div>
  );
};

export default ConverseWithAI;
