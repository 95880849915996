import { GLOBAL_LEFT_NAV_OPEN_STATE } from '../../constants';
import applicationsService from '../../service/applications';
import { useLocalStorage } from '../../utils/storage';
import { toastError } from '../../utils/toastUI';
import HistoricalInsightsPage from './history/HistoricalInsightsPage';
import SearchInsightsPage from './search/SearchInsightsPage';
import { RestoreOutlined } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

const ViewInsightsPage = () => {
  const { applicationId } = useParams();
  const [leftNavOpen, setLeftNavOpen] = useLocalStorage(
    GLOBAL_LEFT_NAV_OPEN_STATE,
    false
  );
  const [infoPaneOpen, setInfoPaneOpen] = useState(false);
  const [startDate, setStartDate] = useState(moment().subtract(60, 'days'));
  const [endDate, setEndate] = useState(moment());
  const [selectedApplication, setSelectedApplication] = useState(null);

  window.addEventListener('left-nav-open', () => setLeftNavOpen(true));
  window.addEventListener('left-nav-close', () => setLeftNavOpen(false));

  window.addEventListener('info-pane-open', () => setInfoPaneOpen(true));
  window.addEventListener('info-pane-close', () => setInfoPaneOpen(false));

  useEffect(() => {
    return () => {
      window.dispatchEvent(new CustomEvent('info-pane-close'));
    };
  }, []);

  // Fetch applications
  useEffect(() => {
    applicationsService
      .get(applicationId)
      .then(data => {
        setSelectedApplication(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch Application');
      });
  }, [applicationId]);

  let className = '';
  if (leftNavOpen && infoPaneOpen) {
    className = 'newDSMuiContainer-root-insights-left-nav-open-info-pane-open';
  } else if (leftNavOpen && !infoPaneOpen) {
    className = 'newDSMuiContainer-root-insights-left-nav-open-info-pane-close';
  } else if (!leftNavOpen && infoPaneOpen) {
    className = 'newDSMuiContainer-root-insights-left-nav-close-info-pane-open';
  } else if (!leftNavOpen && !infoPaneOpen) {
    className =
      'newDSMuiContainer-root-insights-left-nav-close-info-pane-close';
  }

  const showAskAI = () => {
    window.dispatchEvent(new CustomEvent('info-pane-open'));
    var customEvent = new CustomEvent('render-info-pane', {
      detail: {
        type: 'ask-ai',
        startDate: startDate.toString(),
        endDate: endDate.toString(),
        application: selectedApplication,
      },
    });
    // Dispatch the custom event on the window
    window.dispatchEvent(customEvent);
  };

  const resetInterval = () => {
    setStartDate(moment().subtract(60, 'days'));
    setEndate(moment());
  };

  const setRange = (start, end) => {
    setStartDate(start);
    setEndate(end);
  };

  return (
    <Container className={className}>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Link component={RouterLink} to='/insights' color='inherit'>
              Insights
            </Link>
            <Typography variant='h7' component='div'>
              {selectedApplication?.name || 'Insights'}
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Typography className='header' alignContent='center'>
            {selectedApplication?.name || 'Insights'}
          </Typography>
        </Grid>
        <Grid
          item
          xs={12}
          display={'flex'}
          sx={{ flexDirection: 'row', justifyContent: 'flex-start' }}
        >
          {selectedApplication && (
            <DatePicker
              sx={{ width: '150px' }}
              label='Start Date'
              value={startDate}
              onChange={nv => setStartDate(nv)}
            />
          )}{' '}
          &nbsp;&nbsp;&nbsp;
          {selectedApplication && (
            <DatePicker
              sx={{ width: '150px' }}
              label='End Date'
              value={endDate}
              onChange={nv => setEndate(nv)}
              maxDateTime={moment()}
            />
          )}
          {selectedApplication && (
            <IconButton onClick={() => resetInterval()}>
              <RestoreOutlined sx={{ fontSize: 25 }} />
            </IconButton>
          )}
          &nbsp;&nbsp;
          {selectedApplication &&
            ['HISTORICAL_SUPPORT_DATA'].includes(selectedApplication?.type) && (
              <Button
                onClick={showAskAI}
                style={{
                  fontSize: '0.9rem',
                  fontWeight: 800,
                  color: '#444',
                  border: 'none',
                }}
              >
                Ask AI ⚡
              </Button>
            )}
        </Grid>

        <Grid item xs={12} mb={1}></Grid>

        <Box className='insights-details'>
          {['REAMAZE_SEARCH', 'ZENDESK_SEARCH', 'CUSTOM_SEARCH'].includes(
            selectedApplication?.type
          ) ? (
            <SearchInsightsPage
              key={'SearchInsightsPage'}
              application={selectedApplication}
              startDate={startDate}
              endDate={endDate}
              setRange={setRange}
            />
          ) : (
            <></>
          )}

          {['HISTORICAL_SUPPORT_DATA'].includes(selectedApplication?.type) ? (
            <HistoricalInsightsPage
              key={'HistoricalInsightsPage'}
              application={selectedApplication}
              startDate={startDate}
              endDate={endDate}
            />
          ) : (
            <></>
          )}
        </Box>
      </Grid>
      <br />
      <br />
    </Container>
  );
};

export default ViewInsightsPage;
