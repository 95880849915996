export const markBusy = (nodes, setNodes) => {
  for (const node of nodes) {
    node.data = {
      ...node.data,
      ...(node.data?.state || {}),
      preview: { response: '' },
      state: {
        hasError: false,
        status: 'busy',
      },
    };
  }

  setNodes([...nodes]);
};

export const markFree = (nodes, setNodes) => {
  for (const node of nodes) {
    node.data = {
      ...node.data,
      state: {
        hasError: false,
        status: '',
      },
    };
  }
  setNodes([...nodes]);
};

export const validateWorkflow = (nodes, onNodeSelect) => {
  for (const node of nodes) {
    if (node.data.component === 'schedule') {
      if (!node.data.scheduleId || !node.data.stepName) {
        node.data = {
          ...node.data,
          ...(node.data?.state || {}),
          state: {
            hasError: true,
            status: 'busy',
          },
        };

        onNodeSelect(node);
        return false;
      }
    }

    if (node.data.component === 'select_application') {
      if (!node.data.applicationId || !node.data.stepName) {
        node.data = {
          ...node.data,
          ...(node.data?.state || {}),
          state: {
            hasError: true,
            status: 'busy',
          },
        };

        onNodeSelect(node);
        return false;
      }
    }

    if (node.data.component === 'fetch_data') {
      if (!node.data.prompt) {
        node.data = {
          ...node.data,
          ...(node.data?.state || {}),
          state: {
            hasError: true,
            status: 'busy',
          },
        };

        onNodeSelect(node);
        return false;
      }
    }

    if (node.data.component === 'format_data') {
      if (!node.data.prompt) {
        node.data = {
          ...node.data,
          ...(node.data?.state || {}),
          state: {
            hasError: true,
            status: 'busy',
          },
        };

        onNodeSelect(node);
        return false;
      }
    }

    if (node.data.component === 'send_email') {
      if (!node.data.emails || !node.data.emails.length) {
        node.data = {
          ...node.data,
          ...(node.data?.state || {}),
          state: {
            hasError: true,
            status: 'busy',
          },
        };

        onNodeSelect(node);
        return false;
      }
    }
  }

  return true;
};
