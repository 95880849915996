import CodeRenderer from './CodeRenderer';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

const MarkdownComponent = ({ content, components }) => {
  const mergedComponents = {
    ...(components || {}),
    code: args => {
      if (args.inline) {
        return <code>{args.children}</code>;
      } else {
        return <CodeRenderer language='bash' code={args.children} />;
      }
    },
  };

  return (
    <div className='markdown'>
      <ReactMarkdown remarkPlugins={[remarkGfm]} components={mergedComponents}>
        {content}
      </ReactMarkdown>
    </div>
  );
};

export default MarkdownComponent;
