import { getContentEndpoint } from '../utils/utils';
import {
  handleCompletion,
  handleStreamCompletion,
  processResponse,
} from './common';

function createHeaders(apiKey = null) {
  const headers = {
    'Content-Type': 'application/json',
  };

  if (apiKey) {
    headers['X-KWAZII-API-KEY'] = apiKey;
  } else {
    const token = localStorage.getItem('auth_token');
    headers['Authorization'] = `Bearer ${token}`;
  }

  return headers;
}

export async function fetchSearchUsingApiKey({ apiKey, ...params }) {
  const url = `${getContentEndpoint()}/search/${params.applicationId}`;
  const headers = createHeaders(apiKey);
  return fetchSearchCommon({ url, headers, ...params });
}

export async function fetchSearch({ ...params }) {
  const url = `${getContentEndpoint()}/search`;
  const headers = createHeaders();
  return fetchSearchCommon({ url, headers, ...params });
}

export async function fetchHelp({ ...params }) {
  const url = `${getContentEndpoint()}/help`;
  const headers = createHeaders();
  return fetchSearchCommon({ url, headers, ...params });
}

export async function fetchSearchConversation({
  conversationId,
  applicationId,
}) {
  const token = localStorage.getItem('auth_token');
  const url = `${getContentEndpoint()}/search/${applicationId}/conversation/${conversationId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

async function fetchSearchCommon({
  url,
  headers,
  userQuestion,
  selectedSources,
  conversationId,
  applicationId,
  systemPrompt,
  onData,
}) {
  const data = {
    question: userQuestion,
    conversationId,
    applicationId,
    // optional paramaters
    ...(selectedSources ? { datasourceNames: selectedSources } : {}),
    ...(systemPrompt ? { systemPrompt } : {}),
  };

  const controller = new AbortController();
  const signal = controller.signal;

  const responseStream = handleStreamCompletion(
    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
      signal: signal,
    })
  ).then(response => processResponse(response, onData));

  return Promise.resolve({ responseStream, controller });
}
