import { CUSTOM_WORKFLOW_TEMPLATES, WORKFLOW_TEMPLATES } from './common';
import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const WorkflowEmptyStateWithNew = () => {
  return (
    <>
      <Grid item xs={12}>
        <Typography className='subsubheader'>
          Workflows are designed to empower users to automate various actions
          based on specific events within their system.
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography className='subheader2' alignContent='center'>
          Custom
        </Typography>
      </Grid>

      {/* Data Source Cards */}
      {CUSTOM_WORKFLOW_TEMPLATES.map(workflow => (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={workflow.id}>
          <Card
            style={{ height: '80px', display: 'flex', flexDirection: 'column' }}
            component={RouterLink}
            to={workflow.link}
            color='inherit'
          >
            <CardContent sx={{ display: 'flex', flexFlow: 'row' }}>
              <div style={{ alignSelf: 'center' }}>{workflow.icon}</div>
              <Link
                style={{ alignSelf: 'center' }}
                component={RouterLink}
                to={workflow.link}
                color='inherit'
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography className='card-title'>
                    {workflow.title}
                  </Typography>
                </Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {workflow.description}
                </Typography>
              </Link>
            </CardContent>
          </Card>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Typography className='subheader2' alignContent='center'>
          Templates
        </Typography>
      </Grid>
      {/* Data Source Cards */}
      {WORKFLOW_TEMPLATES.map(workflow => (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={workflow.id}>
          <Card
            sx={{ display: 'flex', flexDirection: 'column' }}
            component={RouterLink}
            to={workflow.link}
            color='inherit'
          >
            <CardContent
              sx={{ height: '150px', display: 'flex', flexFlow: 'row' }}
            >
              <div style={{ alignSelf: 'center' }}>{workflow.icon}</div>
              <Link
                style={{ alignSelf: 'center' }}
                component={RouterLink}
                to={workflow.link}
                color='inherit'
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography className='card-title'>
                    {workflow.title}
                  </Typography>
                </Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {workflow.description}
                </Typography>
              </Link>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};

export default WorkflowEmptyStateWithNew;
