import AlphaNumericTextField from '../../components/AlphaNumericTextField';
import TooltipIcon from '../../components/TooltipIcon';
import UrlGlobTextField from '../../components/UrlGlobTextField';
import UrlTextField from '../../components/UrlTextField';
import datasources from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
import { splitAndFilterEmptyLines, tryParse } from '../../utils/utils';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormHelperText,
  Grid,
  Link,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NewWebDataSourcePage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [projectName, setProjectName] = useState('');
  const [startingUrls, setStartingUrls] = useState('');
  const [globs, setGlobs] = useState('');
  const [cronSchedule, setCronSchedule] = useState('once');

  const handleSubmit = event => {
    event.preventDefault();

    setIsSubmitting(true);

    // Split startingUrls by newline
    const startingUrlsArray = splitAndFilterEmptyLines(startingUrls);
    console.log('Starting URLs:');
    startingUrlsArray.forEach(url => {
      console.log(url.trim());
    });

    // Split globs by newline
    let globsArray = splitAndFilterEmptyLines(globs);
    console.log('Globs:');
    globsArray.forEach(glob => {
      console.log(glob.trim());
    });
    globsArray = globsArray.filter(ga => !!ga).map(ga => tryParse(ga) ?? ga);

    console.log('Cron Schedule:', cronSchedule);

    datasources
      .create({
        name: projectName,
        type: 'WEB',
        cronSchedule: cronSchedule,
        connectionInfo: {
          startUrls: startingUrlsArray,
          globs: globsArray,
          waitForLoadState: 'domcontentloaded',
        },
      })
      .then(() => {
        toastSuccess('Data source successfully created');

        window.location.href = '/data-sources';
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to create data source');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Container className='newDSMuiContainer-root'>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
        <Link component={RouterLink} to='/' color='inherit'>
          Home
        </Link>
        <Link component={RouterLink} to='/data-sources' color='inherit'>
          Data Sources
        </Link>
        <Link component={RouterLink} to='/data-sources/new' color='inherit'>
          New Data Sources
        </Link>
        <Typography variant='body1' color='text.primary'>
          Web
        </Typography>
      </Breadcrumbs>
      <Grid item xs={12}>
        <Typography className='header' alignContent='center'>
          Web
        </Typography>
      </Grid>

      <Grid container xs={12}>
        <Grid item xs={12} sm={6} md={6} lg={5} xl={5} mb={4}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
              <Typography>Name</Typography>
              <AlphaNumericTextField
                required
                fullWidth
                name='projectName'
                placeholder='My data source'
                sx={{ mb: 2 }}
                value={projectName}
                onChange={event => setProjectName(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>
                Starting URLs
                <TooltipIcon
                  timeout={2500}
                  tooltipText={'A static list of URLs to scrape. One each line'}
                  linkUrl={
                    'https://crawlee.dev/api/core/interface/EnqueueLinksOptions#urls'
                  }
                />
              </Typography>
              <UrlTextField
                required
                fullWidth
                multiline
                rows={4}
                name='startingUrls'
                placeholder='https://example.com'
                helperText='A static list of URLs to scrape. One each line'
                sx={{ mb: 2 }}
                value={startingUrls}
                onChange={event => setStartingUrls(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>
                Globs
                <TooltipIcon
                  timeout={2500}
                  tooltipText={
                    'Glob patterns to match links in the page that you want to enqueue. One each line'
                  }
                  linkUrl={
                    'https://crawlee.dev/api/core/interface/EnqueueLinksOptions#globs'
                  }
                />
              </Typography>
              <UrlGlobTextField
                required
                fullWidth
                multiline
                rows={4}
                name='globs'
                placeholder='https://example.com/**/*'
                helperText='Glob patterns to match links in the page that you want to enqueue. One each line'
                sx={{ mb: 2 }}
                value={globs}
                onChange={event => setGlobs(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>Cron schedule</Typography>
              <Select
                labelId='cron-schedule-label'
                name='cronSchedule'
                value={cronSchedule}
                onChange={event => setCronSchedule(event.target.value)}
                disabled={isSubmitting}
              >
                <MenuItem value='once'>Once</MenuItem>
                <MenuItem value='daily'>Daily</MenuItem>
                <MenuItem value='weekly'>Weekly</MenuItem>
                <MenuItem value='monthly'>Monthly</MenuItem>
              </Select>
              <FormHelperText sx={{ mb: 2 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;The cron schedule determines when the
                scraping task will run.
              </FormHelperText>

              <Button
                type='submit'
                className='createButton'
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Create'}
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} xl={3}>
          &nbsp;
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewWebDataSourcePage;
