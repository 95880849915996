import { ERROR_STREAM_FIX, ERROR_TRY_AGAIN } from '../constants';
import { toastError } from '../utils/toastUI';

export async function processResponse(response, onData) {
  if (!response.ok) {
    onData(ERROR_STREAM_FIX);
    return;
  }

  const reader = response.body.getReader();

  try {
    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }

      const answer = new TextDecoder('utf-8').decode(value);
      onData(answer);
    }
  } catch (error) {
    console.error('Error:', error);
    onData(ERROR_TRY_AGAIN);
  }
}

export async function handleStreamCompletion(response) {
  return response
    .then(async response => {
      const statusCode = Number(response.status);
      if (statusCode === 401) {
        toastError('Session has expired. Please login again');
      }

      return response;
    })
    .then(async response => {
      if (!response.ok) {
        const { error } = await response.json();
        console.log(error);
        toastError(error);
        response.error = error;
      }

      return response;
    });
}

export async function handleCompletion(response) {
  return response
    .then(response => {
      const statusCode = Number(response.status);
      if (statusCode === 401) {
        toastError('Session has expired. Please login again', {
          position: 'top-center',
          autoClose: 2500,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'dark',
          onClose: () => {
            window.location.href = '/login';
          },
        });
      } else if (statusCode > 199 && statusCode < 500) {
        // Request was successful (status code 2xx)
        return response.json();
      } else {
        // Request was not successful
        throw new Error('Request failed');
      }
    })
    .then(body => {
      if (body.error) {
        throw new Error(body.error);
      } else {
        return body.data;
      }
    });
}
