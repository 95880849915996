export const Template0 = {
  nodes: [
    {
      id: '1',
      type: 'triggerNode',
      data: {
        stepName: 'Every Month',
        component: 'schedule',
        scheduleId: 'monthly',
      },
    },
    {
      id: '2',
      type: 'applicationSelectorNode',
      data: {
        component: 'select_application',
        stepName: 'Select an Application', //Conversational Analytics Demo
      },
    },
    {
      id: '3',
      type: 'actionNode',
      data: {
        prompt:
          'Summarize some of the common feature improvements for software bugs category in the last 1 month',
        stepName: 'Improvements From Resolved Tickets',
        component: 'fetch_data',
      },
    },
    {
      id: '4',
      type: 'formatterNode',
      data: {
        component: 'format_data',
        stepName: 'Product improvement report',
        prompt: `Craft an email report  to summarize feature improvements that can be incorporated into the product+engineering roadmap based on the support ticketing data. Dont generate subject. Use markdown. Use below format
      ----------------
Hello,

This is your automated guide providing a summary of actionable feature improvements for our software, derived from the past month's support ticketing data. These insights could be valuable additions to our product and engineering roadmap. Here’s a rundown of the key improvement areas we’ve pinpointed:

### Feature Improvement: [Improvement Area]
Bug 1: [Concise explanation]

Enhancement 1: [Concise explanation]

...
Optimization N: [Concise explanation]


###Feature Improvement: [Another Improvement Area]
Bug 1: [Concise explanation]

Enhancement 1: [Concise explanation]

...
Optimization N: [Concise explanation]


[Continue with the same structure for additional areas]

Your feedback fuels our progress, and these improvements reflect our commitment to excellence and user satisfaction.

Best regards,

The Kwazii CoPilot System
`,
      },
    },
    {
      id: '5',
      type: 'notifyNode',
      data: {
        component: 'send_email',
        stepName: 'Send Email',
      },
    },
  ],
};
