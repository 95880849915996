import usageService from '../../service/usage';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  Link,
  Skeleton,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
  useMediaQuery,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

// import TooltipIcon from '../../components/TooltipIcon';

const steps = [
  {
    label: 'Step 1: Connect your data source',
    to: ['/data-sources/new'],
    toIcons: [
      <img
        alt='z'
        src='/external/zendesk_logo.png'
        style={{
          width: '30px',
          height: '20px',
          paddingRight: '10px',
          color: '#444',
        }}
      />,
      <img
        alt='r'
        src='/external/reamaze_logo.png'
        style={{
          width: '30px',
          height: '20px',
          paddingRight: '10px',
          color: '#444',
        }}
      />,
    ],
    image: '/external/create-data-source.png',
    description:
      'Connect your knowledge base, ticketing, and chat channels so Kwazii can access your customer service information. Our guided setup makes this quick and easy.',
  },
  {
    label: 'Step 2: Explore the Playground',
    to: ['/playground'],
    image: '/external/explore-playground.png',
    description:
      'Playground allows you to experience the power of NLP without coding a single line. Try AI search, Conversational chat and more before integrating it in your product!',
  },
  {
    label: 'Step 3: Create your 1st Application',
    to: ['/applications'],
    image: '/external/create-application.png',
    description:
      'Seamlessly integrate Kwazii AI with leading customer service providers, such as Zendesk and Re:amaze.',
  },
  {
    label: 'Step 4: Explore Insights',
    to: ['/insights'],
    image: '/external/explore-insights.png',
    description:
      'Kwazii automatically surfaces trends, patterns, and opportunities in easy-to-interpret visualizations. Filter by time period, product, team - whatever you need to pinpoint insights.',
  },
  {
    label: 'Step 5: Create Workflow',
    to: ['/workflow'],
    image: '/external/create-workflow.png',
    description:
      'API key is a secure and unique token that enables authorized access to the capabilities of Kwazii API. It allows for easy and secure application with other platforms!',
  },
  {
    label: 'Step 6: (Optional): Create an API key',
    to: ['/api-keys'],
    image: '/external/create-api-key.png',
    description:
      'API key is a secure and unique token that enables authorized access to the capabilities of Kwazii API. It allows for easy and secure application with other platforms!',
  },
  {
    label: 'Step 7: (Optional): Explore the rest APIs',
    to: ['/rest-api'],
    image: '/external/explore-rest.png',
    description:
      'API key is a secure and unique token that enables authorized access to the capabilities of Kwazii API. It allows for easy and secure application with other platforms!',
  },
];

const HomePage = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const isXs = useMediaQuery(theme => theme.breakpoints.down('md'));

  useEffect(() => {
    // Fetching data
    usageService
      .get()
      .then(
        ({
          workflowsCount,
          apiKeyCount,
          datasourceCount,
          queryCount,
          applicationCount,
        }) => {
          // Checking if there are any data sources
          if (!datasourceCount) {
            return;
          }
          // Setting active step to 1 if there are data sources
          setActiveStep(Math.max(activeStep, 1));

          // Checking if there is a non-zero query count in usage data
          if (!queryCount) {
            return;
          }

          // Setting active step to 2 if there is a non-zero query count
          setActiveStep(Math.max(activeStep, 2));

          if (!applicationCount) {
            return;
          }

          // Setting active step to 3 if there is a non-zero applications count
          setActiveStep(Math.max(activeStep, 3));

          // Checking if the user has explored insights pages
          if (!localStorage.getItem('home_get_started_insights_visited')) {
            return;
          }

          // Setting active step to 4  if user has explored insights pages
          setActiveStep(Math.max(activeStep, 4));

          // Checking if there are any API keys
          if (!workflowsCount) {
            return;
          }

          // Setting active step to 2 if there are API keys
          setActiveStep(Math.max(activeStep, 5));

          // Checking if there are any API keys
          if (!apiKeyCount) {
            return;
          }

          // Setting active step to 2 if there are API keys
          setActiveStep(Math.max(activeStep, 6));
        }
      )
      .catch(error => {
        // Handling errors in the promises
        console.log('error', error.message);
      })
      .finally(() => setLoading(false));

    // eslint-disable-next-line
  }, []);

  // const handleNext = () => {
  //     setActiveStep((prevActiveStep) => prevActiveStep + 1);
  // };

  const handleBack = () => {
    setActiveStep(prevActiveStep => Math.max(prevActiveStep - 1, 0));
  };

  const getButtonLabel = () => {
    switch (activeStep) {
      case 0:
        return ['Zendesk', 'Re:amaze'];
      case 1:
        return ['Explore the playground'];
      case 2:
        return ['Create an Application'];
      case 3:
        return ['Explore Insights'];
      case 4:
        return ['Create Workflow'];
      case 5:
        return ['Create an API key'];
      case 6:
        return ['Explore Rest API'];
      default:
        return '';
    }
  };

  return (
    <Container>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Typography variant='h7' component='div'>
              Home
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Typography className='header' alignContent='center'>
            Welcome to Kwazii
          </Typography>
          <Typography style={{ color: '#555' }} mb={2} mt={2}>
            Welcome to Kwazii! We're thrilled to have you on board. Follow the
            below step-by-step guide to get yourself familiar with the platform.
          </Typography>
        </Grid>

        <Grid item xs={12} md={5} lg={4}>
          {loading ? (
            <Box>
              <Skeleton />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
            </Box>
          ) : (
            <Stepper activeStep={activeStep} orientation='vertical'>
              {steps.map(({ label, to, toIcons, description }, index) => (
                <Step key={index}>
                  <StepLabel>
                    <Typography>{label}</Typography>
                  </StepLabel>
                  <StepContent>
                    <div>
                      {activeStep === steps.length ? (
                        <div>
                          <Typography variant='h5' mb={2} mt={2}>
                            You have completed all the steps!
                          </Typography>
                        </div>
                      ) : (
                        <div>
                          <Typography>{description}</Typography>

                          <div>
                            {getButtonLabel().map((lbl, index) => (
                              <Button
                                variant='contained'
                                className='home-button'
                                onClick={handleBack}
                              >
                                <Link
                                  component={RouterLink}
                                  to={to[index]}
                                  style={{
                                    display: 'contents',
                                    color: 'inherit',
                                  }}
                                >
                                  {toIcons?.[index] || <></>}
                                  {lbl}
                                </Link>
                              </Button>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          )}
        </Grid>
        <Grid item xs={12} md={7} lg={8} mt={2} className='home-grid-item'>
          {!isXs &&
            (loading ? (
              <Box>
                <Skeleton />
                <Skeleton animation='wave' height={100} />
                <Skeleton animation='wave' height={100} />
                <Skeleton animation='wave' height={100} />
              </Box>
            ) : (
              steps.map(({ label, image }, index) => (
                <>
                  {activeStep === index && (
                    <img
                      alt={label}
                      src={image}
                      style={{
                        height: '500px',
                        paddingRight: '10px',
                        borderRadius: '10px',
                        boxShadow: '0 0 10px 5px #eee',
                      }}
                    />
                  )}
                </>
              ))
            ))}
        </Grid>
      </Grid>
    </Container>
  );
};

export default HomePage;
