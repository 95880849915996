import analyticsService from '../../../service/analytics';
import { toastError } from '../../../utils/toastUI';
import IntentDistribution from './components/IntentDistribution';
import Overview from './components/Overview';
import TicketSentimentScore from './components/TicketSentimentScore';
import TicketsTable from './components/TicketsTable';
import FeatureDistribution from './components/featureDistribution';
import { Grid, Skeleton, Typography } from '@mui/material';
import React, { useCallback, useEffect, useState } from 'react';

const HistoricalInsightsPage = ({ application, startDate, endDate }) => {
  // eslint-disable-next-line
  const [conversationLengthData, setConversationLengthData] = useState([]);
  const [answerScoreDistribution, setAnswerScoreDistribution] = useState([]);
  const [trendingFeatureCategory, setTrendingFeatureCategory] = useState([]);

  const [intentTreemapData, setIntentTreemapData] = useState([]);
  const [intentCategory, setIntentCategory] = useState({ category: '' });

  const [featureImprovementTreemapData, setFeatureImprovementTreemapData] =
    useState([]);
  const [tickets, setTickets] = useState([]);

  const [featureImprovementCategory, setFeatureImprovementCategory] = useState({
    category: '',
  });

  const [loading, setLoading] = useState(true);

  // Fetch analytics
  useEffect(() => {
    Promise.all([
      analyticsService.get(
        application?.id,
        'historical',
        startDate.valueOf(),
        endDate.valueOf(),
        'tickets'
      ),
      analyticsService.get(
        application?.id,
        'historical',
        startDate.valueOf(),
        endDate.valueOf(),
        'trendingFeatureCategory'
      ),
      analyticsService.get(
        application?.id,
        'historical',
        startDate.valueOf(),
        endDate.valueOf(),
        'conversationLength'
      ),
      analyticsService.get(
        application?.id,
        'historical',
        startDate.valueOf(),
        endDate.valueOf(),
        'answerScoreDistribution'
      ),
      analyticsService.get(
        application?.id,
        'historical',
        startDate.valueOf(),
        endDate.valueOf(),
        'intentCategoryData'
      ),
      // Feature Improvement
      analyticsService.get(
        application?.id,
        'historical',
        startDate.valueOf(),
        endDate.valueOf(),
        'featureImprovementCategoryData'
      ),
    ])
      .then(results => {
        setTickets(results[0]);
        setTrendingFeatureCategory(results[1]);
        setConversationLengthData(results[2]);
        setAnswerScoreDistribution(results[3]);
        setIntentTreemapData(results[4]);
        setFeatureImprovementTreemapData(results[5]);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDate, endDate, application]);

  // Fetch analytics
  useEffect(() => {
    setLoading(true);
  }, []);

  const handleSetFeatureCategory = useCallback(
    category => setFeatureImprovementCategory({ category }),
    [
      /* dependencies */
    ]
  );
  const handleSetIntentCategory = useCallback(
    category => setIntentCategory({ category }),
    [
      /* dependencies */
    ]
  );

  return loading ? (
    <Grid container xs={12}>
      <Grid item xs={12} ml={2}>
        <Skeleton />
        <Skeleton animation='wave' height={100} />
        <Skeleton animation='wave' height={100} />
        <Skeleton animation='wave' height={100} />
      </Grid>
    </Grid>
  ) : (
    <Grid container xs={12}>
      <Grid container xs={12} spacing={2} sx={{ ml: 0, mt: 0, mr: 1 }}>
        <Overview
          questions={tickets}
          trendingFeatureCategory={trendingFeatureCategory}
        />

        <TicketSentimentScore
          answerScoreDistribution={answerScoreDistribution}
          application={application}
        />
      </Grid>
      {/* -------------------------- */}
      <Grid container xs={12} spacing={2} sx={{ ml: 0, mt: 0, mr: 1 }}>
        <IntentDistribution
          intentTreemapData={intentTreemapData}
          selected={intentCategory?.category}
          setIntentCategory={handleSetIntentCategory}
        />

        <FeatureDistribution
          featureTreemapData={featureImprovementTreemapData}
          selected={featureImprovementCategory?.category}
          setFeatureCategory={handleSetFeatureCategory}
        />
      </Grid>
      {/* -------------------------- */}
      <TicketsTable
        application={application}
        data={tickets}
        selectedIntent={intentCategory?.category}
        selectedFeature={featureImprovementCategory?.category}
        setIntentCategory={handleSetIntentCategory}
        setFeatureCategory={handleSetFeatureCategory}
      />

      <Typography
        variant='small'
        style={{
          margin: '10px',
          marginLeft: '20px',
          fontSize: '0.75rem',
          fontWeight: 700,
        }}
      >
        * processed as per the cron schedule
      </Typography>
    </Grid>
  );
};

export default HistoricalInsightsPage;
