import { Card, CardContent, Grid, Typography } from '@mui/material';
import moment from 'moment-timezone';
import React from 'react';
import {
  CartesianGrid,
  Legend,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';

function DashboardOverview({ data, setRange }) {
  const customLabelFormatter = (value, entry, index) => {
    return `${value === 'notAnswered' ? 'Unanswered' : 'Answered'}`;
  };

  const customTooltipFormatter = (value, name, entry, index) => {
    return [
      `${value}`,
      `${name === 'notAnswered' ? 'Unanswered' : 'Answered'}`,
    ];
  };

  const handleBarClick = selected => {
    const date = selected.activePayload[0]?.payload._idDate;
    const startDate = moment.tz(date, 'UTC');
    const endDate = moment.tz(date, 'UTC').add(1, 'day');
    setRange(startDate, endDate);
  };

  return (
    <Grid container xs={12} spacing={2} sx={{ ml: 0, mt: 0, mr: 1 }}>
      <Grid item xs={12} md={6}>
        <a href='#tickets' style={{ textDecoration: 'none' }}>
          <Card>
            <CardContent>
              <Typography color='textSecondary' gutterBottom>
                Questions Answered
              </Typography>
              <Typography
                variant='h5'
                component='h2'
                sx={{ fontSize: '55px !important' }}
              >
                {data?.reduce((accum, d) => accum + d.answered, 0)}
              </Typography>
            </CardContent>
          </Card>
        </a>
      </Grid>

      <Grid item xs={12} md={6}>
        <a href='#tickets' style={{ textDecoration: 'none' }}>
          <Card>
            <CardContent>
              <Typography color='textSecondary' gutterBottom>
                Questions Unanswered
              </Typography>
              <Typography
                variant='h5'
                component='h2'
                sx={{ fontSize: '55px !important' }}
              >
                {data?.reduce((accum, d) => accum + d.notAnswered, 0)}
              </Typography>
            </CardContent>
          </Card>
        </a>
      </Grid>

      {/* <Grid item xs={12} md={4}>
        <Card>
          <CardContent>
            <Typography color="textSecondary" gutterBottom>
              Average Conversation Length
            </Typography>
            <Typography variant="h5" component="h2" sx={{ fontSize: "55px !important" }}>
              1.5 min
            </Typography>
          </CardContent>
        </Card>
      </Grid> */}

      <Grid item xs={12}>
        <Card>
          <CardContent>
            <Typography color='textSecondary' gutterBottom>
              Questions Answered vs. Unanswered
            </Typography>
            <ResponsiveContainer debounce='0' width='99%' height={300}>
              <LineChart
                width={500}
                height={300}
                data={data}
                margin={{
                  top: 5,
                  right: 30,
                  left: 20,
                  bottom: 5,
                }}
                onClick={handleBarClick}
              >
                <CartesianGrid strokeDasharray='3 3' />
                <XAxis dataKey='_id' tick={{ fontSize: '0.8rem' }} />
                <YAxis width={40} tick={{ fontSize: '0.8rem' }} />
                <Tooltip formatter={customTooltipFormatter} />
                <Legend formatter={customLabelFormatter} />
                <Line
                  type='monotone'
                  dataKey='answered'
                  stroke='#555555'
                  strokeWidth={2}
                  activeDot={{ r: 8 }}
                />
                <Line
                  type='monotone'
                  dataKey='notAnswered'
                  strokeWidth={1}
                  display='Not Answered'
                  stroke='#989898'
                />
              </LineChart>
            </ResponsiveContainer>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

export default DashboardOverview;
