import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';

const TooltipIcon = ({ tooltipText, linkUrl, timeout }) => {
  const [open, setOpen] = useState(false);

  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
    if (timeout) {
      setTimeout(() => {
        setOpen(false);
      }, timeout);
    }
  };

  return (
    <Tooltip
      title={
        <Typography sx={{ fontSize: '0.85rem' }}>
          {tooltipText}
          {linkUrl && (
            <>
              <br />
              <a
                href={linkUrl}
                target='_blank'
                rel='noreferrer'
                style={{ color: 'white' }}
              >
                More Info
              </a>
            </>
          )}
        </Typography>
      }
      open={open}
    >
      <IconButton
        className='padding-0'
        aria-label={tooltipText}
        onMouseOver={handleTooltipOpen}
        onMouseOut={timeout ? () => {} : handleTooltipClose}
        onClick={handleTooltipOpen}
      >
        <InfoOutlined />
      </IconButton>
    </Tooltip>
  );
};

export default TooltipIcon;
