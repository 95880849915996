import AlphaNumericTextField from '../../../components/AlphaNumericTextField';
import { splitAndFilterEmptyLines } from '../../../utils/utils';
import { NewWorkflowContext } from '../Context';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import React, { useContext, useState } from 'react';

const DispatchEmail = () => {
  const { selectedNode, onNodeUpdate } = useContext(NewWorkflowContext);
  const [stepName, setStepName] = useState(selectedNode?.data?.stepName);
  const [subject, setSubject] = useState(selectedNode?.data?.subject);
  const [emails, setEmails] = useState(selectedNode?.data?.emails?.join('\n'));

  const onUpdateEmails = updatedEmails => {
    setEmails(updatedEmails);

    const updatedNode = {
      ...(selectedNode || {}),
      data: {
        ...(selectedNode?.data || {}),
        state: {},
        emails: splitAndFilterEmptyLines(updatedEmails),
      },
    };

    onNodeUpdate(updatedNode);
  };

  const onUpdateStepName = updatedStepName => {
    setStepName(updatedStepName);

    const updatedNode = {
      ...(selectedNode || {}),
      state: {},
      data: {
        ...(selectedNode?.data || {}),
        stepName: updatedStepName,
      },
    };

    onNodeUpdate(updatedNode);
  };

  const onUpdateSubject = subject => {
    setSubject(subject);

    const updatedNode = {
      ...(selectedNode || {}),
      state: {},
      data: {
        ...(selectedNode?.data || {}),
        subject: subject,
      },
    };

    onNodeUpdate(updatedNode);
  };

  const handleSubmit = event => {
    event.preventDefault();
  };

  return (
    <Grid container xs={12} style={{ height: '100%' }}>
      <Grid item xs={12} style={{ height: '100%' }} mt={1}>
        <Splitter layout='vertical' style={{ height: '100%', border: 0 }}>
          <SplitterPanel className='flex align-items-center justify-content-center'>
            <form onSubmit={handleSubmit}>
              <Box sx={{ display: 'flex', flexDirection: 'column' }} ml={2}>
                <Typography>Name</Typography>
                <AlphaNumericTextField
                  required
                  fullWidth
                  name='stepName'
                  placeholder='e.g. My mailing list'
                  sx={{ mb: 2 }}
                  value={stepName}
                  onChange={event => onUpdateStepName(event.target.value)}
                />

                <Typography>Subject</Typography>
                <TextField
                  required
                  fullWidth
                  name='stepName'
                  placeholder='<Email Subject>'
                  sx={{ mb: 2 }}
                  value={subject}
                  onChange={event => onUpdateSubject(event.target.value)}
                />

                <Typography mb={1.5}>
                  Emails <small>(one per line)</small>
                </Typography>
                <TextField
                  label='e.g. abx@xyz.com'
                  placeholder='e.g. abx@xyz.com'
                  required={true}
                  multiline={true}
                  minRows={7}
                  variant='outlined'
                  value={emails}
                  onChange={event => onUpdateEmails(event.target.value)}
                  size='small'
                />
                <br />
              </Box>
            </form>
            <br />
          </SplitterPanel>
          <SplitterPanel className='flex align-items-center justify-content-center'>
            <></>
          </SplitterPanel>
        </Splitter>
      </Grid>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
    </Grid>
  );
};

export default DispatchEmail;
