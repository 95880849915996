import { APPLICATION_TYPES_MAP } from '../../../constants';
import applicationService from '../../../service/applications';
import { toastError } from '../../../utils/toastUI';
import { NewWorkflowContext } from '../Context';
import { Box, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useContext, useEffect, useState } from 'react';

const ApplicationSelector = () => {
  const { selectedNode, onNodeUpdate } = useContext(NewWorkflowContext);
  const [selectedApplicationId, setSelectedApplicationId] = useState(
    selectedNode?.data?.applicationId
  );
  const [applications, setApplications] = useState([]);

  const onUpdateApplication = appId => {
    setSelectedApplicationId(appId);

    const updatedNode = {
      ...(selectedNode || {}),
      data: {
        ...(selectedNode?.data || {}),
        state: {},
        stepName: applications.find(a => a.id === appId).name,
        applicationId: appId,
      },
    };

    onNodeUpdate(updatedNode);
  };

  // Fetch applications
  useEffect(() => {
    applicationService
      .list()
      .then(data => {
        setApplications(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch applications');
      });
  }, [setApplications]);

  return (
    <Grid container xs={12} pl={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
          <Typography mb={1.5}>Select Application</Typography>
          <Select
            labelId='app-schedule-label'
            name='selectedApplication'
            value={selectedApplicationId}
            onChange={event => onUpdateApplication(event.target.value)}
          >
            {applications
              .filter(app =>
                [
                  APPLICATION_TYPES_MAP.HISTORICAL_SUPPORT_DATA,
                  APPLICATION_TYPES_MAP.REAMAZE_SEARCH,
                  APPLICATION_TYPES_MAP.ZENDESK_SEARCH,
                ].includes(app.type)
              )
              .map(app => (
                <MenuItem value={`${app.id}`}>{app.name}</MenuItem>
              ))}
          </Select>
          <br />
        </Box>
      </Grid>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
    </Grid>
  );
};

export default ApplicationSelector;
