import Banner from '../components/Banner';
import auth from '../service/auth';
import { getUrlParam } from '../utils/utils';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const LoginPage = () => {
  const refCode = getUrlParam('ref', '');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [waitlist, setWaitlist] = useState(false);
  const [emailMessage, setEmailMessage] = useState('');
  const ots = getUrlParam('ots', '');

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      document.querySelector('#loginButton').click();
    }
  };
  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError('Invalid email');
      return;
    }

    setLoading(true);
    setEmailError(null);

    // Proceed with login or registration logic
    await auth
      .login(email, ots, refCode)
      .then(data => {
        setEmailError('');
        setEmailMessage(data.message);
        setWaitlist(!!data.waitlist);
      })
      .catch(error => {
        console.log('error', error.message);
        setEmailError(error.message);
        setEmailMessage('');
      })
      .finally(() => setLoading(false));
  };

  const validateEmail = email => {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div
      className='greeting-container'
      style={{ display: 'flex', justifyContent: 'center', width: '100vw' }}
    >
      <Banner></Banner>
      <Container className='login-container greeting-animation'>
        <Box className='login-logoContainer'>
          <a href='https://www.kwazii.app/'>
            <img class='frame' alt='Kwazii' src='/logo_1.png' />
          </a>
        </Box>
        <Grid container spacing={2} alignItems={'flex-start'} mt={10}>
          <Grid item xs={12} md={6}>
            <Typography
              fontWeight={'500'}
              fontSize={'1.1rem !important'}
              mb={1}
            >
              Get started with Kwazii
            </Typography>
            <Typography>
              <u>Log in</u> or <u>Create</u> an account. Quickly get started by
              signing in using your existing accounts.
            </Typography>
          </Grid>

          <Grid item xs={12} md={6} container direction='column' spacing={2}>
            {waitlist ? (
              <Grid item>
                <div className='login-waitlist-helper-text'>{emailMessage}</div>
              </Grid>
            ) : (
              <>
                <Grid item>
                  <Typography fontWeight={'500'} fontSize={'1rem !important'}>
                    Enter your company email
                  </Typography>
                  <TextField
                    fullWidth
                    variant='outlined'
                    value={email}
                    onChange={handleEmailChange}
                    onKeyPress={handleKeyPress}
                    helperText={
                      emailMessage ? (
                        <div className='login-success-helper-text'>
                          {emailMessage}
                        </div>
                      ) : emailError ? (
                        <div className='login-error-helper-text'>
                          {emailError}
                        </div>
                      ) : (
                        ''
                      )
                    }
                  />
                </Grid>
                <Grid item style={{ paddingTop: '8px' }}>
                  <Typography fontSize={'0.7rem !important'}>
                    We'll email you a link for a password-free
                    login/registration.
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography>
                    By proceeding, you are agreeing to Kwazii's{' '}
                    <a
                      className='external-link'
                      href='https://kwazii.app/tos'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Terms of Service
                    </a>{' '}
                    and{' '}
                    <a
                      className='external-link'
                      href='https://kwazii.app/tos'
                      target='_blank'
                      rel='noreferrer'
                    >
                      Privacy Notice
                    </a>
                    .
                  </Typography>
                </Grid>
                <Grid
                  item
                  justifyContent={'center'}
                  display={'flex'}
                  style={{ paddingTop: '24px' }}
                >
                  <LoadingButton
                    id='loginButton'
                    className='loginButton'
                    onClick={handleSubmit}
                    loading={loading}
                    loadingPosition='end'
                    variant='contained'
                  >
                    <span>Continue</span>
                  </LoadingButton>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default LoginPage;
