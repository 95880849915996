import { getAnalyticsEndpoint } from '../utils/utils';
import {
  handleCompletion,
  handleStreamCompletion,
  processResponse,
} from './common';

async function get(
  applicationId,
  applicationType,
  startDate,
  endDate,
  category,
  signal
) {
  const token = localStorage.getItem('auth_token');
  const url = `${getAnalyticsEndpoint()}/${applicationType}/${category}?startDate=${startDate}&endDate=${endDate}${
    applicationId ? '&applicationId=' + applicationId : ''
  }`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'GET',
    headers,
    signal,
  };

  return handleCompletion(fetch(url, requestOptions));
}

async function getStream(
  applicationId,
  applicationType,
  startDate,
  endDate,
  category,
  onData,
  signal
) {
  const token = localStorage.getItem('auth_token');
  const url = `${getAnalyticsEndpoint()}/${applicationType}/${category}?stream=true&startDate=${startDate}&endDate=${endDate}${
    applicationId ? '&applicationId=' + applicationId : ''
  }`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'GET',
    headers,
    signal,
  };

  return handleStreamCompletion(fetch(url, requestOptions)).then(response =>
    processResponse(response, onData)
  );
}

async function getCategoryQuestions(
  applicationId,
  applicationType,
  startDate,
  endDate,
  category,
  intentCategory
) {
  const token = localStorage.getItem('auth_token');
  const url = `${getAnalyticsEndpoint()}/${applicationType}/${category}?startDate=${startDate}&endDate=${endDate}${
    applicationId ? '&applicationId=' + applicationId : ''
  }&intentCategory=${encodeURI(intentCategory)}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

async function getStateMachine(applicationId, applicationType, slug) {
  const token = localStorage.getItem('auth_token');
  const url = `${getAnalyticsEndpoint()}/${applicationType}/stateMachine?slug=${slug}&${
    applicationId ? '&applicationId=' + applicationId : ''
  }`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

const fns = {
  get,
  getStream,
  getCategoryQuestions,
  getStateMachine,
};

export default fns;
