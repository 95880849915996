import datasourcesService from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
// import { CloudUpload } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import { DeleteOutline } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Grid,
  IconButton,
  LinearProgress,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Toolbar,
  Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Link as RouterLink, useParams } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  dropzone: {
    border: '2px dashed #888',
    padding: theme.spacing(2),
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: theme.spacing(2),
  },
  active: {
    borderColor: '#00cc66',
  },
  fileContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(2),
  },
  fileName: {
    flexGrow: 1,
    marginLeft: theme.spacing(1),
  },
  button: {
    marginTop: theme.spacing(2),
  },
}));

const EditFilesDataSourcePage = () => {
  const { datasourceId } = useParams();
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const [datasource, setDatasource] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    datasourcesService
      .get(datasourceId)
      .then(data => {
        setDatasource(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch data source');
      })
      .finally(() => setLoading(false));
  }, [datasourceId, setDatasource]);

  const onDrop = useCallback(acceptedFiles => {
    setFiles(acceptedFiles);
  }, []);

  const handleDeleteLocalFileKey = fileToDelete => {
    const result = window.confirm('Are you sure you want to proceed?');
    if (result) {
      setFiles(files.filter(f => f !== fileToDelete));
    } else {
      // User clicked "Cancel"
      console.log('Cancelled.');
    }
  };

  const handleDeleteFileKey = id => {
    const result = window.confirm('Are you sure you want to proceed?');
    if (result) {
      handleConfirm(id);
    } else {
      // User clicked "Cancel"
      console.log('Cancelled.');
    }
  };

  const handleDeleteDatasourceKey = () => {
    const result = window.confirm('Are you sure you want to proceed?');
    if (result) {
      datasourcesService
        .remove(datasourceId)
        .then(() => {
          toastSuccess('Data source successfully marked for deletion');

          setTimeout(() => {
            window.location.href = '/data-sources';
          }, 500);
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to delete data sources');
        });
    } else {
      // User clicked "Cancel"
      console.log('Cancelled.');
    }
  };

  const handleConfirm = fileId => {
    datasourcesService
      .removeFile(datasourceId, fileId)
      .then(() => {
        toastSuccess('File successfully deleted');

        setTimeout(() => {
          // eslint-disable-next-line
          window.location.href = window.location.href;
        }, 500);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to delete file');
      });
  };

  const uploadFiles = async () => {
    try {
      if (files.length === 0) {
        return;
      }

      setUploading(true);

      const formData = new FormData();
      files.forEach(file => {
        formData.append(file.name, file);
      });

      // Make a POST request to your backend API
      datasourcesService
        .upload(datasourceId, formData)
        .then(() => {
          toastSuccess('Files uploaded successfully');

          setTimeout(() => {
            // eslint-disable-next-line
            window.location.href = window.location.href;
          }, 500);
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to upload files');
        })
        .finally(() => {
          setFiles([]);
          setUploading(false);
        });
    } catch (error) {
      console.error('Error uploading files:', error);
      setUploading(false);
    }
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'text/*': ['.docx', '.txt', '.pdf'],
    }, // Change this to allow other file types if needed=
    multiple: true,
  });

  return (
    <Container className='newDSMuiContainer-root'>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
            <Link component={RouterLink} to='/' color='inherit'>
              Home
            </Link>
            <Link component={RouterLink} to='/data-sources' color='inherit'>
              Data Sources
            </Link>
            <Typography variant='body1' color='text.primary'>
              Files
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}>
          <Typography className='header' alignContent='center'>
            {datasource ? datasource.name : 'Files'}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          <Button
            className='createButton'
            startIcon={<DeleteOutline />}
            variant='contained'
            onClick={handleDeleteDatasourceKey}
          >
            Delete
          </Button>
        </Grid>

        <Grid container xs={12}>
          <Grid item xs={12} sm={6} md={6} lg={5} xl={5} mb={4}>
            <Grid item xs={12} className='fileUpload'>
              <Paper
                {...getRootProps()}
                className={`${classes.dropzone} ${
                  isDragActive ? classes.active : ''
                }`}
                variant='outlined'
              >
                <input {...getInputProps()} />
                <p>Drag and drop some files here or click to select files</p>
              </Paper>
              {files.length ? (
                <Grid item xs={12}>
                  <TableContainer component={Paper}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>Name</TableCell>
                          <TableCell>Action</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {files.map(file => (
                          <TableRow key={file.name}>
                            <TableCell>
                              <Typography variant='h6' component='h2'>
                                {file.name || '-'}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <IconButton
                                sx={{ fontSize: '20px' }}
                                onClick={() => handleDeleteLocalFileKey(file)}
                                color='secondary'
                              >
                                <DeleteOutline />
                              </IconButton>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              ) : (
                <></>
              )}
              {uploading && <LinearProgress />}
              <Toolbar />
              <Button
                variant='contained'
                color='primary'
                onClick={uploadFiles}
                disabled={uploading || files.length === 0}
                className={classes.button}
              >
                Upload
              </Button>
            </Grid>

            <Grid item xs={12} ml={2}>
              <Typography className='subheader' alignContent='center'>
                Already uploaded
              </Typography>
            </Grid>

            {loading ? (
              <Grid item xs={12} ml={2}>
                <Box>
                  <Skeleton />
                  <Skeleton animation='wave' height={100} />
                  <Skeleton animation='wave' height={100} />
                  <Skeleton animation='wave' height={100} />
                </Box>
              </Grid>
            ) : (
              <Grid item xs={12} ml={2}>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Created</TableCell>
                        <TableCell>Action</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {datasource?.connectionInfo?.files?.map(file => (
                        <TableRow key={file.fileId}>
                          <TableCell>
                            <Typography variant='h6' component='h2'>
                              {file.filename || '-'}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant='h6' component='h2'>
                              {moment(file.created || Date.now()).format(
                                'MMMM Do YYYY, h:mm:ss a'
                              )}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <IconButton
                              sx={{ fontSize: '20px' }}
                              onClick={() => handleDeleteFileKey(file.fileId)}
                              color='secondary'
                            >
                              <DeleteOutline />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Grid>
            )}
          </Grid>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} xl={3}>
          &nbsp;
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditFilesDataSourcePage;
