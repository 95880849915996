import { ERROR_STREAM_FIX, ERROR_TRY_AGAIN } from '../constants';
import { getReamazeEndpoint } from '../utils/utils';
import { handleStreamCompletion } from './common';

export async function fetchReamazeAdminAnswer(
  applicationId,
  section,
  slug,
  apiKey,
  onData
) {
  const url = `${getReamazeEndpoint()}/applications/${applicationId}/slugs/${slug}/sections/${section}`;
  const headers = {
    'Content-Type': 'application/json',
    'X-KWAZII-API-KEY': apiKey,
  };

  const controller = new AbortController();
  const signal = controller.signal;

  const responseStream = handleStreamCompletion(
    fetch(url, {
      method: 'POST',
      headers: headers,
      signal: signal,
    })
  )
    .then(async response => {
      if (!response.ok) {
        onData(response.error || ERROR_STREAM_FIX);
        // throw new Error('Network response was not ok');
        return;
      }

      const reader = response.body.getReader();

      return new Promise((resolve, reject) => {
        function read() {
          reader
            .read()
            .then(({ done, value }) => {
              if (done) {
                resolve();
                return;
              }

              const answer = new TextDecoder('utf-8').decode(value);
              onData(answer);
              read();
            })
            .catch(error => {
              reject(error);
            });
        }

        read();
      });
    })
    .catch(error => {
      console.error('Error:', error);
      onData(ERROR_TRY_AGAIN);
    });

  return Promise.resolve({ responseStream, controller });
}
