import { truncateText } from '../utils/utils';
import React, { useCallback } from 'react';
import ReactFlow, {
  Background,
  Controls,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import 'reactflow/dist/style.css';

const ReactFlowStateMachine = ({ stateMachineData }) => {
  const initialNodes = [];
  const initialEdges = [];

  // eslint-disable-next-line
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const onConnect = useCallback(
    params => setEdges(eds => addEdge(params, eds)),
    [setEdges]
  );

  if (!stateMachineData) {
    return <></>;
  }

  let lastY = 0;
  let index = 0;
  let edge = 0;
  stateMachineData.map(annotation => {
    // Persona node
    initialNodes.push({
      id: `${index++}`,
      type: 'default',
      data: {
        label: annotation.persona,
      },
      position: { x: 100, y: lastY },
    });

    lastY += 30;

    // Transition edge
    initialEdges.push({
      id: `edge-${edge++}`,
      source: `${index - 1}`,
      target: `${index}`,
      animated: true,
    });

    // State node
    initialNodes.push({
      id: `${index++}`,
      type: 'default',
      style: { background: '#ddcdf2' },
      data: {
        label: annotation.current_state,
      },
      position: { x: 110, y: lastY },
    });

    lastY += 100;

    // Transition edge
    initialEdges.push({
      id: `edge-${edge++}`,
      label: annotation.action,
      style: { border: 'solid 1px #777', padding: '3px' },
      source: `${index - 1}`,
      target: `${index}`,
      animated: true,
    });

    // Message node
    initialNodes.push({
      id: `${index++}`,
      type: 'default',
      data: {
        label: truncateText(annotation.message, 100),
      },
      position: { x: 100, y: lastY },
    });

    lastY += 150;

    // if (annotation.improvement?.improvement_description) {
    //     // Improvement node
    //     initialNodes.push({
    //         id: `${index}-1`,
    //         type: 'default',
    //         data: {
    //             label: annotation.improvement.improvement_description,
    //         },
    //         position: { x: 500, y: lastY - 50 },
    //     });

    //     // Transition edge
    //     initialEdges.push({
    //         id: `edge-${edge++}`,
    //         label: `Suggested improvement: ${annotation.improvement.improvement_category}`,
    //         source: `${index - 1}`,
    //         target: `${index}-1`
    //     });
    // }

    // Transition edge
    initialEdges.push({
      id: `edge-${edge++}`,
      source: `${index - 1}`,
      target: `${index}`,
      animated: true,
    });

    return {};
  });

  return (
    <div style={{ height: '90vh', width: '100%' }}>
      <ReactFlow
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
      >
        <Background variant='dots' gap={12} size={1} />
        <Controls />
      </ReactFlow>
    </div>
  );
};

export default ReactFlowStateMachine;
