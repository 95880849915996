import applicationService from '../../service/applications';
import { toastError } from '../../utils/toastUI';
import { snakeCasetoTitlecase } from '../../utils/utils';
import InsightsPageEmptyState from './InsightsPageEmptyState';
import { Add } from '@mui/icons-material';
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const InsightsPage = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch applications
  useEffect(() => {
    applicationService
      .list()
      .then(data => {
        setApplications(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch applications');
      })
      .finally(() => setLoading(false));
  }, [setApplications]);

  return (
    <Container maxWidth='100%'>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Typography variant='h7' component='div'>
              Insights
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}>
          <Typography className='header' alignContent='center'>
            Insights
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          <Button
            className='createButton'
            component={RouterLink}
            to='new'
            startIcon={<Add />}
            variant='contained'
          >
            Add
          </Button>
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Box>
              <Skeleton />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
            </Box>
          </Grid>
        ) : applications.length ? (
          applications
            .filter(app => app.analytics)
            .map(application => (
              <Grid
                item
                xs={12}
                sm={6}
                md={4}
                lg={4}
                xl={3}
                key={application.id}
              >
                <Card>
                  <CardContent>
                    <Link
                      component={RouterLink}
                      to={`/insights/${application.id}`}
                      color='inherit'
                    >
                      <Typography className='card-title'>
                        {application.name}
                      </Typography>
                      <Typography className='card-sub-title'>
                        {snakeCasetoTitlecase(application.type)}
                      </Typography>
                    </Link>
                  </CardContent>
                </Card>
              </Grid>
            ))
        ) : (
          <InsightsPageEmptyState />
        )}
      </Grid>
    </Container>
  );
};

export default InsightsPage;
