import { snakeCasetoTitlecase } from '../utils/utils';
import Chip from '@material-ui/core/Chip';
import React from 'react';

const StatusPill = ({ status, actual }) => {
  let pillClass = '';

  switch (status) {
    case 'pending':
      pillClass = 'status-pending';
      break;
    case 'inProgress':
      pillClass = 'status-in-progress';
      break;
    case 'completed':
      pillClass = 'status-completed';
      break;
    default:
      break;
  }

  return actual !== 'SUCCESS' ? (
    <Chip
      label={snakeCasetoTitlecase(actual)}
      className={`status-pill ${pillClass}`}
    />
  ) : (
    <></>
  );
};

export default StatusPill;
