import usageService from '../../service/usage';
import { Link, List, ListItem } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Breadcrumbs, Grid, Skeleton, Typography } from '@mui/material';
import { Box, Container } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  title: {
    width: '250px',
  },
}));

const AccountUsageList = () => {
  const [data, setData] = useState({
    projectCount: 0,
    maxProjects: 0,
    apiKeyCount: 0,
    maxAPIKeys: 0,
    datasourceCount: 0,
    maxDatasources: 0,
    queryCount: 0,
    maxQueries: 0,
    applicationCount: 0,
    maxApplications: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const usPromise = usageService.get(); // Fetch usage data
    usPromise.then(data => setData(data)).finally(() => setLoading(false));
  }, []);

  const classes = useStyles();

  return (
    <Container maxWidth='100%'>
      <Grid container spacing={3} mb={5}></Grid>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Typography variant='h7' component='div'>
              Account Usage
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Typography className='header' alignContent='center'>
            Current Usage
          </Typography>
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Box>
              <Skeleton />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
            </Box>
          </Grid>
        ) : (
          <Grid item xs={12}>
            <List>
              <ListItem>
                <Typography className={classes.title}>Datasources:</Typography>
                <Typography className={classes.title}>
                  {`${data.datasourceCount} / ${data.maxDatasources}`}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography className={classes.title}>Applications:</Typography>
                <Typography className={classes.title}>
                  {`${data.applicationCount} / ${data.maxApplications}`}
                </Typography>
              </ListItem>
              <ListItem>
                <Typography className={classes.title}>Workflows:</Typography>
                <Typography className={classes.title}>
                  {`${data.workflowsCount} / ${data.maxWorkflows}`}
                </Typography>
              </ListItem>
            </List>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default AccountUsageList;
