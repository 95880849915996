// SpecialWelcomeBanner.js
import { getUrlParam } from '../utils/utils';
import React from 'react';

const Banner = () => {
  const refCode = getUrlParam('ref', '');

  if (['ada_cx_01', 'ada_cx_02', 'ada_cx_03'].includes(refCode)) {
    return (
      <div className='welcome-banner'>
        <span>
          Welcome, Ada Interact Attendee! 🚀 Embrace the Future of Innovation
          with Us! 🌟
        </span>
      </div>
    );
  }

  if (['lnkd_cx_01', 'lnkd_cx_02', 'lnkd_cx_03'].includes(refCode)) {
    return (
      <div className='welcome-banner'>
        <span>
          Welcome, LinkedIn Member! 🚀 Embrace the Future of Innovation with Us!
          🌟
        </span>
      </div>
    );
  }

  return null;
};

export default Banner;
