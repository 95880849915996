import applicationService from '../../service/applications';
import { toastError } from '../../utils/toastUI';
import { snakeCasetoTitlecase } from '../../utils/utils';
import ApplicationPageEmptyStateWithNew from './ApplicationPageEmptyStateWithNew';
import { Add } from '@mui/icons-material';
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const ApplicationPage = () => {
  const [applications, setApplications] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch applications
  useEffect(() => {
    applicationService
      .list()
      .then(data => {
        setApplications(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch applications');
      })
      .finally(() => setLoading(false));
  }, [setApplications]);

  return (
    <Container maxWidth='100%'>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Typography variant='h7' component='div'>
              Applications
            </Typography>
          </Breadcrumbs>
        </Grid>
        <Grid item xs={6}>
          <Typography className='header' alignContent='center'>
            {applications.length ? 'Applications' : 'Add an Application'}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          {applications.length ? (
            <Button
              className='createButton'
              component={RouterLink}
              to='new'
              startIcon={<Add />}
              variant='contained'
            >
              Add
            </Button>
          ) : (
            <></>
          )}
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Box>
              <Skeleton />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
            </Box>
          </Grid>
        ) : applications.length ? (
          applications.map(application => (
            <Grid item xs={12} sm={6} md={4} lg={4} xl={3} key={application.id}>
              <Card
                component={RouterLink}
                to={`/applications/${application.type.toLowerCase()}/${
                  application.id
                }`}
                color='inherit'
              >
                <CardContent>
                  <Link
                    component={RouterLink}
                    to={`/applications/${application.type.toLowerCase()}/${
                      application.id
                    }`}
                    color='inherit'
                  >
                    <Typography className='card-title'>
                      {application.name}
                    </Typography>
                    <Typography className='card-sub-title'>
                      {snakeCasetoTitlecase(
                        application.subType || application.type
                      )}
                    </Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <ApplicationPageEmptyStateWithNew />
        )}
      </Grid>
    </Container>
  );
};

export default ApplicationPage;
