import { getDatasourcesEndpoint } from '../utils/utils';
import { handleCompletion } from './common';

/**
 * Creates a new datasource.
 * @param {Object} datasource - The datasource object to create.
 * @returns {Promise<Object>} - A promise that resolves to the created datasource object.
 * @throws {Error} - If the request fails.
 */
async function create(datasource) {
  const token = localStorage.getItem('auth_token');
  const url = `${getDatasourcesEndpoint()} `;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  // Convert datasource object to JSON string
  var body = JSON.stringify(datasource);

  var requestOptions = {
    method: 'POST',
    headers,
    body,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Updates exiting datasource.
 * @param {Object} application - The datasource object to update.
 * @returns {Promise<Object>} - A promise that resolves to the created application object.
 * @throws {Error} - If the request fails.
 */
async function update(datasource) {
  const token = localStorage.getItem('auth_token');
  const url = `${getDatasourcesEndpoint()}/${datasource.id}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  // Convert application object to JSON string
  var body = JSON.stringify(datasource);

  var requestOptions = {
    method: 'PUT',
    headers,
    body,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Retrieves a list of datasources.
 * @returns {Promise<Array>} - A promise that resolves to an array of datasource objects.
 * @throws {Error} - If the request fails.
 */
async function list() {
  const token = localStorage.getItem('auth_token');
  const url = `${getDatasourcesEndpoint()} `;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Retrieves a datasource by its ID.
 * @param {string} datasourceId - The ID of the datasource to retrieve.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved datasource object.
 * @throws {Error} - If the request fails.
 */
async function get(datasourceId) {
  const token = localStorage.getItem('auth_token');
  const url = `${getDatasourcesEndpoint()}/${datasourceId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Removes a datasource by its ID.
 * @param {string} datasourceId - The ID of the datasource to remove.
 * @returns {Promise<Object>} - A promise that resolves to the removed datasource object.
 * @throws {Error} - If the request fails.
 */
async function remove(datasourceId) {
  const token = localStorage.getItem('auth_token');
  const url = `${getDatasourcesEndpoint()}/${datasourceId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'DELETE',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Removes a datasource by its ID.
 * @param {string} datasourceId - The ID of the datasource to remove.
 * @returns {Promise<Object>} - A promise that resolves to the removed datasource object.
 * @throws {Error} - If the request fails.
 */
async function upload(datasourceId, formData) {
  const token = localStorage.getItem('auth_token');
  const url = `${getDatasourcesEndpoint()}/${datasourceId}/upload`;
  const headers = {
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'POST',
    headers,
    body: formData,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Removes a datasource by its ID.
 * @param {string} datasourceId - The ID of the datasource to remove.
 * @returns {Promise<Object>} - A promise that resolves to the removed datasource object.
 * @throws {Error} - If the request fails.
 */
async function removeFile(datasourceId, fileId) {
  const token = localStorage.getItem('auth_token');
  const url = `${getDatasourcesEndpoint()}/${datasourceId}/files/${fileId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'DELETE',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

const fns = {
  list,
  get,
  create,
  update,
  remove,
  upload,
  removeFile,
};

export default fns;
