import AlphaNumericTextField from '../../components/AlphaNumericTextField';
import datasources from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
import ZendeskHelpPane from './components/ZendeskHelpPane';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NewZendeskSupportDataSourcePage = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [datasourceName, setDatasourceName] = useState('');
  const [tenant, setTenant] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cronSchedule, setCronSchedule] = useState('once');
  const [authOption, setAuthOption] = React.useState('0');

  const handleChangeAuthOption = event => {
    setAuthOption(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    setIsSubmitting(true);

    datasources
      .create({
        name: datasourceName,
        type: 'ZENDESK',
        cronSchedule: cronSchedule,
        connectionInfo: {
          authOption,
          tenant,
          username,
          password,
          cronSchedule,
        },
      })
      .then(() => {
        toastSuccess('Data source successfully created');

        window.location.href = '/data-sources';
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to create data source');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Container className='newDSMuiContainer-root'>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
        <Link component={RouterLink} to='/' color='inherit'>
          Home
        </Link>
        <Link component={RouterLink} to='/data-sources' color='inherit'>
          Data Sources
        </Link>
        <Link component={RouterLink} to='/data-sources/new' color='inherit'>
          New Data Source
        </Link>
        <Typography variant='body1' color='text.primary'>
          Zendesk Support
        </Typography>
      </Breadcrumbs>
      <Grid item xs={12}>
        <Typography className='header' alignContent='center'>
          Zendesk Support
        </Typography>
      </Grid>

      <Grid container xs={12}>
        <Grid item xs={12} sm={6} md={6} lg={5} xl={5} mb={4}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
              <Typography>Name</Typography>
              <AlphaNumericTextField
                required
                fullWidth
                name='projectName'
                placeholder='Application name'
                sx={{ mb: 2 }}
                value={datasourceName}
                onChange={event => setDatasourceName(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>Tenant</Typography>
              <TextField
                fullWidth
                name='tenant'
                placeholder='tenant (e.g. https://<tenant>.zendesk.com)'
                sx={{ mb: 2 }}
                value={tenant}
                onChange={event => setTenant(event.target.value)}
              />

              <FormControl sx={{ mt: 0.5, mb: 2 }}>
                <FormLabel id='zendesk-auth-option-controlled-radio-buttons-group'>
                  Select Authentication Method
                </FormLabel>
                <RadioGroup
                  aria-labelledby='zendesk-auth-option-controlled-radio-buttons-group'
                  name='zendesk-auth-option-controlled-radio-buttons-group'
                  value={authOption}
                  onChange={handleChangeAuthOption}
                >
                  <FormControlLabel
                    value='0'
                    control={<Radio />}
                    label='Username / Password Authentication'
                  />
                  <FormControlLabel
                    value='1'
                    control={<Radio />}
                    label='API Token Authentication'
                  />
                </RadioGroup>
              </FormControl>

              {authOption === '0' ? (
                <>
                  <Typography>Username</Typography>
                  <TextField
                    fullWidth
                    name='username'
                    placeholder='Username'
                    sx={{ mb: 2 }}
                    value={username}
                    onChange={event => setUsername(event.target.value)}
                  />

                  <Typography>Password</Typography>
                  <TextField
                    fullWidth
                    name='password'
                    placeholder='Password'
                    sx={{ mb: 2 }}
                    value={password}
                    type='password'
                    onChange={event => setPassword(event.target.value)}
                  />
                </>
              ) : (
                <>
                  <Typography>Email: {'{your_email}/token}'}</Typography>
                  <TextField
                    fullWidth
                    name='username'
                    placeholder='your_email}/token'
                    sx={{ mb: 2 }}
                    value={username}
                    onChange={event => setUsername(event.target.value)}
                  />

                  <Typography>Password: Your API token</Typography>
                  <TextField
                    fullWidth
                    name='password'
                    placeholder='Your API token'
                    sx={{ mb: 2 }}
                    value={password}
                    type='password'
                    onChange={event => setPassword(event.target.value)}
                  />
                </>
              )}

              <Typography>Cron schedule</Typography>
              <Select
                labelId='cron-schedule-label'
                name='cronSchedule'
                value={cronSchedule}
                onChange={event => setCronSchedule(event.target.value)}
                disabled={isSubmitting}
              >
                <MenuItem value='once'>Once</MenuItem>
                <MenuItem value='daily'>Daily</MenuItem>
                <MenuItem value='weekly'>Weekly</MenuItem>
                <MenuItem value='monthly'>Monthly</MenuItem>
              </Select>
              <FormHelperText sx={{ mb: 2 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;The cron schedule determines when the
                scraping task will run.
              </FormHelperText>

              <Button
                type='submit'
                className='createButton'
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Create'}
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} xl={3}>
          <ZendeskHelpPane></ZendeskHelpPane>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewZendeskSupportDataSourcePage;
