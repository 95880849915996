import AlphaNumericTextField from '../../components/AlphaNumericTextField';
import applicationService from '../../service/applications';
import datasourceService from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
import { getUrlParam } from '../../utils/utils';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NewHistoricalSupportDataApplicationPage = () => {
  const appType = getUrlParam('type', 'ZENDESK');
  const [sources, setSources] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [applicationName, setApplicationName] = useState('');
  const [analytics, setAnalytics] = useState(true);
  const [selectedSources, setSelectedSources] = useState([]);

  // Fetch datasourceService
  useEffect(() => {
    datasourceService
      .list()
      .then(data => {
        setSources(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch data sources');
      });
  }, [setSources]);

  const handleSourceChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedSources(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSubmit = event => {
    event.preventDefault();

    setIsSubmitting(true);

    applicationService
      .create({
        name: applicationName,
        type: 'HISTORICAL_SUPPORT_DATA',
        subType: `${selectedSources[0].type}_ticketing_insights`,
        analytics,
        datasourceNames: selectedSources,
        properties: {},
      })
      .then(({ applicationId }) => {
        toastSuccess('Application successfully created');
        window.location.href = `/applications/historical_support_data/${applicationId}`;
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to create application');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Container className='newDSMuiContainer-root'>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
        <Link component={RouterLink} to='/' color='inherit'>
          Home
        </Link>
        <Link component={RouterLink} to='/applications' color='inherit'>
          Applications
        </Link>
        <Link component={RouterLink} to='/applications/new' color='inherit'>
          New Application
        </Link>
        <Typography variant='body1' color='text.primary'>
          Historical Support Data
        </Typography>
      </Breadcrumbs>
      <Grid item xs={12}>
        <Typography className='header' alignContent='center'>
          Historical Support Data
        </Typography>
      </Grid>

      <Grid container xs={12}>
        <Grid item xs={12} md={6} lg={5}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
              <Typography>Name*</Typography>
              <AlphaNumericTextField
                required
                fullWidth
                name='projectName'
                placeholder='Application name'
                sx={{ mb: 2 }}
                value={applicationName}
                onChange={event => setApplicationName(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>Data sources*</Typography>
              <Select
                id='source-multiple-chip'
                placeholder='Select'
                label='Source'
                displayEmpty
                required
                value={selectedSources}
                onChange={handleSourceChange}
                input={<OutlinedInput />}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return '';
                  }

                  return selected.map(value => (
                    <Chip key={value} label={value} sx={{ mr: 1 }} />
                  ));
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value=''>
                  <em>data sources</em>
                </MenuItem>
                {sources.map(({ name, type, ready }) => {
                  return ready &&
                    ['REAMAZE', 'ZENDESK'].includes(type) &&
                    type === appType ? (
                    <MenuItem key={name} value={name}>
                      <span>{name}</span>
                    </MenuItem>
                  ) : (
                    <MenuItem key={name} value={name} disabled>
                      <span>{name}</span>
                    </MenuItem>
                  );
                })}
              </Select>
              <FormGroup>
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                      checked={analytics}
                      onChange={() => setAnalytics(!analytics)}
                    />
                  }
                  label='Enable Analytics'
                />
              </FormGroup>
              <br />

              <Button
                type='submit'
                className='createButton'
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Create'}
              </Button>
              <br />
              <br />
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewHistoricalSupportDataApplicationPage;
