import AlphaNumericTextField from '../../components/AlphaNumericTextField';
import { DATASOURCE_TYPES } from '../../constants';
import applicationService from '../../service/applications';
import datasourceService from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NewReamazeModuleApplicationPage = () => {
  const [sources, setSources] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [applicationName, setApplicationName] = useState('');
  const [systemPrompt, setSystemPrompt] = useState('');
  const [autoReply, setAutoReply] = useState(false);
  const [selectedSources, setSelectedSources] = useState([]);

  // Fetch datasourceService
  useEffect(() => {
    datasourceService
      .list()
      .then(data => {
        setSources(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch data sources');
      });
  }, [setSources]);

  const handleSourceChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedSources(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSubmit = event => {
    event.preventDefault();

    setIsSubmitting(true);

    applicationService
      .create({
        name: applicationName,
        type: 'REAMAZE_MODULE',
        datasourceNames: selectedSources,
        systemPrompt,
        autoReply,
        properties: {},
      })
      .then(({ applicationId }) => {
        toastSuccess('Application successfully created');

        window.location.href = `/applications/reamaze_module/${applicationId}`;
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to create application');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Container className='newDSMuiContainer-root'>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
        <Link component={RouterLink} to='/' color='inherit'>
          Home
        </Link>
        <Link component={RouterLink} to='/applications' color='inherit'>
          Applications
        </Link>
        <Link component={RouterLink} to='/applications/new' color='inherit'>
          New Application
        </Link>
        <Typography variant='body1' color='text.primary'>
          Re:amaze Agent CoPilot
        </Typography>
      </Breadcrumbs>
      <Grid item xs={12}>
        <Typography className='header' alignContent='center'>
          Re:amaze Agent CoPilot
        </Typography>
      </Grid>

      <Grid container xs={12}>
        <Grid item xs={12} md={6} lg={5}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
              <Typography>Name</Typography>
              <AlphaNumericTextField
                required
                fullWidth
                name='projectName'
                placeholder='Application name'
                sx={{ mb: 2 }}
                value={applicationName}
                onChange={event => setApplicationName(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>Custom system prompt</Typography>
              <TextField
                label='Custom system prompt'
                variant='outlined'
                multiline
                value={systemPrompt}
                onChange={event => setSystemPrompt(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                minRows={10}
                required={false}
                size='small'
              />

              <Typography>Data sources</Typography>
              <Select
                id='source-multiple-chip'
                placeholder='Select'
                label='Source'
                displayEmpty
                required
                multiple
                value={selectedSources}
                onChange={handleSourceChange}
                input={<OutlinedInput />}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return '';
                  }

                  return selected.map(value => (
                    <Chip key={value} label={value} sx={{ mr: 1 }} />
                  ));
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value=''>
                  <em>data sources</em>
                </MenuItem>
                {sources.map(({ name, ready, type }) => {
                  return ready &&
                    [
                      DATASOURCE_TYPES.WEB,
                      DATASOURCE_TYPES.REAMAZE,
                      DATASOURCE_TYPES.FILES,
                    ].includes(type) ? (
                    <MenuItem key={name} value={name}>
                      <span>{name}</span>
                    </MenuItem>
                  ) : (
                    <MenuItem key={name} value={name} disabled>
                      <span>{name}</span>
                    </MenuItem>
                  );
                })}
              </Select>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                      checked={autoReply}
                      onChange={() => setAutoReply(!autoReply)}
                    />
                  }
                  label='Enable autoreply'
                />
              </FormGroup>
              <br />

              <Button
                type='submit'
                className='createButton'
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Create'}
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewReamazeModuleApplicationPage;
