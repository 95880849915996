import { getUsageEndpoint } from '../utils/utils';
import { handleCompletion } from './common';

/**
 * get the account usage
 * @returns {Promise<Object>} - A promise that resolves.
 * @throws {Error} - If the request fails.
 */
async function get() {
  const token = localStorage.getItem('auth_token');
  const url = `${getUsageEndpoint()}/`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

const fns = {
  get,
};

export default fns;
