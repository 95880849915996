import { ERROR_STREAM_FIX, ERROR_TRY_AGAIN } from '../constants';
import { getContentEndpoint } from '../utils/utils';
import { handleStreamCompletion } from './common';

export async function fetchAIAnswer({
  userQuestion,
  startDate,
  endDate,
  application,
  conversationId,
  onData,
}) {
  const token = localStorage.getItem('auth_token');
  const url = `${getContentEndpoint()}/insights/${application.id}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  const data = {
    question: userQuestion,
    startDate: startDate,
    endDate: endDate,
    conversationId: conversationId,
  };

  const controller = new AbortController();
  const signal = controller.signal;

  const responseStream = handleStreamCompletion(
    fetch(url, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(data),
      signal: signal,
    })
  )
    .then(async response => {
      if (!response.ok) {
        onData(ERROR_STREAM_FIX);
        // throw new Error('Network response was not ok');
        return;
      }

      const reader = response.body.getReader();

      return new Promise((resolve, reject) => {
        function read() {
          reader
            .read()
            .then(({ done, value }) => {
              if (done) {
                resolve();
                return;
              }

              const answer = new TextDecoder('utf-8').decode(value);
              onData(answer);
              read();
            })
            .catch(error => {
              reject(error);
            });
        }

        read();
      });
    })
    .catch(error => {
      console.error('Error:', error);
      onData(ERROR_TRY_AGAIN);
    });

  return Promise.resolve({ responseStream, controller });
}
