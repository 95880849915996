import { getFeedbackEndpoint } from '../utils/utils';
import { handleCompletion } from './common';

async function submit(apiKey, conversationId, score, answerIndex) {
  const url = `${getFeedbackEndpoint()}/conversation/${conversationId}`;
  const headers = {
    'Content-Type': 'application/json',
    'X-KWAZII-API-KEY': apiKey,
  };

  var requestOptions = {
    method: 'PUT',
    headers,
    body: JSON.stringify({ score, answerIndex }),
  };

  return handleCompletion(fetch(url, requestOptions));
}

async function submit2(conversationId, score, answerIndex, section) {
  const token = localStorage.getItem('auth_token');
  const url = `${getFeedbackEndpoint()}/conversation/${conversationId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  var requestOptions = {
    method: 'PUT',
    headers,
    body: JSON.stringify({ score, section, answerIndex }),
  };

  return handleCompletion(fetch(url, requestOptions));
}

const fns = {
  submit,
  submit2,
};

export default fns;
