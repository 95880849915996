import feedback from '../service/feedback';
import MarkdownComponent from './MarkdownComponent';
import './SearchResultsBox.scss';
import TypingIndicator from './TypingIndicator';
import { CopyAll } from '@mui/icons-material';
// Import the SCSS file
import {
  CleaningServicesOutlined,
  ThumbDownOutlined,
  ThumbUpOutlined,
} from '@mui/icons-material';
import { Box, Button, Typography, useMediaQuery } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';

const SearchResultsBox = ({
  apiKey,
  searchValue,
  searchEnabled,
  conversationId,
  results,
  onSuggestionClick,
  onResetClick,
  onChange,
  onKeyPress,
}) => {
  const [copySuccess, setCopySuccess] = useState(false);
  const [thankYouShown, setThankYouShown] = useState({});
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('sm'));

  const components = {
    // a: (args) => {
    //     console.log(args);
    //     return <a href={args.href}>[{index}]</a>
    // },
  };
  const maxHeight = useRef({});

  const handleThumbsUp = async event => {
    const answerIndex = event.currentTarget.value;
    await feedback.submit(apiKey, conversationId, 1, answerIndex);
    setThankYouShown({
      ...thankYouShown,
      [answerIndex]: true,
    });
  };

  const handleThumbsDown = async event => {
    const answerIndex = event.currentTarget.value;
    await feedback.submit(apiKey, conversationId, 0, answerIndex);
    setThankYouShown({
      ...thankYouShown,
      [answerIndex]: true,
    });
  };

  useEffect(() => {
    setThankYouShown({});
    if (results.length === 0) {
      return;
    }

    const index = results.length - 1;
    maxHeight.current[index] = maxHeight.current[index] || 0;

    const searchResultsRefElem = document.querySelector(`#answer-box-${index}`);

    if (searchResultsRefElem.clientHeight > maxHeight.current[index]) {
      // console.log(searchResultsRefElem.clientHeight);
      // UPDATE 100 to appropiate value if ANSWER BOX HEIGHT IS CHANGED
      maxHeight.current[index] = searchResultsRefElem.clientHeight - 40; // Update max height
      searchResultsRefElem.style.minHeight = maxHeight.current[index] + 'px'; // Prevent shrinking
    }
  }, [results]);

  return (
    <Box flexGrow={1} className='search-results-box-base search-results-box'>
      {results?.length > 0 && (
        <div>
          {results.map((result, index) => (
            <div key={index} className='result-item-base result-item'>
              <div className='question-box-base question-box'>
                {result.question}
              </div>
              <div
                id={`answer-box-${index}`}
                className='answer-box-base answer-box'
              >
                {result.answer ? (
                  <>
                    <MarkdownComponent
                      index={index}
                      content={result.answer}
                      components={components}
                    />
                    <Box display='flex' justifyContent='flex-end'>
                      <CopyToClipboard
                        text={result.answer}
                        onCopy={() => {
                          setCopySuccess(true);
                          console.log(copySuccess);
                        }}
                      >
                        <Button
                          className='feedback-btn'
                          variant='text'
                          size='small'
                        >
                          <CopyAll />
                        </Button>
                      </CopyToClipboard>
                      {thankYouShown[index] ? (
                        <Typography
                          variant='h5'
                          style={{ margin: '10px', textAlign: 'center' }}
                        >
                          Thank you for your feedback!
                        </Typography>
                      ) : (
                        <>
                          <Button
                            value={index}
                            className='feedback-btn'
                            variant='text'
                            size='small'
                            onClick={handleThumbsUp}
                          >
                            <ThumbUpOutlined />
                          </Button>

                          <Button
                            value={index}
                            className='feedback-btn'
                            variant='text'
                            size='small'
                            onClick={handleThumbsDown}
                          >
                            <ThumbDownOutlined />
                          </Button>
                        </>
                      )}
                    </Box>
                  </>
                ) : (
                  <TypingIndicator />
                )}
              </div>
              <div className='suggestions-box-base suggestions-box '>
                {result.suggestions?.map(suggestion => (
                  <Button
                    key={suggestion}
                    className='suggest-button-base suggest-button'
                    id={suggestion}
                    value={index}
                    onClick={onSuggestionClick}
                  >
                    {suggestion}
                  </Button>
                ))}
              </div>
              {index === results.length - 1 ? (
                <div className='container-input-box-inline-base'>
                  <Button
                    className='reset-button-inline-base reset-button-inline'
                    onClick={onResetClick}
                    variant='contained'
                    startIcon={<CleaningServicesOutlined />}
                    style={{
                      alignSelf: 'stretch',
                      fontSize: '0.8rem',
                      marginRight: '10px',
                    }}
                  >
                    {isMobile ? '' : 'New Topic'}
                  </Button>
                  <input
                    className='input-box-inline-base input-box-inline'
                    value={searchValue}
                    onChange={onChange}
                    onKeyPress={onKeyPress}
                    style={{ flexGrow: 1 }}
                    required={true}
                    disabled={!searchEnabled}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          ))}
        </div>
      )}
    </Box>
  );
};

export default SearchResultsBox;
