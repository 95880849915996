import AddButton from '../AddButton';
import { NewWorkflowContext } from '../Context';
import AddFlatButton from '../RemoveButton';
import { useContext } from 'react';
import { BaseEdge, EdgeLabelRenderer, getBezierPath } from 'reactflow';

function EdgeComponent(edge) {
  const {
    id,
    sourceX,
    sourceY,
    targetX,
    targetY,
    sourcePosition,
    targetPosition,
    style = {},
    markerEnd,
  } = edge;

  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const { selectedEdge, onNodeAddSelector } = useContext(NewWorkflowContext);

  return (
    <>
      <BaseEdge path={edgePath} markerEnd={markerEnd} style={style} />
      <EdgeLabelRenderer>
        <div
          style={{
            position: 'absolute',
            transform: `translate(-50%, -50%) translate(${labelX}px,${labelY}px)`,
            fontSize: 12,
            // everything inside EdgeLabelRenderer has no pointer events by default
            // if you have an interactive element, set pointer-events: all
            pointerEvents: 'all',
          }}
          className='nodrag nopan'
        >
          {selectedEdge?.id === id ? (
            <AddFlatButton></AddFlatButton>
          ) : (
            <AddButton onClick={() => onNodeAddSelector(edge)}>+</AddButton>
          )}
        </div>
      </EdgeLabelRenderer>
    </>
  );
}

export default EdgeComponent;
