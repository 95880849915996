import { getServiceMode } from '../../utils/utils';
import { Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import SwaggerUI from 'swagger-ui-react';
import 'swagger-ui-react/swagger-ui.css';

const RestAPIPage = () => {
  window.location.href = 'https://api-docs.kwazii.app/';
  return null;
};

// eslint-disable-next-line
const Deprecated_RestAPIPage = () => {
  const serviceMode = getServiceMode();

  return (
    <Container className='newMuiContainer-root'>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
            <Link component={RouterLink} to='/' color='inherit'>
              Home
            </Link>
            <Typography variant='body1' color='text.primary'>
              Rest API
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Typography className='header' alignContent='center'>
            Rest API
          </Typography>
        </Grid>

        {serviceMode === 'local' ? (
          <SwaggerUI url='/swagger_local.yaml' />
        ) : (
          <></>
        )}
        {serviceMode === 'staging' ? (
          <SwaggerUI url='/swagger_stag.yaml' />
        ) : (
          <></>
        )}
        {serviceMode === 'prod' ? (
          <SwaggerUI url='/swagger_prod.yaml' />
        ) : (
          <></>
        )}
      </Grid>
    </Container>
  );
};

export default RestAPIPage;
