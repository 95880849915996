import PromptDialog from '../../components/PromptDialog';
import apiKeysService from '../../service/apiKeys';
import { toastError, toastSuccess } from '../../utils/toastUI';
import { maskSensitiveString } from '../../utils/utils';
import ApiKeysEmptyState from './ApiKeysEmptyState';
import { Add, ContentCopyOutlined, DeleteOutline } from '@mui/icons-material';
import {
  Breadcrumbs,
  Button,
  Container,
  Grid,
  IconButton,
  Link,
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import clipboardCopy from 'clipboard-copy';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
// Analytics, ChatBubble, Cloud, Language
import { Link as RouterLink } from 'react-router-dom';

const ApiKeysPage = () => {
  const [keys, setKeys] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [loading, setLoading] = useState(true);

  const handleOpen = () => {
    setIsOpen(true);
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const handlePromptConfirm = name => {
    createAPIKey(name);
  };

  useEffect(() => {
    apiKeysService
      .list()
      .then(data => {
        setKeys(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch API keys');
      })
      .finally(() => setLoading(false));
  }, [setKeys]);

  const createAPIKey = name => {
    apiKeysService
      .create({ name })
      .then(() => {
        toastSuccess('API key successfully created');

        setTimeout(() => {
          window.location.href = '/api-keys';
        }, 500);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to create API key');
      });
  };

  const handleConfirm = id => {
    apiKeysService
      .remove(id)
      .then(() => {
        toastSuccess('API key successfully deleted');
        setTimeout(() => {
          window.location.href = '/api-keys';
        }, 500);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to delete API key');
      });
  };

  const handleDeleteApiKey = id => {
    const result = window.confirm('Are you sure you want to proceed?');
    if (result) {
      handleConfirm(id);
    } else {
      // User clicked "Cancel"
      console.log('Cancelled.');
    }
  };

  const handleCopyApiKey = apiKey => {
    clipboardCopy(apiKey)
      .then(() => {
        toastSuccess('API key copied to clipboard');
      })
      .catch(err => {
        console.log('Failed to copy API key to clipboard:', err);
        toastError('Failed to copy API key to clipboard');
      });
  };

  return (
    <>
      <PromptDialog
        title='Give your key a name'
        open={isOpen}
        onClose={handleClose}
        onConfirm={handlePromptConfirm}
      />
      <Container maxWidth='100%'>
        <Grid container spacing={3} mb={5}>
          <Grid item xs={12}>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link component={RouterLink} to='/home' color='inherit'>
                Home
              </Link>
              <Typography variant='h7' component='div'>
                Api Keys
              </Typography>
            </Breadcrumbs>
          </Grid>

          <Grid item xs={6}>
            <Typography className='header' alignContent='center'>
              Api Keys
            </Typography>
          </Grid>

          <Grid item xs={6} textAlign='right'>
            <Button
              className='createButton'
              onClick={handleOpen}
              startIcon={<Add />}
              variant='contained'
            >
              Add
            </Button>
          </Grid>

          {keys.length ? (
            <Grid item xs={12}>
              <Typography
                className='subheader-small'
                alignContent='center'
                sx={{ color: '#777' }}
              >
                An API key is essential for programmatic access to the Kwaziii
                API. It acts as a unique identifier, granting authorized
                applications secure access to API functionalities and data.
                While using the Kwaziii playground or applications doesn't
                require an API key, external programs need it to interact with
                the API securely. Each application within Kwaziii generates its
                own key, enabling seamless communication between platform
                components.
              </Typography>
            </Grid>
          ) : (
            <></>
          )}

          {loading ? (
            <Grid item xs={12}>
              <Box>
                <Skeleton />
                <Skeleton animation='wave' height={100} />
                <Skeleton animation='wave' height={100} />
                <Skeleton animation='wave' height={100} />
              </Box>
            </Grid>
          ) : keys.length ? (
            <Grid item xs={12}>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Key</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Created</TableCell>
                      <TableCell>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {keys.map(apiKey => (
                      <TableRow key={apiKey.id}>
                        <TableCell size='small'>
                          <Typography variant='h6' component='h2'>
                            {maskSensitiveString(apiKey.apiKey)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant='h6' component='h2'>
                            {apiKey.name || '-'}
                          </Typography>
                        </TableCell>
                        <TableCell size='small'>
                          <Typography variant='h6' component='h2'>
                            {moment(apiKey.created).format('LL')}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <IconButton
                            sx={{ fontSize: '17px' }}
                            onClick={() => handleCopyApiKey(apiKey.apiKey)}
                            color='secondary'
                          >
                            <ContentCopyOutlined />
                          </IconButton>
                          <IconButton
                            sx={{ fontSize: '20px' }}
                            onClick={() => handleDeleteApiKey(apiKey.id)}
                            color='secondary'
                          >
                            <DeleteOutline />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          ) : (
            <ApiKeysEmptyState />
          )}
        </Grid>
      </Container>
    </>
  );
};

export default ApiKeysPage;
