import { Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const BillingPage = () => {
  return (
    <Container maxWidth='100%'>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Typography variant='h7' component='div'>
              Billing
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Typography className='header' alignContent='center'>
            Billing
          </Typography>
        </Grid>

        <div>
          <Typography className='subsubheader' mb={2} mt={2} ml={2}>
            Enjoy Six Months of Subscription on Us - Absolutely Free!
          </Typography>
        </div>
      </Grid>
    </Container>
  );
};

export default BillingPage;
