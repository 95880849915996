import { GLOBAL_LEFT_NAV_OPEN_STATE } from '../constants';
import ComingSoonPage from '../pages/ComingSoonPage';
import LoginPage from '../pages/LoginPage';
import ProceedPage from '../pages/ProceedPage';
import WaitlistPage from '../pages/WaitlistPage';
import AccountUsageList from '../pages/account/AccountUsageList';
import AskAIPage from '../pages/ai/AskAIPage';
import ApiKeysPage from '../pages/api-keys/ApiKeysPage';
import ApplicationPage from '../pages/applications/ApplicationPage';
import EditCustomKBSearchApplicationPage from '../pages/applications/EditCustomKBSearchApplicationPage';
import EditHistoricalSupportDataApplicationPage from '../pages/applications/EditHistoricalSupportDataApplicationPage';
import EditReamazeModuleApplicationPage from '../pages/applications/EditReamazeModuleApplicationPage';
import EditReamazeSearchApplicationPage from '../pages/applications/EditReamazeSearchApplicationPage';
import EditZendeskSearchApplicationPage from '../pages/applications/EditZendeskSearchApplicationPage';
import NewApplicationPage from '../pages/applications/NewApplicationPage';
import NewCustomKBSearchApplicationPage from '../pages/applications/NewCustomKBSearchApplicationPage';
import NewHistoricalSupportDataApplicationPage from '../pages/applications/NewHistoricalSupportDataApplicationPage';
import NewReamazeModuleApplicationPage from '../pages/applications/NewReamazeModuleApplicationPage';
import NewReamazeSearchApplicationPage from '../pages/applications/NewReamazeSearchApplicationPage';
import NewZendeskSearchApplicationPage from '../pages/applications/NewZendeskSearchApplicationPage';
import BillingPage from '../pages/billing/BillingPage';
import DataSourcesPage from '../pages/datasources/DataSourcesPage';
import EditFilesDataSourcePage from '../pages/datasources/EditFilesDataSourcePage';
import EditReamazeSupportDataSourcePage from '../pages/datasources/EditReamazeSupportDataSourcePage';
import EditS3DataSourcePage from '../pages/datasources/EditS3DataSourcePage';
import EditWebDataSourcePage from '../pages/datasources/EditWebDataSourcePage';
import EditZendeskSupportDataSourcePage from '../pages/datasources/EditZendeskSupportDataSourcePage';
// Pages
import NewDataSourcePage from '../pages/datasources/NewDataSourcePage';
import NewFilesDataSourcePage from '../pages/datasources/NewFilesDataSourcePage ';
import NewReamazeSupportDataSourcePage from '../pages/datasources/NewReamazeSupportDataSourcePage';
import NewS3DataSourcePage from '../pages/datasources/NewS3DataSourcePage';
import NewWebDataSourcePage from '../pages/datasources/NewWebDataSourcePage';
import NewZendeskSupportDataSourcePage from '../pages/datasources/NewZendeskSupportDataSourcePage';
import HomePage from '../pages/home/HomePage';
import InsightsPage from '../pages/insights/InsightsPage';
import ViewInsightsPage from '../pages/insights/ViewInsightsPage';
import IntegrationPage from '../pages/integration/IntegrationPage';
import NewIntegrationPage from '../pages/integration/NewIntegrationPage';
import PlaygroundPage from '../pages/playground/PlaygroundPage';
import PlaygroundProjectPage from '../pages/playground/PlaygroundProjectPage';
import RestAPIPage from '../pages/rest/RestAPIPage';
import DesignWorkflowPage from '../pages/workflow/DesignWorkflowPage';
import NewWorkflowPage from '../pages/workflow/NewWorkflowPage';
import WorkflowPage from '../pages/workflow/WorkflowPage';
import { useLocalStorage } from '../utils/storage';
import React from 'react';
import { Route, Routes } from 'react-router-dom';

export const InitialArea = () => {
  return (
    <div className='App'>
      <Routes>
        <Route path='/' element={<div>Loading...</div>} />
      </Routes>
    </div>
  );
};

export const LoginArea = () => {
  return (
    <div className='App'>
      <Routes>
        <Route path='/proceed' element={<ProceedPage />} />
        <Route path='/waitlist' element={<WaitlistPage />} />
        <Route path='*' element={<LoginPage />} />
      </Routes>
    </div>
  );
};

export const MainArea = () => {
  const [leftNavOpen, setLeftNavOpen] = useLocalStorage(
    GLOBAL_LEFT_NAV_OPEN_STATE,
    false
  );

  window.addEventListener('left-nav-open', () => setLeftNavOpen(true));
  window.addEventListener('left-nav-close', () => setLeftNavOpen(false));

  return (
    <Routes>
      <Route exact path='/' element={<HomePage />} />
      <Route path='/proceed' element={<ProceedPage />} />
      <Route path='/home' element={<HomePage />} />
      <Route path='/playground' element={<PlaygroundPage />} />
      <Route
        path='/playground/:activityId'
        element={<PlaygroundProjectPage />}
      />

      <Route path='/data-sources' element={<DataSourcesPage />} />
      <Route path='/data-sources/new' element={<NewDataSourcePage />} />

      <Route path='/data-sources/new/web' element={<NewWebDataSourcePage />} />
      <Route
        path='/data-sources/web/:datasourceId'
        element={<EditWebDataSourcePage />}
      />

      <Route path='/data-sources/new/s3' element={<NewS3DataSourcePage />} />
      <Route
        path='/data-sources/s3/:datasourceId'
        element={<EditS3DataSourcePage />}
      />

      <Route
        path='/data-sources/new/reamaze'
        element={<NewReamazeSupportDataSourcePage />}
      />
      <Route
        path='/data-sources/reamaze/:datasourceId'
        element={<EditReamazeSupportDataSourcePage />}
      />

      <Route
        path='/data-sources/new/zendesk'
        element={<NewZendeskSupportDataSourcePage />}
      />
      <Route
        path='/data-sources/zendesk/:datasourceId'
        element={<EditZendeskSupportDataSourcePage />}
      />

      <Route
        path='/data-sources/new/files'
        element={<NewFilesDataSourcePage />}
      />
      <Route
        path='/data-sources/files/:datasourceId'
        element={<EditFilesDataSourcePage />}
      />

      <Route path='/applications' element={<ApplicationPage />} />
      <Route path='/applications/new' element={<NewApplicationPage />} />

      <Route
        path='/applications/new/zendesk_search'
        element={<NewZendeskSearchApplicationPage />}
      />

      <Route
        path='/applications/new/reamaze_search'
        element={<NewReamazeSearchApplicationPage />}
      />
      <Route
        path='/applications/new/reamaze_module'
        element={<NewReamazeModuleApplicationPage />}
      />
      <Route
        path='/applications/new/historical_support_data'
        element={<NewHistoricalSupportDataApplicationPage />}
      />

      <Route
        path='/applications/zendesk_search/:applicationId'
        element={<EditZendeskSearchApplicationPage leftNavOpen={leftNavOpen} />}
      />
      <Route
        path='/applications/reamaze_search/:applicationId'
        element={<EditReamazeSearchApplicationPage leftNavOpen={leftNavOpen} />}
      />
      <Route
        path='/applications/reamaze_module/:applicationId'
        element={<EditReamazeModuleApplicationPage leftNavOpen={leftNavOpen} />}
      />
      <Route
        path='/applications/historical_support_data/:applicationId'
        element={
          <EditHistoricalSupportDataApplicationPage leftNavOpen={leftNavOpen} />
        }
      />

      <Route
        path='/applications/new/custom_search'
        element={<NewCustomKBSearchApplicationPage />}
      />
      <Route
        path='/applications/custom_search/:applicationId'
        element={<EditCustomKBSearchApplicationPage />}
      />

      <Route path='/insights' element={<InsightsPage />} />
      <Route path='/insights/new' element={<ComingSoonPage />} />
      <Route path='/insights/:applicationId' element={<ViewInsightsPage />} />

      <Route path='/ask-ai' element={<AskAIPage />} />

      <Route path='/integration' element={<IntegrationPage />} />
      <Route path='/integration/new' element={<NewIntegrationPage />} />

      <Route path='/workflow' element={<WorkflowPage />} />
      <Route path='/workflow/new/' element={<NewWorkflowPage />} />
      <Route path='/workflow/design/' element={<DesignWorkflowPage />} />
      <Route
        path='/workflow/design/:workflowId'
        element={<DesignWorkflowPage />}
      />

      <Route path='/rest-api' element={<RestAPIPage />} />
      <Route path='/api-keys' element={<ApiKeysPage />} />

      <Route path='/account-usage' element={<AccountUsageList />} />
      <Route path='/billing' element={<BillingPage />} />

      <Route path='/my-prompts' element={<ComingSoonPage />} />
      <Route path='/prompt-library' element={<ComingSoonPage />} />
      <Route path='/coming-soon' element={<ComingSoonPage />} />
      <Route exact path='*' element={<HomePage />} />
    </Routes>
  );
};
