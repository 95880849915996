import { ACCOUNT_TYPE } from '../constants';
import { getUrlParam } from '../utils/utils';
import {
  AccountTreeOutlined,
  AppsOutlined,
  ChatOutlined,
  CodeOutlined,
  DataUsageOutlined,
  HomeOutlined,
  InsightsOutlined,
  Key,
  LinkOutlined,
  LogoutOutlined,
  PaymentOutlined,
  PlaylistPlayOutlined,
  SourceOutlined, // TipsAndUpdatesOutlined,
  VerifiedRounded,
} from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
  Typography,
  styled,
  tooltipClasses,
  useMediaQuery,
} from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const Navigation = ({ accountType, userEmail, open }) => {
  const preview = getUrlParam('preview', false);
  const isLg = useMediaQuery(theme => theme.breakpoints.up('xl'));
  console.log(`isLg:  ${isLg}`);

  const HtmlTooltip = open
    ? React.Fragment
    : styled(({ className, ...props }) => (
        <Tooltip
          arrow
          placement='right'
          {...props}
          classes={{ popper: className }}
        />
      ))(({ theme }) => ({
        [`& .${tooltipClasses.tooltip}`]: {
          backgroundColor: '#555',
          color: 'white',
          fontSize: '0.8rem',
          boxShadow: '0 0 10px 1px #999',
        },
      }));

  const UpgradeLink = () => (
    <div>
      To upgrade contact us at&nbsp;
      <a style={{ color: 'white' }} href='support@kwazii.app'>
        support@kwazii.app
      </a>
    </div>
  );

  const handleUpgradeClick = () => {
    toast.info(UpgradeLink, {
      position: 'top-center',
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: 'dark',
    });
  };

  const handleLogout = () => {
    localStorage.setItem('auth_token', null);
    window.location.reload();
  };

  return (
    <Box style={{ display: 'flex', flexFlow: 'column', height: '100%' }}>
      <Link
        component={RouterLink}
        to='/home'
        color='inherit'
        sx={{ textDecoration: 'none' }}
      >
        <Box
          className={`logoContainer ${
            open ? 'left-padding-10' : ''
          } top-bottom-padding-5`}
        >
          {open ? (
            <>
              <a href='/'>
                <img class='frame' alt='Kwazii' src='/logo_1.png' />
              </a>
            </>
          ) : (
            <>
              <a href='/'>
                <img
                  class='frame'
                  style={{ marginLeft: '9px' }}
                  alt='Kwazii'
                  src='/logo_1.png'
                />
              </a>
            </>
          )}
        </Box>
      </Link>

      <List>
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Home</Typography>
              <em>
                {
                  'Snapshot of your connections, applications, workflows and more'
                }
              </em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/home'
            className={`${`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`} ${isLg ? 'taller' : ''}`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <HomeOutlined />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      Home
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>

        <Box sx={{ p: 0.5 }} />
        {open ? (
          <Divider
            textAlign='left'
            light={true}
            sx={{ borderColor: 'rgba(0,0,0,0.07)' }}
          >
            <Typography style={{ fontSize: '0.75rem' }}>Connect</Typography>
          </Divider>
        ) : (
          <Divider
            textAlign='left'
            light={true}
            sx={{ borderColor: 'rgba(0,0,0,0.07)' }}
          ></Divider>
        )}
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Data Sources</Typography>
              <em>{'View existing and create new data connections'}</em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/data-sources'
            className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <SourceOutlined />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      Data Sources
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Playground</Typography>
              <em>
                {'Try out Generative Search, KB article generation and more'}
              </em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/playground'
            className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <PlaylistPlayOutlined />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      Playground
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Applications</Typography>
              <em>{'View existing and create new applications'}</em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/applications'
            className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <AppsOutlined />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      Applications
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>
        <Box sx={{ p: 0.5 }} />
        {open ? (
          <Divider
            textAlign='left'
            light={true}
            sx={{ borderColor: 'rgba(0,0,0,0.07)' }}
          >
            <Typography style={{ fontSize: '0.75rem' }}>Analyze</Typography>
          </Divider>
        ) : (
          <Divider
            textAlign='left'
            light={true}
            sx={{ borderColor: 'rgba(0,0,0,0.07)' }}
          ></Divider>
        )}
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Analytics</Typography>
              <em>{'Search and Ticketing data analytics'}</em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/insights'
            className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <InsightsOutlined />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      Analytics
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Ask AI</Typography>
              <em>{'Ask AI for Insights and more'}</em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/ask-ai'
            className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <ChatOutlined />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      Ask AI ⚡
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>
        <Box sx={{ p: 0.5 }} />
        {open ? (
          <Divider
            textAlign='left'
            light={true}
            sx={{ borderColor: 'rgba(0,0,0,0.07)' }}
          >
            <Typography style={{ fontSize: '0.75rem' }}>
              Automate&nbsp;&nbsp;
              {/* <Typography style={{ display: "inline-block", transform: "rotate(-3deg) translateY(0px)", fontSize: '0.6rem', border: "solid 0.11rem #666", color: "#222", padding: "1px 5px", borderRadius: "7px" }}>
                coming soon
              </Typography> */}
            </Typography>
          </Divider>
        ) : (
          <Divider
            textAlign='left'
            light={true}
            sx={{ borderColor: 'rgba(0,0,0,0.07)' }}
          ></Divider>
        )}
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Workflow</Typography>
              <em>{'Automate actions based on specific events'}</em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/workflow'
            className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <AccountTreeOutlined />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      Workflow&nbsp;
                      <Typography
                        style={{
                          display: 'inline-block',
                          transform: 'translateY(-2px)',
                          fontSize: '0.6rem',
                          fontWeight: 450,
                          border: 'solid 1px #222',
                          color: '#222',
                          padding: '1px 5px',
                          borderRadius: '7px',
                        }}
                      >
                        new
                      </Typography>
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>
        {preview ? (
          <HtmlTooltip
            title={
              <>
                <Typography color='inherit'>Integration</Typography>
                <em>
                  {
                    'Connect external services to enable more powerful workflows.'
                  }
                </em>
              </>
            }
          >
            <Link
              component={RouterLink}
              to='/integration'
              className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
                isLg ? 'taller' : ''
              }`}
            >
              <ListItemButton className='listLinkitem'>
                <ListItemIcon>
                  <LinkOutlined />
                </ListItemIcon>
                {open ? (
                  <ListItemText
                    primary={
                      <Typography
                        style={{ fontWeight: 450, fontSize: '0.85rem' }}
                      >
                        Integration
                      </Typography>
                    }
                  />
                ) : (
                  <></>
                )}
              </ListItemButton>
            </Link>
          </HtmlTooltip>
        ) : (
          <></>
        )}
        <Box sx={{ p: 0.5 }} />
        {open ? (
          <Divider
            textAlign='left'
            light={true}
            sx={{ borderColor: 'rgba(0,0,0,0.07)' }}
          >
            <Typography style={{ fontSize: '0.75rem' }}>Develop</Typography>
          </Divider>
        ) : (
          <Divider
            textAlign='left'
            light={true}
            sx={{ borderColor: 'rgba(0,0,0,0.07)' }}
          ></Divider>
        )}
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>API Keys</Typography>
              <em>{''}</em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/api-keys'
            className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <Key />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      API Keys
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Rest API</Typography>
              <em>{''}</em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/rest-api'
            className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <CodeOutlined />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      Rest API
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>
        <Box sx={{ p: 0.5 }} />
        {open ? (
          <Divider
            textAlign='left'
            light={true}
            sx={{ borderColor: 'rgba(0,0,0,0.07)' }}
          >
            <Typography style={{ fontSize: '0.75rem' }}>Account</Typography>
          </Divider>
        ) : (
          <Divider
            textAlign='left'
            light={true}
            sx={{ borderColor: 'rgba(0,0,0,0.07)' }}
          ></Divider>
        )}
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Usage</Typography>
              <em>{'Monitor your usage'}</em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/account-usage'
            className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <DataUsageOutlined />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      Usage
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Billing</Typography>
              <em>{'Billing, payments and more'}</em>
            </>
          }
        >
          <Link
            component={RouterLink}
            to='/billing'
            className={`${open ? 'listLink left-padding-10' : 'listLink'} ${
              isLg ? 'taller' : ''
            }`}
          >
            <ListItemButton className='listLinkitem'>
              <ListItemIcon>
                <PaymentOutlined />
              </ListItemIcon>
              {open ? (
                <ListItemText
                  primary={
                    <Typography
                      style={{ fontWeight: 450, fontSize: '0.85rem' }}
                    >
                      Billing
                    </Typography>
                  }
                />
              ) : (
                <></>
              )}
            </ListItemButton>
          </Link>
        </HtmlTooltip>
      </List>
      <Box sx={{ flexGrow: 1 }} />
      {accountType === ACCOUNT_TYPE.TRIAL ? (
        open ? (
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'center' }}>
            <Button
              endIcon={<VerifiedRounded />}
              variant='contained'
              className='upgradeButton'
              onClick={handleUpgradeClick}
              fullWidth
            >
              Upgrade
            </Button>
          </Box>
        ) : (
          <HtmlTooltip
            title={
              <>
                <Typography color='inherit'>Upgrade</Typography>
                <em>{'Upgrade your account'}</em>
              </>
            }
          >
            <Box
              sx={{
                display: 'flex',
                paddingBottom: '30px',
                justifyContent: 'center',
              }}
            >
              <VerifiedRounded
                onClick={handleUpgradeClick}
                style={{ cursor: 'pointer', fontSize: '24px' }}
              />
            </Box>
          </HtmlTooltip>
        )
      ) : (
        <></>
      )}

      <Box
        sx={{
          backgroundColor: '#eee',
          display: 'flex',
          flexFlow: 'row',
          justifyContent: 'space-around',
        }}
      >
        {open ? (
          <Typography sx={{ padding: '10px 0px' }}>{userEmail}</Typography>
        ) : (
          <></>
        )}
        <HtmlTooltip
          title={
            <>
              <Typography color='inherit'>Log out</Typography>
              <em>{''}</em>
            </>
          }
        >
          <Button
            endIcon={<LogoutOutlined />}
            onClick={handleLogout}
            className='accountButton'
          ></Button>
        </HtmlTooltip>
      </Box>
    </Box>
  );
};

export default Navigation;
