import { getUrlParam } from '../utils/utils';
import BlogPanel from './BlogPanel';
import ChatPanel from './ChatPanel';
import SearchPanel from './SearchPanel';
import {
  ArticleOutlined,
  ChatBubbleRounded,
  Search,
} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, { useState } from 'react';
import { v4 as uuid } from 'uuid';

const sSessionId = uuid();
const cSessionId = uuid();
const bSessionId = uuid();

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role='tabpanel'
      hidden={value !== index}
      id={`vertical-tabpanel-${index}`}
      aria-labelledby={`vertical-tab-${index}`}
      style={{ textAlign: 'left', width: '100%' }}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `vertical-tab-${index}`,
    'aria-controls': `vertical-tabpanel-${index}`,
  };
}

function VerticalTabs({ selectedSources, savedHistory }) {
  const tab = Number(getUrlParam('tab', 0));
  const [selectedTabIndex, setSelectedTabIndex] = useState(tab);

  const handleChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  return (
    <Box
      sx={{
        width: '100%',
        flexGrow: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Tabs
        orientation='horizontal'
        variant='scrollable'
        value={selectedTabIndex}
        onChange={handleChange}
        className='vertical-tabs-tabs'
        sx={{ borderBottom: 1, borderColor: 'divider' }}
        visibleScrollbar={false}
      >
        <Tab
          className='chat-tab-button'
          sx={{ textAlign: 'left', textTransform: 'none' }}
          icon={<Search />}
          iconPosition='start'
          label='Search'
          {...a11yProps(0)}
        />

        <Tab
          className='chat-tab-button'
          sx={{ textAlign: 'left', textTransform: 'none' }}
          icon={<ChatBubbleRounded />}
          iconPosition='start'
          label='AI Agent'
          {...a11yProps(0)}
        />

        <Tab
          className='chat-tab-button'
          sx={{ textAlign: 'left', textTransform: 'none' }}
          icon={<ArticleOutlined />}
          iconPosition='start'
          label='Knowledge base'
          {...a11yProps(1)}
        />
      </Tabs>
      <TabPanel className='chat-tab-panel' value={selectedTabIndex} index={0}>
        <SearchPanel
          sessionId={sSessionId}
          selectedSources={selectedSources}
          savedHistory={savedHistory}
        ></SearchPanel>
      </TabPanel>
      <TabPanel className='chat-tab-panel' value={selectedTabIndex} index={1}>
        <ChatPanel
          sessionId={cSessionId}
          selectedSources={selectedSources}
          savedHistory={savedHistory}
        ></ChatPanel>
      </TabPanel>
      <TabPanel className='chat-tab-panel' value={selectedTabIndex} index={2}>
        <BlogPanel
          sessionId={bSessionId}
          selectedSources={selectedSources}
          savedHistory={savedHistory}
        ></BlogPanel>
      </TabPanel>
    </Box>
  );
}

export default VerticalTabs;
