import CodeRenderer from '../../components/CodeRenderer';
import applicationService from '../../service/applications';
import datasourceService from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
import {
  getDomainName,
  getProtocol,
  splitAndFilterEmptyLines,
} from '../../utils/utils';
import { OpenInNew } from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

const EditCustomKBSearchApplicationPage = () => {
  const { applicationId } = useParams();
  const [sources, setSources] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitType, setSubmitType] = useState('');

  const [systemPrompt, setSystemPrompt] = useState('');
  const [noKnowledgeAnswer, setNoKnowledgeAnswer] = useState('');

  const [greetingQuestions, setGreetingQuestions] = useState('');
  const [customStyle, setCustomStyle] = useState('');
  const [customPlaceholder, setCustomPlaceholder] = useState('');
  const [applicationName, setApplicationName] = useState('');
  const [selectedSources, setSelectedSources] = useState([]);
  const [analytics, setAnalytics] = useState(false);
  const [enabled, setEnabled] = useState(true);
  const [apiKey, setAPIKey] = useState([]);

  // Fetch application
  useEffect(() => {
    if (applicationId) {
      applicationService
        .get(applicationId)
        .then(data => {
          setApplicationName(data.name);
          setSystemPrompt(data.systemPrompt);
          setNoKnowledgeAnswer(data.noKnowledgeAnswer);
          setGreetingQuestions((data.greetingQuestions || []).join('\n'));
          setCustomStyle(data.properties?.customStyle || '');
          setCustomPlaceholder(data.properties?.customPlaceholder || '');
          setAPIKey(data.apiKey);
          setAnalytics(!!data.analytics);
          setEnabled(!data.disabled);
          setSelectedSources(
            (data.datasourceNames.length && data.datasourceNames) ||
              data.selectedSources
          );
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to fetch application');
        });
    }
  }, [applicationId, sources, setApplicationName, setSelectedSources]);

  // Fetch datasources
  useEffect(() => {
    datasourceService
      .list()
      .then(data => {
        setSources(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch data sources');
      });
  }, [setSources]);

  const handleSourceChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedSources(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (submitType === 'delete') {
      const result = window.confirm('Are you sure you want to proceed?');
      if (result) {
        setIsSubmitting(true);

        applicationService
          .remove(applicationId)
          .then(() => {
            toastSuccess('Application successfully deleted');

            setTimeout(() => {
              window.location.href = '/applications';
            }, 500);
          })
          .catch(error => {
            console.log('error', error.message);
            toastError(error.message ?? 'Failed to delete application');
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    } else {
      setIsSubmitting(true);

      applicationService
        .update({
          id: applicationId,
          datasourceNames: selectedSources,
          systemPrompt,
          noKnowledgeAnswer,
          analytics,
          disabled: !enabled,
          greetingQuestions: splitAndFilterEmptyLines(greetingQuestions),
          customStyle,
          customPlaceholder,
        })
        .then(() => {
          toastSuccess('Application successfully updated');
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to update application');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <Container className='newDSMuiContainer-root-application'>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
            <Link component={RouterLink} to='/' color='inherit'>
              Home
            </Link>
            <Link component={RouterLink} to='/applications' color='inherit'>
              Applications
            </Link>
            <Typography variant='body1' color='text.primary'>
              Custom KBSearch
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Typography className='header' alignContent='center'>
            {applicationName || 'Custom KBSearch'}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
              <Typography>Name*</Typography>
              <TextField
                fullWidth
                name='projectName'
                placeholder='Application name'
                sx={{ mb: 2 }}
                value={applicationName}
                onChange={event => setApplicationName(event.target.value)}
                disabled
              />

              <Typography>Data sources*</Typography>
              <Select
                id='source-multiple-chip'
                placeholder='Select'
                label='Source'
                displayEmpty
                multiple
                required
                value={selectedSources}
                onChange={handleSourceChange}
                input={<OutlinedInput />}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return '';
                  }

                  return selected.map(value => (
                    <Chip key={value} label={value} sx={{ mr: 1 }} />
                  ));
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value=''>
                  <em>data sources</em>
                </MenuItem>
                {sources.map(({ name, ready }) => {
                  return ready ? (
                    <MenuItem key={name} value={name}>
                      <span>{name}</span>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={name}
                      value={name}
                      // disabled
                    >
                      <span>{name}</span>
                    </MenuItem>
                  );
                })}
              </Select>
              <br />
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                      checked={analytics}
                      onChange={() => setAnalytics(!analytics)}
                    />
                  }
                  label='Enable Analytics'
                />
              </FormGroup>
              <br />

              <Typography>Greeting questions</Typography>
              <TextField
                placeholder='Greeting questions'
                variant='outlined'
                multiline
                value={greetingQuestions}
                onChange={event => setGreetingQuestions(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                minRows={5}
                required={false}
                size='small'
              />

              <Typography>Custom placeholder text</Typography>
              <TextField
                placeholder='Custom placeholder text'
                variant='outlined'
                value={customPlaceholder}
                onChange={event => setCustomPlaceholder(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                required={false}
                size='small'
              />

              <Typography>Custom No Knowledge answer</Typography>
              <TextField
                placeholder='Custom No Knowledge answer'
                variant='outlined'
                multiline
                value={noKnowledgeAnswer}
                onChange={event => setNoKnowledgeAnswer(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                minRows={10}
                required={false}
                size='small'
              />

              <Typography>Custom system prompt</Typography>
              <TextField
                placeholder='Custom system prompt'
                variant='outlined'
                multiline
                value={systemPrompt}
                onChange={event => setSystemPrompt(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                minRows={10}
                required={false}
                size='small'
              />
              <br />

              <Typography>Custom style</Typography>
              <TextField
                placeholder={`.input-box {
    border: 2px solid #ddd;
    background-color: #f5f5f5;
    color: #333;
    font-size: 16px;
    padding: 12px 20px;
    border-radius: 8px;
    }

.input-box:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(61, 13, 150, 0.7);
}`}
                variant='outlined'
                multiline
                value={customStyle}
                onChange={event => setCustomStyle(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                minRows={10}
                required={false}
                size='small'
              />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                      checked={enabled}
                      onChange={() => setEnabled(!enabled)}
                    />
                  }
                  label='Enabled'
                />
              </FormGroup>

              <br />

              <Button
                type='submit'
                className='createButton'
                value='update'
                onClick={() => setSubmitType('update')}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Update'}
              </Button>
              <br />

              <Button
                type='submit'
                className='deleteButton'
                value='delete'
                onClick={() => setSubmitType('delete')}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Delete'}
              </Button>
              <br />
              <br />
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} sm={6}>
          <Typography className='subsubheader' mb={2} mt={2}>
            <Typography
              variant='h5'
              mb={1}
              sx={{ fontWeight: 500, color: '#222' }}
            >
              Embed as iframe
            </Typography>
            <Typography
              variant='h5'
              mb={1}
              sx={{ fontWeight: 300, color: '#222' }}
            >
              Add the following html snippet into your page.&nbsp;
              <a
                className='try-it'
                href={`/ui_applications/kb_search/?applicationId=${applicationId}&apiKey=${apiKey}`}
                target='_blank'
                rel='noreferrer'
              >
                Try it <OpenInNew />
              </a>
            </Typography>
          </Typography>

          <CodeRenderer
            language='bash'
            code={`
<iframe id="kwazii-search-iframe" src="${getProtocol()}//${getDomainName()}/ui_applications/kb_search?applicationId=${applicationId}&amp;apiKey=${apiKey}" style="..." frameborder="0" height="..." width="..."></iframe>`}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditCustomKBSearchApplicationPage;
