import { APPLICATION_TYPES_MAP } from '../../constants';
import applicationsService from '../../service/applications';
import { useSessionStorage } from '../../utils/storage';
import { toastError } from '../../utils/toastUI';
import ConverseWithAI from './ConverseWithAI';
import {
  Breadcrumbs,
  Container,
  Grid,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const AskAIPage = () => {
  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useSessionStorage(
    `ask_ai_page_`,
    null
  );

  // Fetch applications
  useEffect(() => {
    applicationsService
      .list()
      .then(data => {
        setApplications(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch data sources');
      });
  }, [setApplications]);

  const handleApplicationChange = event => {
    const {
      target: { value: index },
    } = event;
    setSelectedApplication(applications[index]);
  };

  return (
    <Container maxWidth='100%' pr={0}>
      <Grid container spacing={2}>
        <Grid item xs={12} sx={{ display: 'flex', alignItems: 'center' }}>
          <Breadcrumbs aria-label='breadcrumb' sx={{ display: 'inline-block' }}>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Typography variant='h7' component='div'>
              Ask AI
            </Typography>
          </Breadcrumbs>
          <Select
            id='application-multiple-chip'
            placeholder='Select'
            label='Application'
            displayEmpty
            value={selectedApplication}
            onChange={handleApplicationChange}
            input={<OutlinedInput />}
            style={{ borderRadius: '10px', marginLeft: '20px', float: 'right' }}
            renderValue={selected => {
              if (!selected) {
                return <em>application</em>;
              }

              return selected.name;
            }}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem disabled value=''>
              <em>application</em>
            </MenuItem>
            {applications.map(({ name, type, analytics, disabled }, index) => {
              return analytics &&
                !disabled &&
                type === APPLICATION_TYPES_MAP.HISTORICAL_SUPPORT_DATA ? (
                <MenuItem key={name} value={index}>
                  <span>{name}</span>
                </MenuItem>
              ) : (
                <MenuItem key={name} value={index} disabled>
                  <span style={{ textDecoration: 'line-through' }}>{name}</span>
                </MenuItem>
              );
            })}
          </Select>
        </Grid>

        <Grid item xs={12}>
          <ConverseWithAI application={selectedApplication} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default AskAIPage;
