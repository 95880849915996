export const Template2 = {
  nodes: [
    {
      id: '1',
      type: 'triggerNode',
      data: {
        stepName: 'Every Week',
        component: 'schedule',
        scheduleId: 'weekly',
      },
    },
    {
      id: '2',
      type: 'applicationSelectorNode',
      data: {
        component: 'select_application',
        stepName: 'Select an Application',
      },
    },
    {
      id: '3',
      type: 'actionNode',
      data: {
        prompt:
          'Find the product improvement category that is trending in the last 7 days. Gather the associated improvements as well',
        stepName: 'Trending pain points',
        component: 'fetch_data',
      },
    },
    {
      id: '4',
      type: 'formatterNode',
      data: {
        component: 'format_data',
        stepName: 'Trending product category report',
        prompt: `Craft an email report to summarize the trend and improvements. Dont generate subject. Use markdown. Use below format
----------------
Hello,

This automated message brings you insights into the product improvement categories that have shown significant trends over the past week. Below, you will find a concise summary of each trending area along with their associated improvements:

### Trending Category: [Category Name]
Improvement 1: [Brief description]

Improvement 2: [Brief description]

...
### Improvement N: [Brief description]

Trending Category: [Another Category Name]
Improvement 1: [Brief description]

Improvement 2: [Brief description]

...
### Improvement N: [Brief description]


[Repeat the format for additional categories]

We hope this data-driven update supports your decision-making process and strategic initiatives.

Warm regards,

The Kwazii CoPilot System
`,
      },
    },
    {
      id: '5',
      type: 'notifyNode',
      data: {
        component: 'send_email',
        subject: 'Weekly Product Trends Report',
        stepName: 'Send Email',
      },
    },
  ],
};
