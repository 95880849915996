import ConversationView from '../components/ConversationView';
import JsonToAccordion from '../components/JsonToAccordion';
import ReactFlowStateMachine from '../components/ReactFlowStateMachine';
import { Tab, Tabs } from '@mui/material';
import * as React from 'react';
import { useState } from 'react';

function TicketAnalytics({ data }) {
  console.log(data);

  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const handleChange = (event, newValue) => {
    setSelectedTabIndex(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role='tabpanel'
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        style={{ textAlign: 'left', width: '100%' }}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  return (
    <div className='historical-insights'>
      <a
        className='original-ticket'
        target='_blank'
        rel='noreferrer'
        href={data.permaUrl}
      >
        Original Ticket
      </a>
      <br />
      <Tabs
        value={selectedTabIndex}
        onChange={handleChange}
        style={{ marginTop: '10px' }}
      >
        <Tab label='Report' />
        <Tab label='Conversation' />
        <Tab label='Flow' />
      </Tabs>
      <TabPanel value={selectedTabIndex} index={0}>
        <div className='ticket-analytics-panel'>
          <JsonToAccordion data={data} />
          <div style={{ paddingBottom: '100px' }}></div>
        </div>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={1}>
        <div className='ticket-analytics-panel'>
          <ConversationView data={data.stateMachine} />
          <div style={{ paddingBottom: '100px' }}></div>
        </div>
      </TabPanel>
      <TabPanel value={selectedTabIndex} index={2}>
        <div className='ticket-analytics-panel'>
          <ReactFlowStateMachine
            key={Math.random()}
            stateMachineData={data.stateMachine}
          />
        </div>
      </TabPanel>
    </div>
  );
}

export default TicketAnalytics;
