import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

const UrlGlobTextField = props => {
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = event => {
    const value = event.target.value;
    setIsValid(isValidUrl(value));
    if (props.onChange) {
      props.onChange(event);
    }
  };

  const isValidUrl = url => {
    try {
      const parsedUrl = new URL(url);
      return parsedUrl.protocol === 'http:' || parsedUrl.protocol === 'https:';
    } catch (error) {
      return false;
    }
  };

  return (
    <TextField
      {...props}
      inputProps={{
        pattern: '^[a-zA-Z0-9 _-]*$', // For mobile browsers support
        onChange: handleInputChange,
      }}
      error={!isValid}
    />
  );
};

export default UrlGlobTextField;
