import { NewWorkflowContext } from '../Context';
import { Box, Grid, MenuItem, Select, Typography } from '@mui/material';
import React, { useContext, useState } from 'react';

const SCHEDULES = [
  {
    id: 'daily',
    name: 'Every Day',
  },
  {
    id: 'weekly',
    name: 'Every Week',
  },
  {
    id: 'monthly',
    name: 'Every Month',
  },
];
const Scheduler = () => {
  const { selectedNode, onNodeUpdate } = useContext(NewWorkflowContext);
  const [cronScheduleId, setCronScheduleId] = useState(
    selectedNode?.data?.scheduleId
  );

  const onUpdateSchedule = scheduleId => {
    setCronScheduleId(scheduleId);

    const updatedNode = {
      ...(selectedNode || {}),
      data: {
        ...(selectedNode?.data || {}),
        state: {},
        stepName: SCHEDULES.find(a => a.id === scheduleId).name,
        scheduleId: scheduleId,
      },
    };

    onNodeUpdate(updatedNode);
  };

  return (
    <Grid container xs={12} pl={2}>
      <Grid item xs={12}>
        <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
          <Typography mb={1.5}>Schedule</Typography>
          <Select
            labelId='cron-schedule-label'
            name='cronSchedule'
            value={cronScheduleId}
            onChange={event => onUpdateSchedule(event.target.value)}
          >
            {SCHEDULES.map(schd => (
              <MenuItem value={`${schd.id}`}>{schd.name}</MenuItem>
            ))}
          </Select>
          <br />
        </Box>
      </Grid>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
    </Grid>
  );
};

export default Scheduler;
