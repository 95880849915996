import { getUrlParam } from '../../utils/utils';
import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import Image from 'mui-image';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const IntegrationEmptyStateWithNew = () => {
  const preview = getUrlParam('preview', false);

  const workflows = [
    {
      id: 1,
      icon: (
        <Image
          duration={0}
          easing='none'
          src='/external/segment_logo.png'
          style={{
            width: '70px',
            height: '50px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Segment',
      link: '/workflow/new/segment',
      description: '',
    },
    ...(preview
      ? [
          {
            id: 1,
            icon: (
              <Image
                duration={0}
                easing='none'
                src='/external/slack_logo.png'
                style={{
                  width: '70px',
                  height: '50px',
                  paddingRight: '20px',
                  color: '#444',
                }}
              />
            ),
            title: 'Slack',
            link: '/workflow/new/segment',
            description: '',
          },
          {
            id: 1,
            icon: (
              <Image
                duration={0}
                easing='none'
                src='/external/bigquery_logo.png'
                style={{
                  width: '70px',
                  height: '50px',
                  paddingRight: '20px',
                  color: '#444',
                }}
              />
            ),
            title: 'Google BigQuery',
            link: '/workflow/new/bigquery',
            description: '',
          },
        ]
      : []),
  ];

  return (
    <>
      <Grid item xs={12}>
        <Typography className='subsubheader'>
          Integrations push data to any source, including databases, CDP, and
          productivity tools of your choice.
        </Typography>
      </Grid>
      {/* Data Source Cards */}
      {workflows.map(workflow => (
        <Grid item xs={12} sm={4} md={4} lg={2.5} xl={2.5} key={workflow.id}>
          <Link component={RouterLink} to={workflow.link} color='inherit'>
            <Card sx={{ display: 'flex', flexDirection: 'column' }}>
              <CardContent
                sx={{ height: '100px', display: 'flex', flexFlow: 'row' }}
              >
                <div>{workflow.icon}</div>
                <Box sx={{ display: 'flex' }}>
                  <Typography
                    className='card-title'
                    sx={{ alignSelf: 'center' }}
                  >
                    {workflow.title}
                  </Typography>
                </Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {workflow.description}
                </Typography>
              </CardContent>
            </Card>
          </Link>
        </Grid>
      ))}
    </>
  );
};

export default IntegrationEmptyStateWithNew;
