import KbSearchApplicationPage from '../external_pages/kbsearch/KbSearchApplicationPage';
import theme from '../theme';
import { getUrlParam } from '../utils/utils';
import './AppKbSearch.scss';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

const scrollToBottom = () => {
  const scrollHeight = document.body.clientHeight;
  window.scrollTo(0, scrollHeight);
};

const useAutoScroll = enableAutoScroll => {
  useEffect(() => {
    if (enableAutoScroll) {
      const observer = new ResizeObserver(scrollToBottom);
      observer.observe(document.body);

      return () => {
        observer.disconnect();
      };
    }
  }, [enableAutoScroll]);
};

const updateHeight = height => {
  if (height > 0) {
    window.parent.postMessage(
      {
        message: 'kwazii:resize',
        height: height,
      },
      '*'
    );
  }
};

const useResizePostMessage = () => {
  useEffect(() => {
    const observer = new ResizeObserver(() => {
      updateHeight(document.body.scrollHeight);
    });

    observer.observe(document.body);
    return () => {
      observer.disconnect();
    };
  }, []);
};

const InnerApp = () => {
  const enableAutoScroll = getUrlParam('enableAutoScroll');

  useAutoScroll(Boolean(enableAutoScroll));
  useResizePostMessage();

  return (
    <div className='search-page-base search-page'>
      <Box sx={{ display: 'flex' }}>
        <Router>
          <Routes>
            <Route
              path='/ui_applications/kb_search'
              element={<KbSearchApplicationPage />}
            />
            <Route path='*' element={<></>} />
          </Routes>
        </Router>
      </Box>
    </div>
  );
};

const AppKbSearch = () => (
  <ThemeProvider theme={theme}>
    <InnerApp />
  </ThemeProvider>
);

export default AppKbSearch;
