import {
  CloudOutlined,
  FileUploadOutlined,
  LibraryBooksOutlined,
} from '@mui/icons-material';
import { Box, Card, CardContent, Grid, Link, Typography } from '@mui/material';
import Image from 'mui-image';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const DataSourceEmptyStateWithNew = () => {
  const externalDataSources = [
    {
      id: 1,
      icon: (
        <Image
          duration={0}
          easing='none'
          src='/external/zendesk_logo.png'
          style={{
            width: '70px',
            height: '50px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Connect to Zendesk',
      link: '/data-sources/new/zendesk',
      description: 'Private ticketing, and knowledge base data',
    },
    {
      id: 2,
      icon: (
        <Image
          duration={0}
          easing='none'
          src='/external/reamaze_logo.png'
          style={{
            width: '70px',
            height: '50px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Connect to Re:amaze',
      link: '/data-sources/new/reamaze',
      description: 'Private ticketing, and knowledge base data',
    },
  ];

  // eslint-disable-next-line
  const dataSources = [
    {
      id: 1,
      icon: (
        <LibraryBooksOutlined
          sx={{
            width: '50px',
            height: '30px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Public dataset',
      link: '/data-sources/new/web',
      description:
        'Publicly available information such as docs, developer and blog websites',
    },
    {
      id: 2,
      icon: (
        <FileUploadOutlined
          sx={{
            width: '50px',
            height: '30px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Upload files',
      link: '/data-sources/new/files',
      description: 'Upload your files',
    },
    {
      id: 3,
      icon: (
        <CloudOutlined
          sx={{
            width: '50px',
            height: '30px',
            paddingRight: '20px',
            color: '#444',
          }}
        />
      ),
      title: 'Private dataset in S3',
      link: '/data-sources/new/s3',
      description: 'Private dataset stored in S3',
    },
    // {
    //     id: 5,
    //     icon: <FolderOutlined />,
    //     title: 'Atlassian Confluence (coming soon)',
    //     // link: "/data-sources/new/confluence",
    //     link: "/coming-soon",
    //     description: 'Content management',
    // },
    // {
    //     id: 6,
    //     icon: <Analytics />,
    //     title: 'Segment CDP (coming soon)',
    //     // link: "/data-sources/new/segment",
    //     link: "/coming-soon",
    //     description: 'Real-time Analytics dataset ',
    // },
  ];

  return (
    <>
      <Grid item xs={12}>
        <Typography className='subsubheader'>
          Connect your data source to Kwazii
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <Typography className='subheader2' alignContent='center'>
          External
        </Typography>
      </Grid>

      {/* Data Source Cards */}
      {externalDataSources.map(dataSource => (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={dataSource.id}>
          <Card sx={{ display: 'flex', flexDirection: 'column' }}>
            <CardContent
              sx={{ height: '130px', display: 'flex', flexFlow: 'row' }}
              component={RouterLink}
              to={dataSource.link}
            >
              <div style={{ alignSelf: 'center' }}>{dataSource.icon}</div>
              <Link
                style={{ alignSelf: 'center' }}
                component={RouterLink}
                to={dataSource.link}
                color='inherit'
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography className='card-title'>
                    {dataSource.title}
                  </Typography>
                </Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {dataSource.description}
                </Typography>
              </Link>
            </CardContent>
          </Card>
        </Grid>
      ))}

      <Grid item xs={12}>
        <Typography className='subheader2' alignContent='center'>
          Custom
        </Typography>
      </Grid>

      {/* Data Source Cards */}
      {dataSources.map(dataSource => (
        <Grid item xs={12} sm={12} md={6} lg={4} xl={3} key={dataSource.id}>
          <Card
            sx={{ display: 'flex', flexDirection: 'column' }}
            component={RouterLink}
            to={dataSource.link}
          >
            <CardContent
              sx={{ height: '130px', display: 'flex', flexFlow: 'row' }}
            >
              <div style={{ alignSelf: 'center' }}>{dataSource.icon}</div>
              <Link
                style={{ alignSelf: 'center' }}
                component={RouterLink}
                to={dataSource.link}
                color='inherit'
              >
                <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
                  <Typography className='card-title'>
                    {dataSource.title}
                  </Typography>
                </Box>
                <Typography
                  variant='body2'
                  color='text.secondary'
                  sx={{ textOverflow: 'ellipsis', overflow: 'hidden' }}
                >
                  {dataSource.description}
                </Typography>
              </Link>
            </CardContent>
          </Card>
        </Grid>
      ))}
    </>
  );
};

export default DataSourceEmptyStateWithNew;
