import { EditOutlined } from '@mui/icons-material';
import { IconButton, InputAdornment } from '@mui/material';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import React, { useEffect, useState } from 'react';

const EditableTypography = ({ value, onChange }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [text, setText] = useState(value);

  const handleClick = () => {
    setIsEditing(true);
  };

  const handleBlur = () => {
    setIsEditing(false);
  };

  const handleChange = event => {
    setText(event.target.value);
    onChange(event.target.value);
  };

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleBlur();
    }
  };

  useEffect(() => {
    setText(value);
  }, [value]);

  return (
    <div>
      {isEditing ? (
        <TextField
          value={text}
          onChange={handleChange}
          onBlur={handleBlur}
          autoFocus
          required={true}
          sx={{
            position: 'absolute',
            transform: 'translateY(-21px)',
            border: 0,
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <IconButton type='submit'>
                  <EditOutlined sx={{ fontSize: 16 }} />
                </IconButton>
              </InputAdornment>
            ),
          }}
          onKeyDown={handleKeyDown} // Handle Enter key press
        />
      ) : (
        <Typography variant='body1' onClick={handleClick}>
          {text}&nbsp;
          <EditOutlined sx={{ fontSize: 14 }} />
        </Typography>
      )}
    </div>
  );
};

export default EditableTypography;
