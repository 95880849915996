import AskAI from '../components/AskAI';
import TicketAnalytics from '../components/TicketAnalytics';
import GenerateKB from '../pages/insights/search/components/GenerateKB';
import SearchConversation from '../pages/insights/search/components/SearchConversation';
import TryChat from '../pages/insights/search/components/TryChat';
import './AppBase.scss';
import { Close } from '@mui/icons-material';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { v4 as uuid } from 'uuid';

const drawerWidth = 700;
let id = uuid();

const openedMixin = theme => ({
  width: drawerWidth,
  transition: {
    enter: false,
    exit: false,
  },
  overflowX: 'hidden',
  backgroundColor: 'rgb(250, 250, 250)',
});

const closedMixin = theme => ({
  transition: {
    enter: false,
    exit: false,
  },
  overflowX: 'hidden',
  width: 0,
  [theme.breakpoints.up('sm')]: {
    width: 0,
  },
  backgroundColor: 'rgb(250, 250, 250)',
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
  }),
}));

function RightPane() {
  const [open, setOpen] = useState(false);
  const [renderEvent, setRenderEvent] = useState({});

  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  console.log(`isMobile: ${isMobile}`);

  const key = JSON.stringify(renderEvent);

  const openDrawer = () => {
    if (isMobile) {
      document.querySelector('.main-content').style.display = 'none';
    }

    setOpen(true);
  };

  const closeDrawer = () => {
    if (isMobile) {
      document.querySelector('.main-content').style.display = 'flex';
    }

    setOpen(false);
  };

  const handleDrawerClose = () => {
    closeDrawer();
    window.dispatchEvent(new CustomEvent('info-pane-close'));
  };

  useEffect(() => {
    // Add an event listener to handle the custom event
    const renderInfoPane = event => {
      id = uuid();
      setRenderEvent({ ...event?.detail });
    };

    window.addEventListener('render-info-pane', renderInfoPane);
    window.addEventListener('info-pane-open', openDrawer);

    window.addEventListener('info-pane-close', closeDrawer);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener('render-info-pane', renderInfoPane);
    };
    // eslint-disable-next-line
  }, [isMobile]);

  return (
    <Drawer
      variant='permanent'
      open={open}
      anchor={'right'}
      transitionDuration={0}
      sx={{
        '> .MuiPaper-root': {
          position: isMobile ? 'relative' : 'fixed',
          width: isMobile
            ? 'calc(100vw - 60px)'
            : 'calc((100vw - 240px) * 0.4)',
          display: open ? 'block' : 'none',
        },
      }}
    >
      <Box className='right-info-pane' sx={{ borderLeft: 'solid 1px #fafafa' }}>
        {open ? (
          <Box sx={{ position: 'absolute' }}>
            <IconButton
              className='info-drawer-close-button'
              onClick={handleDrawerClose}
            >
              <Close />
            </IconButton>
          </Box>
        ) : (
          <></>
        )}

        {renderEvent?.type === 'ticket-insights' ? (
          <TicketAnalytics key={key} {...renderEvent} />
        ) : (
          <></>
        )}
        {renderEvent?.type === 'ask-ai' ? (
          <AskAI key={key} id={renderEvent.id} {...renderEvent}></AskAI>
        ) : (
          <></>
        )}
        {renderEvent?.type === 'generate-kb' ? (
          <GenerateKB key={key} {...renderEvent}></GenerateKB>
        ) : (
          <></>
        )}
        {renderEvent?.type === 'playground-chat' ? (
          <TryChat key={key} id={id} {...renderEvent}></TryChat>
        ) : (
          <></>
        )}
        {renderEvent?.type === 'open-search-conversation' ? (
          <SearchConversation
            key={key}
            id={id}
            {...renderEvent}
          ></SearchConversation>
        ) : (
          <></>
        )}
      </Box>
    </Drawer>
  );
}

export default RightPane;
