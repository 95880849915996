import Banner from '../components/Banner';
import auth from '../service/auth';
import { LoadingButton } from '@mui/lab';
import { Box, Container, Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';

const WaitlistPage = () => {
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [emailError, setEmailError] = useState('');
  const [emailMessage, setEmailMessage] = useState('');

  const handleEmailChange = e => {
    setEmail(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      document.querySelector('#joinWaitlistButton').click();
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();

    if (!validateEmail(email)) {
      setEmailError('Invalid email');
      return;
    }

    setLoading(true);
    setEmailError(null);

    // Proceed with waitlist registration logic
    await auth
      .login(email)
      .then(data => {
        setEmailMessage(data.message);
      })
      .catch(error => {
        console.log('error', error.message);
        setEmailError(error.message);
        setEmailMessage('');
      })
      .finally(() => setLoading(false));
  };

  const validateEmail = email => {
    // Basic email validation using regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  return (
    <div
      className='greeting-container'
      style={{ display: 'flex', justifyContent: 'center', width: '100vw' }}
    >
      <Banner></Banner>
      <Container className='login-container greeting-animation'>
        <Box className='login-logoContainer'>
          <a href='https://www.kwazii.app/'>
            <img class='frame' alt='Kwazii' src='/logo_1.png' />
          </a>
        </Box>
        <Grid container spacing={2} alignItems={'flex-start'} mt={10}>
          <Grid item xs={12} md={6}>
            <Typography variant='h6'>
              Join the waitlist and be the first to experience Kwazii when it
              launches!
            </Typography>
          </Grid>
          <Grid item xs={12} md={6} container direction='column' spacing={2}>
            <Grid item>
              <Typography fontWeight={'500'}>
                Enter your company email
              </Typography>
              <TextField
                fullWidth
                variant='outlined'
                value={email}
                onChange={handleEmailChange}
                onKeyPress={handleKeyPress}
                helperText={
                  emailMessage ? (
                    <span
                      style={{
                        fontSize: '0.8rem',
                        fontWeight: 500,
                        color: 'darkgreen',
                      }}
                    >
                      {emailMessage}
                    </span>
                  ) : emailError ? (
                    <div className='waitlist-helper-text'>{emailError}</div>
                  ) : (
                    ''
                  )
                }
              />
            </Grid>
            <Grid item>
              <Typography variant='body2'>
                We'll notify you once Kwazii is ready to launch!
              </Typography>
            </Grid>
            <Grid item>
              <LoadingButton
                id='joinWaitlistButton'
                className='loginButton'
                onClick={handleSubmit}
                loading={loading}
                loadingPosition='end'
                variant='contained'
              >
                <span>Join Waitlist</span>
              </LoadingButton>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </div>
  );
};

export default WaitlistPage;
