import { Typography } from '@mui/material';
import React from 'react';

const ApiKeysEmptyState = () => {
  return (
    <div>
      <Typography className='subsubheader' mb={2} mt={2} ml={2}>
        An API key is essential for programmatic access to the Kwaziii API. It
        acts as a unique identifier, granting authorized applications secure
        access to API functionalities and data.
        <br />
        While using the Kwaziii playground or applications doesn't require an
        API key, external programs need it to interact with the API securely.
        Each application within Kwaziii generates its own key, enabling seamless
        communication between platform components.
      </Typography>
    </div>
  );
};

export default ApiKeysEmptyState;
