import { getAuthEndpoint } from '../utils/utils';
import { handleCompletion } from './common';

async function login(email, ots, refCode) {
  const url = `${getAuthEndpoint()}/login`;
  const headers = {
    'Content-Type': 'application/json',
  };
  var body = JSON.stringify({
    email: email,
    ots: ots,
    ref_code: refCode,
  });
  var requestOptions = {
    method: 'POST',
    headers,
    body,
  };

  return handleCompletion(fetch(url, requestOptions));
}

async function checkToken(token) {
  const url = `${getAuthEndpoint()}/check_token`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`, // Add the Authorization header with the token
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

async function verifyCode(ec) {
  const url = `${getAuthEndpoint()}/verify?ec=${ec}`;

  var requestOptions = {
    method: 'GET',
  };

  return handleCompletion(fetch(url, requestOptions));
}

const fns = {
  login,
  checkToken,
  verifyCode,
};

export default fns;
