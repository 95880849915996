import { Button } from '@mui/material';
import { styled } from '@mui/material/styles';

const AddButton = styled(Button)`
  width: 18px;
  min-width: 18px;
  display: flex !important;
  justify-content: center;
  height: 18px;
  background: #aaa !important;
  border: none !important;
  cursor: pointer;
  border-radius: 4px !important;
  font-size: 12px;
  line-height: 20px; /* Adjusted for vertical centering */
  text-align: center;
  display: inline-block;
  color: #eee !important;
  overflow: hidden;
  padding: 0;
  padding-bottom: 4px !important;

  &:hover {
    box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.08);
    text-decoration: none !important;
  }
`;

export default AddButton;
