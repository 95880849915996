import {
  TICKETING_INSIGHT_TABLE_ORDER,
  TICKETING_INSIGHT_TABLE_ORDER_BY,
} from '../../../../constants';
import { useSessionStorage } from '../../../../utils/storage';
import {
  capitalizeText,
  debounce,
  truncateTextEnd,
} from '../../../../utils/utils';
import BorderOnHoverButton from '../../search/components/BorderOnHoverButton';
import MenuListComposition from './MenuListComposition';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import {
  Divider,
  IconButton,
  InputAdornment,
  Paper,
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@mui/material';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import { styled } from '@mui/material/styles';
import moment from 'moment-timezone';
import React, { useEffect, useRef, useState } from 'react';

const StyledSpan = styled(Typography)`
  text-decoration: none;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:nth-of-type(odd)': {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableSubHeadCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: theme.palette.action.selected,
    color: theme.palette.common.black,
    borderBottom: 'solid 1px #bbb',
    fontSize: '0.9rem',
  },
}));

function TicketsTable({
  application,
  data,
  selectedIntent = '',
  selectedFeature = '',
  setIntentCategory,
  setFeatureCategory,
}) {
  const [filters, setFilters] = useState({
    intentCategory: selectedIntent,
    intentIssue: '',
    intentFeature: '',
    featureImprovementCategory: selectedFeature,
    painPointCategory: '',
    supportImprovementCategory: '',
  });

  const [order, setOrder] = useSessionStorage(
    TICKETING_INSIGHT_TABLE_ORDER,
    'desc'
  );
  const [orderBy, setOrderBy] = useSessionStorage(
    TICKETING_INSIGHT_TABLE_ORDER_BY,
    'cxCreatedTimestamp'
  );
  const myRef = useRef(null);
  const debSetIntentCategory = debounce(setIntentCategory, 1000);
  const debSetFeatureCategory = debounce(setFeatureCategory, 1000);

  const handleSortRequest = property => {
    const isAsc = orderBy === property && order === 'asc';

    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleFilterChange = event => {
    const { name, value } = event.target;
    setFilters(prevFilters => ({
      ...prevFilters,
      [name]: value,
    }));

    if (name === 'intentCategory') {
      debSetIntentCategory(value);
    }

    if (name === 'featureImprovementCategory') {
      debSetFeatureCategory(value);
    }
  };

  const sortedData = [...data].sort((a, b) => {
    if (orderBy === 'ticketAssigneeName') {
      return order === 'asc'
        ? `${a.ticketAssigneeName || ''}`.localeCompare(
            `${b.ticketAssigneeName || ''}`
          )
        : `${b.ticketAssigneeName || ''}`.localeCompare(
            `${a.ticketAssigneeName || ''}`
          );
    } else if (orderBy === 'cxCreatedTimestamp') {
      return order === 'asc'
        ? a.cxCreatedTimestamp - b.cxCreatedTimestamp
        : b.cxCreatedTimestamp - a.cxCreatedTimestamp;
    } else if (orderBy === 'happinessScore') {
      return order === 'asc'
        ? a.happinessScore * 1 - b.happinessScore * 1
        : b.happinessScore * 1 - a.happinessScore * 1;
    }
    return 0;
  });

  const handleTicketInsights = row => {
    window.dispatchEvent(new CustomEvent('info-pane-open'));
    var customEvent = new CustomEvent('render-info-pane', {
      detail: {
        type: 'ticket-insights',
        application: application,
        data: row,
      },
    });
    // Dispatch the custom event on the window
    window.dispatchEvent(customEvent);
  };

  useEffect(() => {
    const scrollToRef = () => {
      if (myRef.current && (selectedIntent || selectedFeature)) {
        myRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    };

    scrollToRef();
  }, [selectedIntent, selectedFeature]);

  if (!data) {
    return null;
  }

  const filterFeatureCategory =
    filters.featureImprovementCategory || selectedFeature;
  const filterIntentCategory = filters.intentCategory || selectedIntent;
  const filteredData = sortedData.filter(
    row =>
      (!filterIntentCategory ||
        row.intentCategory
          ?.toLowerCase()
          .includes(filterIntentCategory?.toLowerCase())) &&
      (!filters.intentIssue ||
        row.intentIssue
          ?.toLowerCase()
          .includes(filters.intentIssue?.toLowerCase())) &&
      (!filters.intentFeature ||
        row.intentFeature
          ?.toLowerCase()
          .includes(filters.intentFeature?.toLowerCase())) &&
      (!filterFeatureCategory ||
        row.featureImprovementCategory
          ?.toLowerCase()
          .includes(filterFeatureCategory?.toLowerCase())) &&
      (!filters.painPointCategory ||
        row.painPointCategory
          ?.toLowerCase()
          .includes(filters.painPointCategory?.toLowerCase())) &&
      (!filters.supportImprovementCategory ||
        row.supportImprovementCategory
          ?.toLowerCase()
          .includes(filters.supportImprovementCategory?.toLowerCase()))
  );

  return (
    <div style={{ width: '100%', overflowX: 'auto' }} ref={myRef}>
      {/* eslint-disable-next-line */}
      <a name='tickets'></a>
      <Paper
        elevation={3}
        sx={{
          m: 2,
          width: 'calc(100% - 20px)',
          border: 'solid 1px #eee',
          borderTop: 0,
        }}
      >
        <Divider />
        <Table stickyHeader aria-label='sticky table'>
          <TableHead>
            <TableRow>
              <TableCell align='center' colSpan={4}></TableCell>
              <TableCell align='center' colSpan={1}>
                Intent <small>(ticket)</small>
              </TableCell>
              <TableCell align='center' colSpan={2}>
                Product
              </TableCell>
              <TableCell align='center' colSpan={1}>
                Agent
              </TableCell>
              <TableCell align='center' colSpan={1}></TableCell>
            </TableRow>
            <TableRow>
              <StyledTableSubHeadCell>
                <TableSortLabel
                  active={orderBy === 'cxCreatedTimestamp'}
                  direction={order}
                  onClick={() => handleSortRequest('cxCreatedTimestamp')}
                >
                  <div>Date</div>
                </TableSortLabel>
                <div style={{ fontSize: '0.6rem', color: '#777' }}>MM/DD</div>
              </StyledTableSubHeadCell>

              <StyledTableSubHeadCell
                sx={{ fontSize: '0.9rem', minWidth: '200px' }}
              >
                Subject
              </StyledTableSubHeadCell>
              <StyledTableSubHeadCell
                sx={{ fontSize: '0.9rem', minWidth: '200px' }}
              >
                <TableSortLabel
                  active={orderBy === 'ticketAssigneeName'}
                  direction={order}
                  onClick={() => handleSortRequest('ticketAssigneeName')}
                >
                  Assignee
                </TableSortLabel>
              </StyledTableSubHeadCell>
              <StyledTableSubHeadCell
                sx={{ fontSize: '0.9rem', minWidth: '200px' }}
              >
                <TableSortLabel
                  active={orderBy === 'happinessScore'}
                  direction={order}
                  onClick={() => handleSortRequest('happinessScore')}
                >
                  Sentiment score
                </TableSortLabel>
              </StyledTableSubHeadCell>

              <StyledTableSubHeadCell align='left'>
                <div>Ticket Category</div>
                <TextField
                  variant='standard'
                  placeholder=''
                  name='intentCategory'
                  value={filterIntentCategory}
                  onChange={handleFilterChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        {filterIntentCategory && (
                          <IconButton
                            edge='end'
                            onClick={() =>
                              handleFilterChange({
                                target: { name: 'intentCategory', value: '' },
                              })
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledTableSubHeadCell>

              <StyledTableSubHeadCell align='left'>
                <div>Product Category</div>
                <TextField
                  variant='standard'
                  placeholder=''
                  name='featureImprovementCategory'
                  value={filterFeatureCategory}
                  onChange={handleFilterChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        {filterFeatureCategory && (
                          <IconButton
                            edge='end'
                            onClick={() =>
                              handleFilterChange({
                                target: {
                                  name: 'featureImprovementCategory',
                                  value: '',
                                },
                              })
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledTableSubHeadCell>
              <StyledTableSubHeadCell align='left'>
                <div>Feature</div>
                <TextField
                  variant='standard'
                  placeholder=''
                  name='intentFeature'
                  value={filters.intentFeature}
                  onChange={handleFilterChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        {filters.intentFeature && (
                          <IconButton
                            edge='end'
                            onClick={() =>
                              handleFilterChange({
                                target: { name: 'intentFeature', value: '' },
                              })
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledTableSubHeadCell>

              <StyledTableSubHeadCell align='left'>
                <div>Support Category</div>
                <TextField
                  variant='standard'
                  placeholder=''
                  name='supportImprovementCategory'
                  value={filters.supportImprovementCategory}
                  onChange={handleFilterChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='start'>
                        <SearchIcon />
                      </InputAdornment>
                    ),
                    endAdornment: (
                      <InputAdornment position='end'>
                        {filters.supportImprovementCategory && (
                          <IconButton
                            edge='end'
                            onClick={() =>
                              handleFilterChange({
                                target: {
                                  name: 'supportImprovementCategory',
                                  value: '',
                                },
                              })
                            }
                          >
                            <ClearIcon />
                          </IconButton>
                        )}
                      </InputAdornment>
                    ),
                  }}
                />
              </StyledTableSubHeadCell>

              <StyledTableSubHeadCell align='center'>
                &nbsp;
              </StyledTableSubHeadCell>
            </TableRow>
          </TableHead>
          <TableBody key={Math.random()}>
            {filteredData.map(row => (
              <StyledTableRow key={row.question}>
                <StyledTableCell align='left'>
                  {moment.tz(row.cxCreatedTimestamp, 'UTC').format('M/D')}
                </StyledTableCell>
                <StyledTableCell>
                  <StyledSpan onClick={() => handleTicketInsights(row)}>
                    {row.subject}
                  </StyledSpan>
                </StyledTableCell>
                <StyledTableCell>{row.ticketAssigneeName}</StyledTableCell>
                <StyledTableCell>{row.happinessScore}</StyledTableCell>

                <StyledTableCell
                  align='left'
                  onClick={() =>
                    handleFilterChange({
                      target: {
                        name: 'intentCategory',
                        value: row.intentCategory,
                      },
                    })
                  }
                >
                  <BorderOnHoverButton>
                    {capitalizeText(row.intentCategory)}
                  </BorderOnHoverButton>
                </StyledTableCell>

                <StyledTableCell
                  align='left'
                  onClick={() =>
                    handleFilterChange({
                      target: {
                        name: 'featureImprovementCategory',
                        value: row.featureImprovementCategory,
                      },
                    })
                  }
                >
                  <BorderOnHoverButton>
                    {capitalizeText(row.featureImprovementCategory)}
                  </BorderOnHoverButton>
                </StyledTableCell>
                <StyledTableCell
                  align='left'
                  onClick={() =>
                    handleFilterChange({
                      target: {
                        name: 'intentFeature',
                        value: row.intentFeature,
                      },
                    })
                  }
                >
                  <BorderOnHoverButton>
                    {truncateTextEnd(capitalizeText(row.intentFeature))}
                  </BorderOnHoverButton>
                </StyledTableCell>

                <StyledTableCell
                  align='left'
                  onClick={() =>
                    handleFilterChange({
                      target: {
                        name: 'supportImprovementCategory',
                        value: row.supportImprovementCategory,
                      },
                    })
                  }
                >
                  <BorderOnHoverButton>
                    {capitalizeText(row.supportImprovementCategory)}
                  </BorderOnHoverButton>
                </StyledTableCell>
                <StyledTableCell>
                  <MenuListComposition
                    application={application}
                    row={row}
                    onClick={r => {}}
                  />
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </Paper>
    </div>
  );
}

export default TicketsTable;
