import AppBase from './app/AppBase';
import AppKbSearch from './app/AppKbSearch';
import AppReamazeModule from './app/AppReamazeModule';
import './index.css';
import reportWebVitals from './reportWebVitals';
import React from 'react';
import ReactDOM from 'react-dom/client';

const root = ReactDOM.createRoot(document.getElementById('root'));

if (window.location.pathname.startsWith('/ui_applications/kb_search')) {
  root.render(<AppKbSearch />);
} else if (
  window.location.pathname.startsWith('/applications/chat/reamaze_module')
) {
  root.render(<AppReamazeModule />);
} else {
  root.render(<AppBase />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
