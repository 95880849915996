import { Breadcrumbs, Container, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

const ComingSoonPage = () => {
  return (
    <Container maxWidth='100%'>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Typography variant='h7' component='div'>
              Coming Soon
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={6}>
          <Typography className='header' alignContent='center'>
            Coming Soon
          </Typography>
        </Grid>

        {/* Existing Data Sources */}
        <div>
          <Typography className='subsubheader' mb={2} mt={2} ml={2}>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
            eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim
            ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut
            aliquip ex ea commodo consequat. Duis aute irure dolor in
            reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla
            pariatur. Excepteur sint occaecat cupidatat non proident, sunt in
            culpa qui officia deserunt mollit anim id est laborum.
          </Typography>
        </div>
      </Grid>
    </Container>
  );
};

export default ComingSoonPage;
