import { fetchArticle } from '../service/fetchArticle';
import { useSessionStorage } from '../utils/storage';
import { toastError } from '../utils/toastUI';
import { getUrlParam } from '../utils/utils';
import ContainerBox from './ContainerBox';
import {
  BoltOutlined,
  CleaningServicesOutlined,
  Edit,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useRef } from 'react';
import { removeStopwords } from 'stopword';
import { v4 as uuid } from 'uuid';

const BlogPanel = ({ sessionId, selectedSources }) => {
  const question = getUrlParam('question', '');
  const [searchValue, setSearchValue] = useSessionStorage(
    `search_value_${sessionId}`,
    `Create a knowledge base article on - ${question}`
  );
  const [systemPromptValue, setSystemPromptValue] = useSessionStorage(
    `system_prompt_${sessionId}`,
    ''
  );
  const [showSystemPrompt, setShowSystemPrompt] = useSessionStorage(
    `show_system_prompt_${sessionId}`,
    false
  );

  const [conversationId, setConversationId] = useSessionStorage(
    `conversationId_${sessionId}`,
    uuid()
  );

  const [keywords, setKeywords] = useSessionStorage(
    `keywords_${sessionId}`,
    question ? removeStopwords(question.split(' ')).join(',') : ''
  );

  const [results, setResults] = useSessionStorage(`results_${sessionId}`, []);
  const inputRef = useRef(null);
  const containerRef = useRef(null);

  const handleShowSystemPrompt = () => {
    setShowSystemPrompt(!showSystemPrompt);
  };

  const handleReset = () => {
    setResults([]);
    setConversationId(uuid());
  };

  const handleInputChange = e => {
    setSearchValue(e.target.value);
  };

  const handleSystemPromptInputChange = e => {
    setSystemPromptValue(e.target.value);
  };

  const handleKeywordInputChange = e => {
    setKeywords(e.target.value);
  };

  const handleSearch = async event => {
    event.preventDefault();

    if (!selectedSources || !selectedSources.length) {
      toastError('Select data sources');
      return;
    }

    const userQuestion = searchValue ?? '';
    const updatedResults = [
      ...results,
      {
        question: userQuestion,
        answer: '',
        placeholder: 'Creating blog post...',
      },
    ];

    setResults(updatedResults);

    setSearchValue('');

    await fetchArticle(
      userQuestion,
      keywords,
      selectedSources,
      conversationId,
      systemPromptValue,
      answer => {
        const lastResult = updatedResults[updatedResults.length - 1];
        lastResult.answer += answer;

        setResults([...updatedResults]);
      }
    );

    // setSearchValue('');
  };

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  return (
    <>
      <div
        className='wrk-panel'
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ContainerBox
          conversationId={conversationId}
          results={results}
          adjustHeight={true}
          heightRef={containerRef}
        />

        <form onSubmit={handleSearch}>
          <Box
            className='editor-options-box'
            display='flex'
            alignItems='center'
            px={0}
            py={2}
            ref={containerRef}
          >
            <Box
              width={'100%'}
              display='flex'
              flexDirection={'column'}
              justifyContent='stretch'
              px={0}
              py={0}
            >
              <TextField
                label='Custom system prompt'
                variant='outlined'
                multiline
                value={systemPromptValue}
                onChange={handleSystemPromptInputChange}
                sx={{ flexGrow: 1 }}
                className={`custom-system-prompt ${
                  showSystemPrompt ? '' : 'display-none'
                }`}
                required={false}
                size='small'
              />
              <Box
                width={'100%'}
                display='flex'
                justifyContent='stretch'
                flexDirection={'column'}
              >
                <TextField
                  label='Keywords e.g. product, feature, quality...'
                  placeholder='product, feature, quality...'
                  variant='outlined'
                  value={keywords}
                  onChange={handleKeywordInputChange}
                  sx={{ flexGrow: 1, marginBottom: '10px' }}
                  size='small'
                  inputRef={inputRef}
                  required={true}
                />
                <TextField
                  label='Ask e.g. Create a knowledge base article on - '
                  placeholder='Create a knowledge base article on -'
                  required={true}
                  variant='outlined'
                  value={searchValue}
                  onChange={handleInputChange}
                  // onKeyPress={handleKeyPress}
                  sx={{ flexGrow: 1 }}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position='end'>
                        <IconButton type='submit'>
                          <BoltOutlined sx={{ fontSize: 25 }} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  size='small'
                />
              </Box>
            </Box>
            <Box
              display='flex'
              justifyContent='stretch'
              flexDirection={'column'}
            >
              <Tooltip title='Start new topic'>
                <Button
                  className='save-button'
                  onClick={handleReset}
                  variant='contained'
                  startIcon={<CleaningServicesOutlined />}
                ></Button>
              </Tooltip>
              <Tooltip title='Specify custom system prompt'>
                <Button
                  className='save-button'
                  onClick={handleShowSystemPrompt}
                  variant='contained'
                  startIcon={<Edit />}
                ></Button>
              </Tooltip>
            </Box>
          </Box>
        </form>
      </div>
    </>
  );
};

export default BlogPanel;
