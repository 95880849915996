import { NewWorkflowContext } from '../Context';
import { AppsOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { Handle, Position } from 'reactflow';

function ApplicationSelectorNode(node) {
  const { data } = node || {};
  const { status, hasError } = data.state || {};
  const { onNodeSelect } = useContext(NewWorkflowContext);

  return (
    <div style={{ display: 'flex', width: '300px' }}>
      <Handle
        type='target'
        position={Position.Top}
        onConnect={params => console.log('handle onConnect', params)}
        isConnectable={true}
      />
      <Button
        style={{ border: 'none', textAlign: 'left' }}
        onClick={() => onNodeSelect(node)}
      >
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              height: 'auto',
              width: '270px',
              borderColor: hasError ? 'red !important' : '',
            }}
          >
            <div style={{ width: '50px' }}>
              <Typography
                className='card-title'
                style={{ transform: 'translate(-10px,-10px)' }}
              >
                {data.index}
              </Typography>
              <AppsOutlined
                sx={{
                  width: '55px',
                  height: '45px',
                  paddingRight: '20px',
                  color: '#444',
                }}
              />
            </div>
            <Box
              style={{
                display: 'flex',
                flexFlow: 'column',
                border: 'none',
                textAlign: 'left',
              }}
            >
              <Typography
                className='card-title'
                sx={{ alignSelf: 'flex-start' }}
              >
                {data.stepName || (
                  <i style={{ color: '#999' }}>Select Application</i>
                )}
              </Typography>
              <Typography
                className='card-sub-title'
                sx={{ alignSelf: 'flex-start' }}
              >
                {data.component}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Button>
      <div style={{ width: '25px' }}>
        {!hasError && status === 'busy' ? (
          <CircularProgress size={16} sx={{ ml: 1 }} />
        ) : (
          <></>
        )}
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        id={data.id}
        style={{ background: '#555' }}
        isConnectable={true}
      />
    </div>
  );
}

export default ApplicationSelectorNode;
