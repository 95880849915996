export const Template3 = {
  nodes: [
    {
      id: '1',
      type: 'triggerNode',
      data: {
        stepName: 'Every Week',
        component: 'schedule',
        scheduleId: 'weekly',
      },
    },
    {
      id: '2',
      type: 'applicationSelectorNode',
      data: {
        component: 'select_application',
        stepName: 'Select an Application',
      },
    },
    {
      id: '3',
      type: 'actionNode',
      data: {
        prompt:
          'Find tickets with a happiness score below X in the last week. Include subject, score, ticket url, and improvements.',
        stepName: 'Find tickets with happiness score below X',
        component: 'fetch_data',
      },
    },
    {
      id: '4',
      type: 'formatterNode',
      data: {
        component: 'format_data',
        stepName: 'Email report',
        prompt: `Craft an email report summarizing the tickets. Don't generate a subject. Use markdown. Use below format
@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@
Hello,

This is your automated guide providing a summary of tickets that have a low happiness score (<=9) 

| Subject   | Ticket Link  | Score  | Reason for Low Score                 |
|-----------|--------------|--------|--------------------------------------|
| [Subject] | [Ticket link]| [Score]| [Concise summary for low score]      |
| [Subject] | [Ticket link]| [Score]| [Concise summary for low score]      |
| ...       | ...          | ...    | ...                                  |
| [Subject] | [Ticket link]| [Score]| [Concise summary for low score]      |


Best regards,

The Kwazii CoPilot System
     
`,
      },
    },
    {
      id: '5',
      type: 'notifyNode',
      data: {
        component: 'send_email',
        subject: 'Weekly Low Happiness Score Report (<X)',
        stepName: 'Send Email',
      },
    },
  ],
};
