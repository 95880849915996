import { Divider, Grid, Typography } from '@mui/material';
import React from 'react';

const ReamazeHelpPane = () => {
  return (
    <Grid
      container
      spacing={3}
      ml={2}
      mr={1.5}
      pr={2}
      sx={{
        '*': { fontSize: '0.8rem !important' },
        background: '#fafafa',
        borderRadius: '10px',
      }}
    >
      <Grid item xs={12}>
        <Typography variant='h4'>Setting up Re:amaze</Typography>
        <Divider sx={{ mt: 1.5, mb: 0 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography>
          Re:amaze provides an API for programmatic access to certain data. API
          access is provided in a REST-like interface exposing Re:amaze
          resources in a JSON format.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
          <strong>Brand</strong>
        </Typography>
        <Typography>
          Each Re:amaze account may contain one or more brands and each brand's
          host domain can be found from the Brand Settings Page.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
          <strong>Email</strong>
        </Typography>
        <Typography>Login email for your Re:amaze account.</Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
          <strong>API Token</strong>
        </Typography>
        <Typography>
          Every user within an account has their own individual token. This
          allows the Re:amaze API to act on behalf of a specific user within
          your account.
          <br />
          <br />
          Getting your API Token
          <ol>
            <li>Go to Settings within your Re:amaze account.</li>
            <li>Click "API Token" under Developer.</li>
            <li>Click to "Generate New Token" to generate a unique token.</li>
          </ol>
        </Typography>
      </Grid>
      {/* Add more content here */}
    </Grid>
  );
};

export default ReamazeHelpPane;
