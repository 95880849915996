export const Template1 = {
  nodes: [
    {
      id: '1',
      type: 'triggerNode',
      data: {
        stepName: 'Every Month',
        component: 'schedule',
        scheduleId: 'monthly',
      },
    },
    {
      id: '2',
      type: 'applicationSelectorNode',
      data: {
        component: 'select_application',
        stepName: 'Select an Application',
      },
    },
    {
      id: '3',
      type: 'actionNode',
      data: {
        prompt:
          'Find the top 3 pain point categories that occurred in the last 1 month. For each identify the pain points',
        stepName: 'Trending pain points',
        component: 'fetch_data',
      },
    },
    {
      id: '4',
      type: 'formatterNode',
      data: {
        component: 'format_data',
        stepName: 'Trending pain points report',
        prompt: `Craft an email report summarizing the data. Clearly call out the category that is trending in percentages. Dont generate subject. Use markdown. Use below format
----------------
Hello,

We're reaching out with an automated report detailing the top three pain point categories identified from our data over the last month. For each category, we’ve listed specific pain points that may require attention. This information is crucial for enhancing our user experience and informing our continuous improvement efforts.

###  Top Pain Point Category: [Category Name #1]
Pain Point 1: [Detailed description]

Pain Point 2: [Detailed description]

...
Pain Point N: [Detailed description]


### Top Pain Point Category: [Category Name #2]
Pain Point 1: [Detailed description]

Pain Point 2: [Detailed description]

...
Pain Point N: [Detailed description]


### Top Pain Point Category: [Category Name #3]
Pain Point 1: [Detailed description]

Pain Point 2: [Detailed description]

...
Pain Point N: [Detailed description]


Each of these points provides a potential area for improvement that could significantly enhance the overall user experience and satisfaction with our products and services.

Warm regards,

The Kwazii CoPilot System
`,
      },
    },
    {
      id: '5',
      type: 'notifyNode',
      data: {
        component: 'send_email',
        stepName: 'Send Email',
      },
    },
  ],
};
