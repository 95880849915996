import { Divider, Grid, Link, Typography } from '@mui/material';
import React from 'react';

const ReamazeHelpPane = () => {
  return (
    <Grid
      container
      spacing={3}
      ml={2}
      mr={1.5}
      pr={2}
      pb={2}
      sx={{
        '*': { fontSize: '0.8rem !important' },
        background: '#fafafa',
        borderRadius: '10px',
      }}
    >
      <Grid item xs={12}>
        <Typography variant='h4'>Setting up Zendesk</Typography>
        <Divider sx={{ mt: 1.5, mb: 0 }} />
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
          <strong>Create an access token</strong>
        </Typography>
        <Typography>
          To get started using Zendesk, choose your authentication method and
          configure your integrations based on the instructions below.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
          <strong>Tenant</strong>
        </Typography>
        <Typography>
          Your organization subdomain {'(e.g. https://<tenant>.zendesk.com)'}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
          <strong>Username / Password Authentication</strong>
        </Typography>
        <ol>
          <li>
            Find your <strong>Zendesk tenant</strong>. It typically follows the
            format `https://your-tenant.zendesk.com`, where `your-tenant` is
            your Zendesk tenant.
          </li>
          <li>
            Copy and paste your <strong>username</strong> and{' '}
            <strong>password</strong> to configure your integration's
            connection.
          </li>
        </ol>
        {'To use basic authentication, password access must be enabled in '}
        <Link
          href='https://support.zendesk.com/hc/en-us/articles/4408889192858'
          target='_blank'
          rel='noopener noreferrer'
          sx={{ textDecoration: 'underline !important' }}
        >
          Admin Center
        </Link>
        {' interface at Apps and integrations > APIs > Zendesk API.'}
      </Grid>
      <Grid item xs={12}>
        <Typography paragraph>
          <strong>API Token Authentication</strong>
        </Typography>
        <ol>
          <li>
            Find your <strong>Zendesk tenant</strong>. It typically follows the
            format `https://your-tenant.zendesk.com`, where `your-tenant` is
            your Zendesk tenant.
          </li>
          <li>
            In{' '}
            <Link
              href='https://support.zendesk.com/hc/en-us/articles/4581766374554#topic_hfg_dyz_1hb'
              target='_blank'
              rel='noopener noreferrer'
              sx={{ textDecoration: 'underline !important' }}
            >
              Admin Center
            </Link>{' '}
            click <strong>Apps and integrations</strong>
          </li>
          <li>
            Under <strong>APIs</strong> select <strong>Zendesk API</strong>
          </li>
          <li>
            Click <strong>Add API token</strong>
          </li>
          <li>
            Copy the API token and configure your integration's connection as
            follows
            <ul>
              <li>
                <strong>Email</strong>: `your_email/token`
              </li>
              <li>
                <strong>Password</strong>: Your API token
              </li>
            </ul>
          </li>
        </ol>
        <Typography>
          Learn more about{' '}
          <Link
            href='https://developer.zendesk.com/api-reference/introduction/security-and-auth'
            target='_blank'
            rel='noopener noreferrer'
            sx={{ textDecoration: 'underline !important' }}
          >
            Zendesk API Authentication
          </Link>
        </Typography>
      </Grid>
      {/* Add more content here */}
    </Grid>
  );
};

export default ReamazeHelpPane;
