import VerticalTabs from '../../components/VerticalTabs';
import datasources from '../../service/datasources';
import { useSessionStorage } from '../../utils/storage';
import { toastError } from '../../utils/toastUI';
import {
  Breadcrumbs,
  Container,
  Grid,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const PlaygroundPage = () => {
  const [sources, setSources] = useState([]);
  const [selectedSources, setSelectedSources] = useSessionStorage(
    `playground_selected_sources`,
    []
  );

  // Fetch datasources
  useEffect(() => {
    datasources
      .list()
      .then(data => {
        setSources(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch data sources');
      });
  }, [setSources]);

  const handleSourceChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedSources(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  // const handleRuleChange = (event) => {
  //     const { target: { value } } = event;
  //     setselectedRules(
  //         // On autofill we get a stringified value.
  //         typeof value === 'string' ? value.split(',') : value,
  //     );
  // };

  return (
    <Container className='newMuiContainer-root'>
      <Grid container spacing={1} flexGrow={1} display={'flex'}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Typography variant='h7' component='div'>
              Playground
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Typography className='header' alignContent='center'>
            Playground
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography
            className='subheader-small bottom-padding-5 source-multiple-chip-parent'
            alignContent='center'
          >
            Test our semantic search, AI chatbot with your&nbsp;
            <Select
              id='source-multiple-chip'
              placeholder='Select'
              label='Source'
              displayEmpty
              multiple
              value={selectedSources}
              onChange={handleSourceChange}
              input={<OutlinedInput />}
              renderValue={selected => {
                if (selected.length === 0) {
                  return <em>data source</em>;
                }

                return selected.join(', ');
              }}
              inputProps={{ 'aria-label': 'Without label' }}
            >
              <MenuItem disabled value=''>
                <em>data sources</em>
              </MenuItem>
              {sources.map(({ name, ready }) => {
                return ready ? (
                  <MenuItem key={name} value={name}>
                    <span>{name}</span>
                  </MenuItem>
                ) : (
                  <MenuItem key={name} value={name} disabled>
                    <span style={{ textDecoration: 'line-through' }}>
                      {name}
                    </span>
                  </MenuItem>
                );
              })}
            </Select>
          </Typography>
        </Grid>

        <Grid
          item
          xs={12}
          sx={{
            flexFlow: 'row !important',
            alignItems: 'stretch',
            paddingTop: '10px !important',
            flexGrow: '1 !important',
            display: 'flex',
          }}
        >
          <VerticalTabs selectedSources={selectedSources}></VerticalTabs>
        </Grid>
      </Grid>
    </Container>
  );
};

export default PlaygroundPage;
