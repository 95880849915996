import workflows from '../../service/workflows';
import { toastError } from '../../utils/toastUI';
import WorkflowEmptyStateWithNew from './WorkflowEmptyStateWithNew';
import { Add } from '@mui/icons-material';
import {
  Breadcrumbs,
  Button,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Skeleton,
  Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React, { useEffect, useState } from 'react';
// Analytics, ChatBubble, Cloud, Language
import { Link as RouterLink } from 'react-router-dom';

const WorkflowPage = () => {
  const [sources, setSources] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch workflows
  useEffect(() => {
    const fetch = () => {
      workflows
        .list()
        .then(data => {
          setSources(data);
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to fetch data sources');
        })
        .finally(() => setLoading(false));
    };
    fetch();
  }, [setSources]);

  return (
    <Container maxWidth='100%'>
      <Grid container spacing={3} mb={5}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb'>
            <Link component={RouterLink} to='/home' color='inherit'>
              Home
            </Link>
            <Typography variant='h7' component='div'>
              Workflows
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={6}>
          <Typography className='header' alignContent='center'>
            {sources.length ? 'Workflows' : 'Create a Workflow'}
          </Typography>
        </Grid>
        <Grid item xs={6} textAlign='right'>
          {sources.length ? (
            <Button
              className='createButton'
              component={RouterLink}
              to='new'
              startIcon={<Add />}
              variant='contained'
            >
              Add
            </Button>
          ) : (
            <></>
          )}
        </Grid>

        {loading ? (
          <Grid item xs={12}>
            <Box>
              <Skeleton />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
              <Skeleton animation='wave' height={100} />
            </Box>
          </Grid>
        ) : sources.length ? (
          sources.map(workflow => (
            <Grid item xs={12} sm={6} md={4} lg={3} xl={3} key={workflow.id}>
              <Card
                component={RouterLink}
                to={`/workflow/design/${workflow.id}`}
                color='inherit'
              >
                <CardContent
                  sx={{ display: 'flex', flexFlow: 'row', height: '75px' }}
                >
                  <Link
                    component={RouterLink}
                    to={`/workflow/design/${workflow.id}`}
                    color='inherit'
                  >
                    <Typography className='card-title'>
                      {workflow.name}
                    </Typography>
                  </Link>
                </CardContent>
              </Card>
            </Grid>
          ))
        ) : (
          <WorkflowEmptyStateWithNew />
        )}
      </Grid>
    </Container>
  );
};

export default WorkflowPage;
