import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

const BorderOnHoverButton = styled(Button)`
  border: 0 !important;
  font-size: 0.9rem !important;
  font-weight: 400;
  background-color: transparent;
  transition: all 1s;
  position: relative;
  overflow: hidden;
  padding: 8px 16px;
  text-align: left;

  &:hover {
    text-decoration: none !important;
  }

  &::before,
  &::after {
    content: '';
    position: absolute;
    width: 0;
    height: 1px;
    background-color: black;
    transition: all 0.5s;
  }

  &::before {
    top: 0;
    left: 0;
  }

  &::after {
    bottom: 0;
    right: 0;
  }

  &:hover::before {
    width: 100%;
  }

  &:hover::after {
    width: 100%;
  }

  span {
    position: relative;
  }

  span::before,
  span::after {
    content: '';
    position: absolute;
    height: 0;
    width: 2px;
    background-color: black;
    transition:
      all 0.5s,
      width 0.5s 0.5s;
    transition-delay: 0.5s;
  }

  span::before {
    top: 0;
    left: 0;
  }

  span::after {
    bottom: 0;
    right: 0;
  }

  &:hover span::before {
    height: 100%;
  }

  &:hover span::after {
    height: 100%;
  }
`;

export default BorderOnHoverButton;
