import {
  ACCOUNT_INFO_KEY,
  ACCOUNT_TYPE,
  GLOBAL_LEFT_NAV_OPEN_STATE,
} from '../constants';
import auth from '../service/auth';
import theme from '../theme';
import { useLocalStorage } from '../utils/storage';
import './AppBase.scss';
import { InitialArea, LoginArea, MainArea } from './ContentArea';
import Navigation from './Navigation';
import RightPane from './RightPane';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { useMediaQuery } from '@mui/material';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import { ThemeProvider } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import * as React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const drawerWidth = 240;

const openedMixin = theme => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
  backgroundColor: 'rgb(250, 250, 250)',
});

const closedMixin = theme => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(7)} + 1px)`,
  },
  backgroundColor: 'rgb(250, 250, 250)',
});

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: prop => prop !== 'open',
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: 'nowrap',
  boxSizing: 'border-box',
  ...(open && {
    ...openedMixin(theme),
    '& .MuiDrawer-paper': openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    '& .MuiDrawer-paper': closedMixin(theme),
  }),
}));

function InnerApp() {
  const isMobile = useMediaQuery(theme => theme.breakpoints.down('md'));
  console.log(`isMobile: ${isMobile}`);

  // Page state
  const [open, setOpen] = useLocalStorage(GLOBAL_LEFT_NAV_OPEN_STATE, false);
  const [page, setPage] = useState('initial');

  // Account state
  const [userEmail, setUserEmail] = useState('');
  const [accountType, setAccountType] = useState(ACCOUNT_TYPE.TRIAL);
  // eslint-disable-next-line
  const [_, setAccountInfo] = useLocalStorage(ACCOUNT_INFO_KEY);

  // Auth data
  const token = localStorage.getItem('auth_token');

  useEffect(() => {
    auth
      .checkToken(token)
      .then(me => {
        setUserEmail(me.email);
        setAccountType(me.accountType);
        setAccountInfo(me);
        setPage('home');

        // This function creates visitors and accounts in Pendo
        // You will need to replace <visitor-id-goes-here> and <account-id-goes-here> with values you use in your app
        // Please use Strings, Numbers, or Bools for value types.
        window.pendo?.initialize({
          visitor: {
            id: me.id,
            email: me.email,
            // email:        // Recommended if using Pendo Feedback, or NPS Email */}
            // full_name:    // Recommended if using Pendo Feedback
            // role:         // Optional

            // You can add any additional visitor level key-values here,
            // as long as it's not one of the above reserved names.
          },

          account: {
            id: me.id,
            // name:         // Optional
            // is_paying:    // Recommended if using Pendo Feedback
            // monthly_value:// Recommended if using Pendo Feedback
            // planLevel:    // Optional
            // planPrice:    // Optional
            // creationDate: // Optional

            // You can add any additional account level key-values here,
            // as long as it's not one of the above reserved names.
          },
        });
      })
      .catch(() => {
        setPage('login');
      });
    // eslint-disable-next-line
  }, []);

  const handleDrawerOpen = () => {
    setOpen(true);
    window.dispatchEvent(new CustomEvent('left-nav-open'));
  };

  const handleDrawerClose = () => {
    setOpen(false);
    window.dispatchEvent(new CustomEvent('left-nav-close'));
  };

  if (page === 'initial') {
    return <InitialArea />;
  } else if (page === 'login') {
    return <LoginArea />;
  } else {
    return (
      <>
        <ToastContainer
          position='top-center'
          autoClose={2500}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='dark'
          style={{ minWidth: '500px' }}
        />

        <Box sx={{ display: 'flex' }} className='App'>
          <CssBaseline />
          <Drawer variant='permanent' open={open}>
            <Box>
              {open ? (
                <IconButton
                  className='drawer-close-button'
                  onClick={handleDrawerClose}
                >
                  <ChevronLeftIcon />
                </IconButton>
              ) : (
                <IconButton
                  className='drawer-open-button'
                  onClick={handleDrawerOpen}
                >
                  <ChevronRightIcon />
                </IconButton>
              )}
            </Box>
            <Navigation
              userEmail={userEmail}
              accountType={accountType}
              open={open}
            />
          </Drawer>

          <Box component='main' className='main-content'>
            <MainArea />
          </Box>
          <RightPane />
        </Box>
      </>
    );
  }
}

function AppBase() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <Router>
          <InnerApp></InnerApp>
        </Router>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default AppBase;
