import { getKeysEndpoint } from '../utils/utils';
import { handleCompletion } from './common';

/**
 * Creates a new apiKey.
 * @param {Object} apiKey - The apiKey object to create.
 * @returns {Promise<Object>} - A promise that resolves to the created apiKey object.
 * @throws {Error} - If the request fails.
 */
async function create(apiKey) {
  const token = localStorage.getItem('auth_token');
  const url = `${getKeysEndpoint()} `;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  // Convert apiKey object to JSON string
  var body = JSON.stringify(apiKey);

  var requestOptions = {
    method: 'POST',
    headers,
    body,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Retrieves a list of apiKeys.
 * @returns {Promise<Array>} - A promise that resolves to an array of apiKey objects.
 * @throws {Error} - If the request fails.
 */
async function list() {
  const token = localStorage.getItem('auth_token');
  const url = `${getKeysEndpoint()} `;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Retrieves a apiKey by its ID.
 * @param {string} apiKeyId - The ID of the apiKey to retrieve.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved apiKey object.
 * @throws {Error} - If the request fails.
 */
async function get(apiKeyId) {
  const token = localStorage.getItem('auth_token');
  const url = `${getKeysEndpoint()}/${apiKeyId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Removes a apiKey by its ID.
 * @param {string} apiKeyId - The ID of the apiKey to remove.
 * @returns {Promise<Object>} - A promise that resolves to the removed apiKey object.
 * @throws {Error} - If the request fails.
 */
async function remove(apiKeyId) {
  const token = localStorage.getItem('auth_token');
  const url = `${getKeysEndpoint()}/${apiKeyId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'DELETE',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

const fns = {
  list,
  get,
  create,
  remove,
};

export default fns;
