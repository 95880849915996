import { getWorkflowsEndpoint } from '../utils/utils';
import { handleCompletion } from './common';

/**
 * Creates a new workflow.
 * @param {Object} workflow - The workflow object to create.
 * @returns {Promise<Object>} - A promise that resolves to the created workflow object.
 * @throws {Error} - If the request fails.
 */
async function create(workflow) {
  const token = localStorage.getItem('auth_token');
  const url = `${getWorkflowsEndpoint()} `;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  // Convert workflow object to JSON string
  var body = JSON.stringify(workflow);

  var requestOptions = {
    method: 'POST',
    headers,
    body,
  };

  return handleCompletion(fetch(url, requestOptions));
}

async function execute(workflow, controller) {
  const token = localStorage.getItem('auth_token');
  const url = `${getWorkflowsEndpoint()}/execute`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  const signal = controller.signal;

  // Convert workflow object to JSON string
  var body = JSON.stringify(workflow);

  var requestOptions = {
    method: 'POST',
    headers,
    body,
    signal,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Updates exiting workflow.
 * @param {Object} application - The workflow object to update.
 * @returns {Promise<Object>} - A promise that resolves to the created application object.
 * @throws {Error} - If the request fails.
 */
async function update(workflow) {
  const token = localStorage.getItem('auth_token');
  const url = `${getWorkflowsEndpoint()}/${workflow.id}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  // Convert application object to JSON string
  var body = JSON.stringify(workflow);

  var requestOptions = {
    method: 'PUT',
    headers,
    body,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Retrieves a list of workflows.
 * @returns {Promise<Array>} - A promise that resolves to an array of workflow objects.
 * @throws {Error} - If the request fails.
 */
async function list() {
  const token = localStorage.getItem('auth_token');
  const url = `${getWorkflowsEndpoint()} `;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token} `,
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Retrieves a workflow by its ID.
 * @param {string} workflowId - The ID of the workflow to retrieve.
 * @returns {Promise<Object>} - A promise that resolves to the retrieved workflow object.
 * @throws {Error} - If the request fails.
 */
async function get(workflowId) {
  const token = localStorage.getItem('auth_token');
  const url = `${getWorkflowsEndpoint()}/${workflowId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'GET',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

/**
 * Removes a workflow by its ID.
 * @param {string} workflowId - The ID of the workflow to remove.
 * @returns {Promise<Object>} - A promise that resolves to the removed workflow object.
 * @throws {Error} - If the request fails.
 */
async function remove(workflowId) {
  const token = localStorage.getItem('auth_token');
  const url = `${getWorkflowsEndpoint()}/${workflowId}`;
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${token}`,
  };

  var requestOptions = {
    method: 'DELETE',
    headers,
  };

  return handleCompletion(fetch(url, requestOptions));
}

const fns = {
  list,
  get,
  create,
  update,
  remove,
  execute,
};

export default fns;
