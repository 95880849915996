import ContainerBox from '../../../../components/ContainerBox';
import { fetchSearchConversation } from '../../../../service/fetchSearch';
import { toastError } from '../../../../utils/toastUI';
import { Grid, Skeleton, Typography } from '@mui/material';
import Box from '@mui/material/Box';
import React, { useEffect, useState } from 'react';

function SearchConversation({ conversationId, applicationId }) {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchSearchConversation({ conversationId, applicationId })
      .then(data => {
        setResults(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch search conversation');
      })
      .finally(() => setLoading(false));
  }, [applicationId, conversationId, setResults]);

  return (
    <>
      <Box
        sx={{
          height: '35px',
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
          background: '#fafafa',
        }}
      >
        <Typography variant='h6' sx={{ fontWeight: 'bold', padding: '5px' }}>
          Conversation Thread
        </Typography>
      </Box>
      {loading ? (
        <Grid item xs={12} style={{ padding: '10px' }}>
          <Box>
            <Skeleton />
            <Skeleton animation='wave' height={100} />
            <Skeleton animation='wave' height={100} />
            <Skeleton animation='wave' height={100} />
          </Box>
        </Grid>
      ) : (
        <Box
          sx={{
            width: '100%',
            flexGrow: 1,
            display: 'flex',
            flexDirection: 'column',
            padding: '10px',
          }}
        >
          <div
            className='wrk-panel wrk-chat-panel'
            style={{
              height: 'calc(100vh - 35px)',
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            <ContainerBox
              conversationId={conversationId}
              results={results}
              adjustHeight={false}
              hideFeedback={true}
            />
          </div>
        </Box>
      )}
    </>
  );
}

export default SearchConversation;
