import EditableTypography from '../../components/EditableTypography';
import workflowService from '../../service/workflows';
import { toastError, toastSuccess } from '../../utils/toastUI';
import { getUrlParam } from '../../utils/utils';
import { NewWorkflowContext } from './Context';
import { CUSTOM_WORKFLOW_TEMPLATES, WORKFLOW_TEMPLATES } from './common';
import EdgeComponent from './edges/EdgeComponent';
import ActionPicker from './forms/ActionPicker';
import ApplicationSelector from './forms/ApplicationSelector';
import DispatchEmail from './forms/DispatchEmail';
import FetchData from './forms/FetchData';
import FormatData from './forms/FormatData';
import Scheduler from './forms/Scheduler';
import ActionNode from './nodes/ActionNode';
import ApplicationSelectorNode from './nodes/ApplicationSelectorNode';
import NotifyNode from './nodes/NotifyNode';
import TriggerNode from './nodes/TriggerNode';
import { markBusy, markFree, validateWorkflow } from './runner';
import {
  PlayArrowOutlined,
  PublishOutlined,
  StopCircleOutlined,
} from '@mui/icons-material';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  Divider,
  Grid,
  Link,
  Skeleton,
} from '@mui/material';
import { PrimeReactProvider } from 'primereact/api';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import ReactFlow, {
  Background,
  Controls,
  MarkerType,
  addEdge,
  useEdgesState,
  useNodesState,
} from 'reactflow';

const nodeTypes = {
  triggerNode: TriggerNode,
  applicationSelectorNode: ApplicationSelectorNode,
  actionNode: ActionNode,
  formatterNode: ActionNode,
  notifyNode: NotifyNode,
};

const edgeTypes = {
  addStepEdge: EdgeComponent,
};

const DesignWorkflowPage = () => {
  const templateId = Number(getUrlParam('template'));
  const { workflowId } = useParams();

  const [loading, setLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isRunning, setIsRunning] = useState(false);
  const [workflow, setWorkflow] = useState();
  const [title, setTitle] = useState('My workflow');
  const [NodeForm, setNodeForm] = useState(() => <></>);
  const [controller, setController] = useState(new AbortController());
  const [selectedEdge, setSelectedEdge] = useState();
  const [selectedNode, setSelectedNode] = useState();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);

  const onConnect = useCallback(
    params => setEdges(eds => addEdge(params, eds)),
    [setEdges]
  );

  const setNodeFormByComponent = component => {
    switch (component) {
      case 'schedule':
        return <Scheduler key={Date.now()} />;
      case 'select_application':
        return <ApplicationSelector key={Date.now()} />;
      case 'fetch_data':
        return <FetchData key={Date.now()} />;
      case 'format_data':
        return <FormatData key={Date.now()} />;
      case 'send_email':
        return <DispatchEmail key={Date.now()} />;
      default:
        return <></>;
    }
  };

  const updateNodesAndEdges = useCallback(
    updatedNodes => {
      for (let i = 0; i < updatedNodes.length; i++) {
        updatedNodes[i].data.index = i + 1;
        updatedNodes[i].position = { x: 250, y: i * 175 };
      }

      const updatedEdges = [];
      for (let i = 0; i < updatedNodes.length - 1; i++) {
        updatedEdges.push({
          id: `e${updatedNodes[i].id}-${updatedNodes[i + 1].id}`,
          source: updatedNodes[i].id,
          target: updatedNodes[i + 1].id,
          type: i === 0 ? '' : 'addStepEdge',
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 10,
            height: 10,
            color: '#666',
          },
          style: {
            strokeWidth: 2,
            stroke: '#666',
          },
        });
      }

      setNodes([...updatedNodes]);
      setEdges([...updatedEdges]);
    },
    [setEdges, setNodes]
  );

  const onNodeAddSelector = useCallback(
    edge => {
      setSelectedEdge(edge);
      if (edge) {
        setNodeForm(() => <ActionPicker />);
      }
    },
    [setSelectedEdge]
  );

  const onNodeSelect = useCallback(node => {
    setSelectedNode(node);
    setNodeForm(() => setNodeFormByComponent(node.data.component));
  }, []);

  const onNodeUpdate = useCallback(
    node => {
      const updatedNodes = nodes.map(n =>
        n.id === node.id ? { ...n, data: { ...n.data, ...node.data } } : n
      );
      setNodes(updatedNodes);
      setSelectedNode(node);
    },
    [nodes, setNodes]
  );

  const onNodeAdd = useCallback(
    node => {
      const index = nodes.findIndex(n => n.id === selectedEdge.source) + 1;
      nodes.splice(index, 0, node);

      updateNodesAndEdges(nodes);
      setSelectedEdge(null);
      setSelectedNode(node);
      onNodeSelect(node);
    },
    [
      updateNodesAndEdges,
      selectedEdge,
      nodes,
      setSelectedEdge,
      setSelectedNode,
      onNodeSelect,
    ]
  );

  const onNodeDelete = useCallback(
    node => {
      const updatedNodes = nodes.filter(n => n.id !== node.id);
      updateNodesAndEdges(updatedNodes);
    },
    [nodes, updateNodesAndEdges]
  );

  useEffect(() => {
    if (workflowId) {
      workflowService
        .get(workflowId)
        .then(workflow => {
          setWorkflow(workflow);
          setTitle(workflow.name);
          setNodes([...workflow.nodes]);

          const nodes = workflow.nodes;
          updateNodesAndEdges(nodes);
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to fetch data sources');
        })
        .finally(() => setLoading(false));
    } else {
      const template =
        WORKFLOW_TEMPLATES.find(wt => wt.template === templateId) ||
        CUSTOM_WORKFLOW_TEMPLATES[0];
      if (template) {
        setTitle(template.title);
        setNodes([...template.nodes]);

        const nodes = template.nodes;
        updateNodesAndEdges(nodes);
        setLoading(false);
      }
    }
  }, [workflowId, templateId, setNodes, updateNodesAndEdges]);

  const clearNodeState = () => {
    for (const node of nodes) {
      node.data.state = {};
      node.data.preview = {};
    }

    setNodes([...nodes]);
  };

  const onRun = () => {
    if (isRunning) {
      controller.abort();
      setIsSubmitting(false);
      setIsRunning(false);
    } else {
      setIsSubmitting(true);
      setIsRunning(true);

      clearNodeState();

      if (!validateWorkflow(nodes, onNodeSelect)) {
        setIsSubmitting(false);
        return;
      }

      markBusy(nodes, setNodes);
      let updatedNodes = nodes;

      workflowService
        .execute(
          {
            id: workflowId,
            name: title,
            nodes,
          },
          controller
        )
        .then(({ nodes }) => {
          updatedNodes = nodes;
          updateNodesAndEdges(nodes);
          toastSuccess(
            'Workflow successfully executed. Click on each node to view the result'
          );
        })
        .catch(error => {
          if (!controller.signal.aborted) {
            console.log('error', error.message);
            toastError(error.message ?? 'Failed to execute workflow');
          }
        })
        .finally(() => {
          setIsSubmitting(false);
          setIsRunning(false);
          setController(new AbortController());
          markFree(updatedNodes, setNodes);
        });
    }
  };

  const onPublish = () => {
    setIsSubmitting(true);
    clearNodeState();

    if (!validateWorkflow(nodes, onNodeSelect)) {
      setIsSubmitting(false);
      return;
    }

    if (!workflow) {
      workflowService
        .create({
          name: title,
          nodes,
        })
        .then(({ id }) => {
          toastSuccess('Workflow successfully created');
          window.location.href = `/workflow/design/${id}`;
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to create workflow');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    } else {
      workflowService
        .update({
          id: workflow.id,
          name: title,
          nodes,
        })
        .then(() => {
          toastSuccess('Workflow successfully updated');
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to update workflow');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  const onDelete = () => {
    setIsSubmitting(true);

    if (workflow) {
      workflowService
        .remove(workflow.id)
        .then(() => {
          toastSuccess('Workflow successfully deleted');
          window.location.href = `/workflow`;
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to delete workflow');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <PrimeReactProvider>
      <Container maxWidth='100%'>
        <Grid container spacing={2}>
          <Grid item xs={12} md={8} pl={3} pr={3} mb={1}>
            <Breadcrumbs aria-label='breadcrumb'>
              <Link component={RouterLink} to='/home' color='inherit'>
                Home
              </Link>
              <Link component={RouterLink} to='/workflow' color='inherit'>
                Workflows
              </Link>
              {!workflow ? (
                <Link component={RouterLink} to='/workflow/new' color='inherit'>
                  New Workflow
                </Link>
              ) : null}
              <EditableTypography
                value={title}
                variant='h7'
                component='div'
                onChange={val => setTitle(val)}
              ></EditableTypography>
            </Breadcrumbs>
          </Grid>

          <Grid item xs={12} md={4} textAlign='right'>
            <Button
              className='createButton'
              onClick={() => onRun()}
              style={{ fontSize: '0.8rem' }}
              startIcon={
                isRunning ? (
                  <StopCircleOutlined style={{ fontSize: '16px' }} />
                ) : (
                  <PlayArrowOutlined style={{ fontSize: '16px' }} />
                )
              }
              variant='contained'
            >
              {isRunning ? 'Stop' : 'Run'}
            </Button>
            &nbsp;&nbsp;&nbsp;
            <Button
              className='createButton'
              onClick={() => onPublish()}
              style={{ fontSize: '0.8rem' }}
              startIcon={<PublishOutlined style={{ fontSize: '16px' }} />}
              variant='contained'
              disabled={isSubmitting || isRunning}
            >
              Publish
            </Button>
            &nbsp;&nbsp;&nbsp;
            {workflow ? (
              <Button
                className='deleteButton'
                onClick={() => onDelete()}
                style={{ fontSize: '0.8rem' }}
                disabled={isSubmitting || isRunning}
              >
                Delete
              </Button>
            ) : (
              <></>
            )}
          </Grid>
          <Grid item xs={12}>
            <Divider></Divider>
          </Grid>

          {loading ? (
            <Grid item xs={12}>
              <Box>
                <Skeleton />
                <Skeleton animation='wave' height={100} />
                <Skeleton animation='wave' height={100} />
                <Skeleton animation='wave' height={100} />
              </Box>
            </Grid>
          ) : (
            <Grid
              item
              xs={12}
              style={{ height: 'calc(100vh - 56px)', padding: 0 }}
            >
              <NewWorkflowContext.Provider
                value={{
                  selectedEdge,
                  selectedNode,
                  onNodeAdd,
                  onNodeSelect,
                  onNodeAddSelector,
                  onNodeDelete,
                  onNodeUpdate,
                }}
              >
                <Splitter style={{ height: '100%', border: 0 }}>
                  <SplitterPanel
                    size={70}
                    className='flex align-items-center justify-content-center'
                  >
                    <ReactFlow
                      nodes={nodes}
                      edges={edges}
                      nodeTypes={nodeTypes}
                      edgeTypes={edgeTypes}
                      onPaneClick={() => setSelectedEdge(null)}
                      onNodesChange={onNodesChange}
                      onEdgesChange={onEdgesChange}
                      onConnect={onConnect}
                      nodesDraggable={true}
                      panOnDrag={true}
                      snapGrid={true}
                      maxZoom={1.2}
                      fitView
                    >
                      <Background variant='dots' gap={12} size={1} />
                      <Controls />
                    </ReactFlow>
                  </SplitterPanel>
                  <SplitterPanel
                    size={30}
                    className='flex align-items-center justify-content-center'
                  >
                    {NodeForm}
                  </SplitterPanel>
                </Splitter>
              </NewWorkflowContext.Provider>
            </Grid>
          )}
        </Grid>
      </Container>
    </PrimeReactProvider>
  );
};

export default DesignWorkflowPage;
