import React, { useEffect, useState } from 'react';

export default function TypingIndicator() {
  const [position, setPosition] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setPosition(prevPosition => (prevPosition + 1) % 3);
    }, 500); // Change the interval duration to control the speed of the animation

    return () => clearInterval(interval);
  }, []);

  return (
    <div style={{ display: 'flex', alignItems: 'center', margin: '5px 10px' }}>
      <div
        style={{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          backgroundColor: '#444',
          marginRight: '5px',
          opacity: position === 0 ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out',
        }}
      />
      <div
        style={{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          backgroundColor: '#444',
          marginRight: '5px',
          opacity: position === 1 ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out',
        }}
      />
      <div
        style={{
          width: '5px',
          height: '5px',
          borderRadius: '50%',
          backgroundColor: '#444',
          marginRight: '5px',
          opacity: position === 2 ? 1 : 0,
          transition: 'opacity 0.2s ease-in-out',
        }}
      />
    </div>
  );
}
