import analyticsService from '../../../service/analytics';
import { toastError } from '../../../utils/toastUI';
import DashboardOverview from './components/DashboardOverview';
import IntentDistribution from './components/IntentDistribution';
import QueriesTicketsTable from './components/QueriesTicketsTable';
import { Grid, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';

const SearchInsightsPage = ({ application, startDate, endDate, setRange }) => {
  const [allQuestionsData, setAllQuestionsData] = useState([]);
  const [questionsStat, setQuestionsStat] = useState([]);

  const [intentTreemapData, setIntentTreemapData] = useState([]);
  const [intentCategory, setIntentCategory] = useState({ category: '' });

  const [loading, setLoading] = useState(true);

  // Fetch analytics
  useEffect(() => {
    Promise.all([
      analyticsService.get(
        application?.id,
        'search',
        startDate.valueOf(),
        endDate.valueOf(),
        ''
      ),
      analyticsService.get(
        application?.id,
        'search',
        startDate.valueOf(),
        endDate.valueOf(),
        'questionsStat'
      ),
      analyticsService.get(
        application?.id,
        'search',
        startDate.valueOf(),
        endDate.valueOf(),
        'intentCategoryData'
      ),
    ])
      .then(results => {
        setAllQuestionsData(results[0]);
        setQuestionsStat(results[1]);
        setIntentTreemapData(results[2]);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch');
      })
      .finally(() => {
        setLoading(false);
      });
  }, [startDate, endDate, application]);

  // Fetch analytics
  useEffect(() => {
    setLoading(true);
  }, []);

  return loading ? (
    <Grid container xs={12}>
      <Grid item xs={12} ml={2}>
        <Skeleton />
        <Skeleton animation='wave' height={100} />
        <Skeleton animation='wave' height={100} />
        <Skeleton animation='wave' height={100} />
      </Grid>
    </Grid>
  ) : (
    <Grid container xs={12}>
      <DashboardOverview data={questionsStat} setRange={setRange} />

      <IntentDistribution
        intentTreemapData={intentTreemapData}
        selected={intentCategory?.category}
        setIntentCategory={category => setIntentCategory({ category })}
      />

      <QueriesTicketsTable
        application={application}
        key={Math.random()}
        data={allQuestionsData}
        selected={intentCategory?.category}
        setIntentCategory={category => setIntentCategory({ category })}
      />

      <Typography
        variant='small'
        style={{
          margin: '10px',
          marginLeft: '20px',
          fontSize: '0.75rem',
          fontWeight: 700,
        }}
      >
        * processed every 24hr
      </Typography>
    </Grid>
  );
};

export default SearchInsightsPage;
