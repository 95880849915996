import { Card, CardContent, Grid, Typography } from '@mui/material';
import React from 'react';

function Overview({ questions, trendingFeatureCategory }) {
  return (
    <>
      <Grid item xs={12} md={6}>
        <a href='#tickets' style={{ textDecoration: 'none' }}>
          <Card>
            <CardContent sx={{ height: '140px' }}>
              <Typography color='textSecondary' gutterBottom>
                # Support tickets
              </Typography>
              <Typography
                variant='h5'
                component='h2'
                sx={{ fontSize: '55px !important' }}
              >
                {questions?.length || 0}
              </Typography>
            </CardContent>
          </Card>
        </a>
      </Grid>

      <Grid item xs={12} md={6}>
        <a href='#tickets' style={{ textDecoration: 'none' }}>
          <Card>
            <CardContent sx={{ height: '140px' }}>
              <Typography color='textSecondary' gutterBottom>
                Trending Category
              </Typography>
              <Typography
                variant='h5'
                component='h2'
                sx={{ fontSize: '30px !important' }}
              >
                {trendingFeatureCategory[0]?.featureImprovementCategory}
              </Typography>
            </CardContent>
          </Card>
        </a>
      </Grid>
    </>
  );
}

export default Overview;
