import MarkdownComponent from './MarkdownComponent';
import { Box, Checkbox, FormControlLabel, FormGroup } from '@mui/material';
import React, { useState } from 'react';

const ConversationView = ({ data }) => {
  const [showImprovements, setShowImprovements] = useState(true);
  const [showReasoning, setShowReasoning] = useState(false);
  const [showImprovedAnswer, setShowImprovedAnswer] = useState(false);
  const improvementDisplayed = new Set();

  if (!data) {
    return null;
  }

  const renderMessage = (item, index) => {
    const isCustomer =
      item.persona.toLowerCase() === 'customer' ||
      item.persona.toLowerCase() === 'user';
    const isStaff = item.persona.toLowerCase() === 'staff';

    if (!isCustomer && !isStaff) {
      return null;
    }

    const personaLabel = isCustomer ? 'Customer:' : 'Staff:';
    const improvementDescription =
      item.improvement?.improvement_description?.toLowerCase();

    return (
      <div key={index} className='message-container'>
        {isCustomer && (
          <div className='question-box'>
            <div style={{ minWidth: '100px' }}>
              <MarkdownComponent content={personaLabel} />
            </div>
            <MarkdownComponent content={item.message} />
          </div>
        )}
        {isStaff && (
          <>
            <div className='answer-box'>
              <div style={{ minWidth: '100px' }}>
                <MarkdownComponent content={personaLabel} />
              </div>
              <MarkdownComponent content={item.message} />
            </div>
            {showImprovements &&
              improvementDescription !== 'n/a' &&
              improvementDescription?.length > 5 &&
              !improvementDisplayed.has(improvementDescription) && (
                <div className='improvements-box'>
                  <i>
                    <MarkdownComponent content={'Suggestion:'} />
                    <MarkdownComponent
                      content={item.improvement?.improvement_description}
                    />
                  </i>
                </div>
              )}
            {showReasoning &&
              improvementDescription !== 'n/a' &&
              improvementDescription?.length > 5 &&
              !improvementDisplayed.has(improvementDescription) && (
                <div className='improvements-box-why'>
                  <i>
                    <MarkdownComponent content={'Why:'} />
                    <MarkdownComponent
                      content={item.improvement?.improvement_how_could_helped}
                    />
                  </i>
                </div>
              )}
            {showImprovedAnswer &&
              improvementDescription !== 'n/a' &&
              improvementDescription?.length > 5 &&
              !improvementDisplayed.has(improvementDescription) && (
                <div className='improvements-box-ideal-response'>
                  <i>
                    <MarkdownComponent content={'Suggested Answer:'} />
                    <MarkdownComponent
                      content={item.improvement?.ideal_response_message}
                    />
                  </i>
                </div>
              )}
            {improvementDisplayed.add(improvementDescription || '') && ''}
          </>
        )}
      </div>
    );
  };

  return (
    <Box flexGrow={1} className='container-box analytics-conversation'>
      <FormGroup style={{ display: 'flex', flexFlow: 'row' }}>
        <FormControlLabel
          control={
            <Checkbox
              sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
              checked={showImprovements}
              onChange={() => setShowImprovements(!showImprovements)}
            />
          }
          label='Suggestion'
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
              checked={showReasoning}
              onChange={() => setShowReasoning(!showReasoning)}
            />
          }
          label='Reasoning'
        />
        <FormControlLabel
          control={
            <Checkbox
              sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
              checked={showImprovedAnswer}
              onChange={() => setShowImprovedAnswer(!showImprovedAnswer)}
            />
          }
          label='Suggested Answer '
        />
      </FormGroup>
      {data.map((item, index) => renderMessage(item, index))}
    </Box>
  );
};

export default ConversationView;
