import {
  capitalizeText,
  generateShades,
  remToPx,
  truncateText,
} from '../../../../utils/utils';
import { Card, CardContent, Grid, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { ResponsiveContainer, Tooltip, Treemap } from 'recharts';

const FeatureTreeMapChart = ({
  data: initialData,
  setFeatureCategory,
  selected,
}) => {
  localStorage.setItem('home_get_started_insights_visited', 'search');

  const [data, setData] = useState([]);
  const COLORS = generateShades('#666666', data?.length);

  useEffect(() => {
    setData(initialData.map(d => ({ name: d.name, size: d.size })));
  }, [initialData]);

  const onClick = node => {
    const newFeatureCategory = node?.root?.children?.[node.index]?.name;
    setFeatureCategory(
      selected === newFeatureCategory ? '' : newFeatureCategory
    );
  };

  const CustomizedContent = props => {
    const { x, y, width, height, name, index } = props;
    const maxLength = Math.floor(width / remToPx(0.85));

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            cursor: 'pointer',
            fill: selected
              ? selected?.toLowerCase() === name?.toLowerCase()
                ? COLORS[0]
                : COLORS[COLORS.length - 1]
              : COLORS[index],
          }}
        />
        <text
          x={x + width / 2}
          y={y + height / 2 + 7}
          textAnchor='middle'
          fill='white'
          fontSize={'0.85rem'}
          style={{ cursor: 'pointer' }}
        >
          {truncateText(capitalizeText(name), maxLength)}
        </text>
      </g>
    );
  };

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const dataPoint = payload[0].payload;
      return (
        <div className='chart custom-tooltip'>
          <b className='label'>{`${capitalizeText(dataPoint.name)}`}</b>&nbsp;
          <span className='label'>{`asked ${dataPoint.size} time${
            dataPoint.size > 1 ? 's' : ''
          }`}</span>
        </div>
      );
    }
    return null;
  };

  return (
    <ResponsiveContainer debounce='0' width='99%' height={450}>
      <Treemap
        data={data}
        dataKey='size'
        stroke='#fff'
        fill='#fefefe'
        content={<CustomizedContent />}
        isAnimationActive={false} // Disable animations for simplicity
        onClick={onClick}
      >
        <Tooltip
          content={<CustomTooltip />}
          cursor={{ strokeDasharray: '3 3' }}
        />
      </Treemap>
    </ResponsiveContainer>
  );
};

const FeatureDistribution = ({
  featureTreemapData,
  setFeatureCategory,
  selected,
}) => {
  return (
    <Grid item xs={12} md={12}>
      <Card>
        <CardContent>
          <Typography color='textSecondary' gutterBottom>
            Product Category Distribution*
          </Typography>
          <FeatureTreeMapChart
            data={featureTreemapData}
            setFeatureCategory={setFeatureCategory}
            selected={selected}
          />
        </CardContent>
      </Card>
    </Grid>
  );
};

export default FeatureDistribution;
