import { fetchAIAnswer } from '../service/fetchAIAnswer';
import { toastError } from '../utils/toastUI';
import ContainerBox from './ContainerBox';
import { BoltOutlined, CleaningServicesOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  InputAdornment,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

const AskAI = ({
  question,
  startDate,
  endDate,
  application,
  savedHistory,
  id,
}) => {
  const [searchValue, setSearchValue] = useState();
  const [conversationId, setConversationId] = useState(id || uuid());
  const [results, setResults] = useState([]);
  const inputRef = useRef(null);

  const handleReset = () => {
    setResults([]);
    setConversationId(uuid());
  };

  const handleSuggestions = async event => {
    setSearchValue(event.target.id);
    if (!application) {
      toastError('Application is missing');
      return;
    }

    setSearchValue('');
    await performSearch(event.target.id);
  };

  const handleInputChange = e => {
    setSearchValue(e.target.value);
  };

  const handleKeyPress = e => {
    if (e.key === 'Enter') {
      e.preventDefault();
      handleSearch(e);
    }
  };

  const handleSearch = async e => {
    e && e.preventDefault();

    if (!application) {
      toastError('Application is missing');
      return;
    }

    if (searchValue.trim() !== '') {
      const userQuestion = searchValue;
      setSearchValue('');
      await performSearch(userQuestion);
    }
  };

  const performSearch = async userQuestion => {
    const updatedResults = [
      ...results,
      {
        question: userQuestion,
        answer: '',
        placeholder: 'Thinking...',
        suggestions: [],
      },
    ];

    setResults([...updatedResults]);

    const { responseStream } = await fetchAIAnswer({
      userQuestion,
      startDate,
      endDate,
      application,
      conversationId,
      onData: answer => {
        const lastResult = updatedResults[updatedResults.length - 1];
        lastResult.answer += answer;
        setResults([...updatedResults]);
      },
    });

    await responseStream;
  };

  useEffect(() => {
    if (savedHistory && savedHistory.length) {
      setConversationId(savedHistory[savedHistory.length - 1].conversationId);
      setResults(savedHistory);
    }
  }, [savedHistory, setResults, setConversationId]);

  useEffect(() => {
    inputRef?.current?.focus();
  }, []);

  useEffect(() => {
    if (question) {
      performSearch(question);
    }
    // eslint-disable-next-line
  }, [question]);

  const handleGreetings = async event => {
    await performSearch(event.target.id);
  };

  return (
    <>
      <Box
        sx={{
          height: '35px',
          width: '100%',
          justifyContent: 'center',
          display: 'flex',
          background: '#fafafa',
        }}
      >
        <Typography variant='h6' sx={{ fontWeight: 'bold', padding: '5px' }}>
          Ask AI ⚡
        </Typography>
      </Box>

      <div
        className='wrk-panel'
        style={{
          height: 'calc(100vh - 35px)',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ContainerBox
          key={id}
          conversationId={conversationId}
          results={results}
          adjustHeight={false}
          onSuggestionClick={handleSuggestions}
        />

        {!results?.length && application?.greetingQuestions?.length ? (
          <Box
            display='flex'
            justifyContent={'center'}
            alignItems='center'
            flexWrap={'wrap'}
            px={2}
            py={1}
          >
            {application.greetingQuestions.map((greeting, index) => (
              <Button
                key={greeting}
                className='suggest-button-base suggest-button'
                id={greeting}
                value={index}
                onClick={handleGreetings}
              >
                {greeting}
              </Button>
            ))}
          </Box>
        ) : (
          <></>
        )}

        <form onSubmit={handleSearch}>
          <Box
            className='editor-options-box'
            display='flex'
            alignItems='center'
            px={1}
            py={2}
          >
            <Box
              width={'100%'}
              display='flex'
              flexDirection={'column'}
              justifyContent='stretch'
            >
              <TextField
                label='Ask AI'
                variant='outlined'
                value={searchValue}
                onChange={handleInputChange}
                onKeyPress={handleKeyPress}
                sx={{ flexGrow: 1 }}
                required={true}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position='end'>
                      <IconButton type='submit'>
                        <BoltOutlined sx={{ fontSize: 25 }} />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
                size='small'
                inputRef={inputRef}
              />
            </Box>
            <Tooltip title='Start new topic'>
              <Button
                className='save-button'
                onClick={handleReset}
                variant='contained'
                startIcon={<CleaningServicesOutlined />}
              ></Button>
            </Tooltip>
          </Box>
        </form>
      </div>
    </>
  );
};

export default AskAI;
