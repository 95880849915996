import ReamazeModuleChatApplicationPage from '../external_pages/reamaze/ReamazeModuleChatApplicationPage';
import theme from '../theme';
import './AppReamazeModule.scss';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';

<script type='text/javascript'>
  window.onload = function(){' '}
  {window.parent.postMessage(
    {
      message: 'reamaze:resize',
      height: '500px', //document.body.scrollHeight
    },
    '*'
  )}
  ;
</script>;

const InnerApp = () => {
  return (
    <div className='AppReamazeModule'>
      <Box sx={{ display: 'flex' }}>
        <Router>
          <Routes>
            <Route
              path='/applications/chat/reamaze_module'
              element={<ReamazeModuleChatApplicationPage />}
            />
            <Route path='*' element={<div></div>} />
          </Routes>
        </Router>
      </Box>
    </div>
  );
};

function AppReamazeModule() {
  return (
    <ThemeProvider theme={theme}>
      <InnerApp></InnerApp>
    </ThemeProvider>
  );
}

export default AppReamazeModule;
