import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@mui/material';
import React, { useState } from 'react';

const PromptDialog = ({ title, open, onClose, onConfirm }) => {
  const [inputValue, setInputValue] = useState('');

  const handleInputChange = event => {
    setInputValue(event.target.value);
  };

  const handleConfirm = () => {
    onConfirm(inputValue);
    setInputValue('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div style={{ width: '500px' }}>
        <DialogTitle>{title || 'Save as?'}</DialogTitle>
        <DialogContent sx={{ paddingTop: '10px !important' }}>
          <TextField
            autoFocus
            fullWidth
            value={inputValue}
            onChange={handleInputChange}
            label='Please enter the name'
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} color='primary'>
            Cancel
          </Button>
          <Button onClick={handleConfirm} color='primary'>
            OK
          </Button>
        </DialogActions>
      </div>
    </Dialog>
  );
};

export default PromptDialog;
