import ContainerBox from '../../components/ContainerBox';
import { fetchReamazeAdminAnswer } from '../../service/fetchReamazeAdminAnswer';
import { getUrlParam } from '../../utils/utils';
import { Box, Container } from '@mui/material';
import React, { useEffect, useState } from 'react';

const ReamazeModuleChatApplicationPage = () => {
  const [todos, setTodos] = useState({
    question: '#### Next Steps/Todo',
    answer: '',
    placeholder: 'Fetching...',
  });
  const [suggestedAnswers, setSuggestedAnswers] = useState({
    question: '#### AI bot response',
    answer: '',
    placeholder: 'Fetching...',
  });
  // const [painPoints, setPainPoints] = useState({ question: "#### Pain points (Customer)", answer: '', placeholder: 'Fetching...' });
  // const [improvements, setImprovements] = useState({ question: "#### Process/product improvements", answer: '', placeholder: 'Fetching...' });

  const applicationId = getUrlParam('applicationId');
  const apiKey = getUrlParam('apiKey');
  const slug = getUrlParam('slug');

  function resizeWidget() {
    window.parent.postMessage(
      { message: 'reamaze:resize', height: document.body.scrollHeight },
      '*'
    );
  }

  // Fetch reamaze conversation
  useEffect(() => {
    fetchReamazeAdminAnswer(applicationId, 'todos', slug, apiKey, answer => {
      todos.answer += answer;
      setTodos({ ...todos });
      resizeWidget();
    });

    fetchReamazeAdminAnswer(
      applicationId,
      'suggested_answer',
      slug,
      apiKey,
      answer => {
        suggestedAnswers.answer += answer;
        setSuggestedAnswers({ ...suggestedAnswers });
        resizeWidget();
      }
    );

    // fetchReamazeAdminAnswer(applicationId, "pain_points", slug, apiKey, (answer) => {
    //     painPoints.answer += answer;
    //     setPainPoints({ ...painPoints });
    //     resizeWidget();
    // });

    // fetchReamazeAdminAnswer(applicationId, "improvements", slug, apiKey, (answer) => {
    //     improvements.answer += answer;
    //     setImprovements({ ...improvements });
    //     resizeWidget();
    // });
    // eslint-disable-next-line
  }, []);

  return (
    <Container>
      <small
        style={{
          opacity: '0.7',
          textAlign: 'right',
          margin: '3px',
          display: 'block',
        }}
      >
        Powered by{' '}
        <a rel='noreferrer' href='https://kwazii.app' target='_blank'>
          Kwazii
        </a>
      </small>
      <div
        className='wrk-panel'
        style={{
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <ContainerBox
          conversationId={slug}
          results={[todos]}
          adjustHeight={false}
          section='todos'
        />
        <ContainerBox
          conversationId={slug}
          results={[suggestedAnswers]}
          adjustHeight={false}
          section='suggested_answer'
        />
        {/* <ContainerBox conversationId={slug} results={[painPoints]} adjustHeight={false} section='pain_points' />
        <ContainerBox conversationId={slug} results={[improvements]} adjustHeight={false} section='improvements' /> */}
      </div>
      <Box sx={{ p: 1 }} />
    </Container>
  );
};

export default ReamazeModuleChatApplicationPage;
