import AlphaNumericTextField from '../../../components/AlphaNumericTextField';
import MarkdownComponent from '../../../components/MarkdownComponent';
import { NewWorkflowContext } from '../Context';
import { Box, Grid, TextField, Typography } from '@mui/material';
import { Splitter, SplitterPanel } from 'primereact/splitter';
import React, { useContext, useState } from 'react';

const FormatData = () => {
  const { selectedNode, onNodeUpdate } = useContext(NewWorkflowContext);
  const [stepName, setStepName] = useState(selectedNode?.data?.stepName);
  const [prompt, setPrompt] = useState(selectedNode?.data?.prompt);

  const onUpdatePrompt = updatedPrompt => {
    setPrompt(updatedPrompt);

    const updatedNode = {
      ...(selectedNode || {}),
      data: {
        ...(selectedNode?.data || {}),
        state: {},
        prompt: updatedPrompt,
      },
    };

    onNodeUpdate(updatedNode);
  };

  const onUpdateStepName = updatedStepName => {
    setStepName(updatedStepName);

    const updatedNode = {
      ...(selectedNode || {}),
      data: {
        ...(selectedNode?.data || {}),
        stepName: updatedStepName,
      },
    };

    onNodeUpdate(updatedNode);
  };

  return (
    <Grid container xs={12} style={{ height: '100%' }}>
      <Grid item xs={12} style={{ height: '100%' }} mt={1}>
        <Splitter layout='vertical' style={{ height: '100%', border: 0 }}>
          <SplitterPanel
            className='flex align-items-center justify-content-center'
            style={{ overflow: 'scroll' }}
          >
            <Box sx={{ display: 'flex', flexDirection: 'column' }} ml={2}>
              <Typography>Name</Typography>
              <AlphaNumericTextField
                required
                fullWidth
                name='stepName'
                placeholder='Format previous results'
                sx={{ mb: 2 }}
                value={stepName}
                onChange={event => onUpdateStepName(event.target.value)}
              />
              <Typography mb={1.5}>Instruction Prompt</Typography>
              <TextField
                label='e.g. Craft an email with a subject...'
                placeholder='e.g. Craft an email with a subject...'
                required={true}
                multiline={true}
                minRows={7}
                variant='outlined'
                value={prompt}
                onChange={event => onUpdatePrompt(event.target.value)}
                size='small'
              />
              <br />
            </Box>
            <br />
          </SplitterPanel>
          <SplitterPanel
            className='flex align-items-center justify-content-center'
            style={{ overflow: 'scroll' }}
          >
            <MarkdownComponent
              content={selectedNode?.data?.preview?.response || ''}
            />
          </SplitterPanel>
        </Splitter>
      </Grid>
      <Grid item xs={12}>
        &nbsp;
      </Grid>
    </Grid>
  );
};

export default FormatData;
