import SearchResultsBox from '../../components/SearchResultsBox';
import applicationService from '../../service/applications';
import { fetchSearchUsingApiKey } from '../../service/fetchSearch';
import { useSessionStorage } from '../../utils/storage';
import {
  extractRelatedQuestions,
  getUrlParam,
  relatedQuestionsMatched,
} from '../../utils/utils';
import { Box, Button } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { v4 as uuid } from 'uuid';

const KbSearchApplicationPage = () => {
  const applicationId = getUrlParam('applicationId');
  const apiKey = getUrlParam('apiKey');
  const sessionId = getUrlParam('sessionId');

  const [searchValue, setSearchValue] = useState('');
  const [searchEnabled] = useState(!!applicationId);
  const [greetingQuestions, setGreetingQuestions] = useState([]);
  const [customStyle, setCustomStyle] = useState('');
  const [customPlaceholder, setCustomPlaceholder] = useState('');
  const [conversationId, setConversationId] = useState(uuid());
  const [results, setResults] = useSessionStorage(sessionId, []);
  const inputRef = useRef(null);

  useEffect(() => {
    inputRef.current.focus();
  }, []);

  useEffect(() => {
    if (applicationId) {
      applicationService
        .get2(applicationId, apiKey)
        .then(({ greetingQuestions, properties }) => {
          setGreetingQuestions(greetingQuestions);
          setCustomStyle(properties?.customStyle);
          setCustomPlaceholder(properties?.customPlaceholder);
        })
        .catch(error => {
          console.log('error', error.message);
        });
    }
  }, [applicationId, apiKey]);

  const resetResults = () => {
    setResults([]);
    setConversationId(uuid());
  };

  const handleSuggestions = async event => {
    setSearchValue(event.target.id);
    const updatedResults = [...results];
    updatedResults[event.target.value].suggestions = [];
    setResults(updatedResults);

    setSearchValue('');
    await performSearch(event.target.id);
  };

  const handleGreetings = async event => {
    setSearchValue(event.target.id);
    setGreetingQuestions([]);
    await performSearch(event.target.id);
    setSearchValue('');
  };

  const handleInputChange = event => {
    setSearchValue(event.target.value);
  };

  const handleKeyPress = event => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSearch();
    }
  };

  const handleSearch = async event => {
    event && event.preventDefault();

    if (searchValue.trim() !== '') {
      await performSearch(searchValue);
      setSearchValue('');
    }
  };

  const updateResults = updatedResults => {
    setResults([...updatedResults]);
  };

  const performSearch = async userQuestion => {
    const updatedResults = [
      ...results,
      {
        question: userQuestion,
        answer: '',
        placeholder: 'Thinking...',
        suggestions: [],
      },
    ];

    updateResults(updatedResults);

    let relatedQuestions = '';
    let relatedQuestionsFound = false;

    const { responseStream } = await fetchSearchUsingApiKey({
      apiKey,
      userQuestion,
      conversationId,
      applicationId,
      onData: answer => {
        if (!relatedQuestionsFound) {
          const lastResult = updatedResults[updatedResults.length - 1];
          lastResult.answer += answer;

          relatedQuestionsFound = relatedQuestionsMatched(lastResult.answer);
          if (relatedQuestionsFound) {
            const startIndex = lastResult.answer.indexOf('Related Questions');
            relatedQuestions = lastResult.answer.substring(startIndex);
            lastResult.answer = lastResult.answer.replace(relatedQuestions, '');
          }
        } else {
          relatedQuestions += answer;
        }

        updateResults(updatedResults);
      },
    });

    await responseStream;
    if (relatedQuestions) {
      const relatedQuestionsModified =
        extractRelatedQuestions(relatedQuestions);
      const lastResult = updatedResults[updatedResults.length - 1];
      lastResult.suggestions = relatedQuestionsModified;
      updateResults(updatedResults);
    }
  };

  return (
    <>
      <div style={{ display: 'none' }}>
        {/* The hidden div to hold the dynamically generated CSS */}
        <style>{customStyle}</style>
      </div>
      <div className='search-panel-base search-panel'>
        <form
          onSubmit={handleSearch}
          style={{ display: results.length ? 'none' : 'block' }}
        >
          <Box display='flex' alignItems='center' px={2} py={1}>
            <input
              className='input-box-base input-box'
              placeholder={customPlaceholder || 'Ask me anything...'}
              value={searchValue}
              onChange={handleInputChange}
              onKeyPress={handleKeyPress}
              style={{ flexGrow: 1 }}
              required={true}
              ref={inputRef}
              disabled={!searchEnabled}
            />
          </Box>
          <small
            style={{
              opacity: '0.7',
              textAlign: 'center',
              marginLeft: '20px',
              display: 'block',
            }}
          >
            Powered by{' '}
            <a rel='noreferrer' href='https://kwazii.app' target='_blank'>
              Kwazii
            </a>
          </small>
          {greetingQuestions.length ? (
            <Box
              display='flex'
              alignItems='center'
              flexWrap={'wrap'}
              px={2}
              py={1}
            >
              {greetingQuestions?.map((greeting, index) => (
                <Button
                  key={greeting}
                  className='suggest-button-base suggest-button'
                  id={greeting}
                  value={index}
                  onClick={handleGreetings}
                >
                  {greeting}
                </Button>
              ))}
            </Box>
          ) : (
            <></>
          )}
        </form>
        <Box display='flex' alignItems='center' px={0} py={0}>
          <SearchResultsBox
            apiKey={apiKey}
            searchValue={searchValue}
            searchEnabled={searchEnabled}
            conversationId={conversationId}
            results={results}
            onSuggestionClick={handleSuggestions}
            onResetClick={resetResults}
            onChange={handleInputChange}
            onKeyPress={handleKeyPress}
          />
        </Box>
      </div>
    </>
  );
};

export default KbSearchApplicationPage;
