export const CustomTemplate = {
  nodes: [
    {
      id: '1',
      type: 'triggerNode',
      data: {
        component: 'schedule',
      },
    },
    {
      id: '2',
      type: 'applicationSelectorNode',
      data: {
        component: 'select_application',
      },
    },
    {
      id: '3',
      type: 'actionNode',
      data: {
        component: 'fetch_data',
      },
    },
    {
      id: '4',
      type: 'notifyNode',
      data: {
        component: 'send_email',
      },
    },
  ],
};
