import { Button } from '@mui/base';
import { CopyAll } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React, { useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { prism } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeRenderer = ({ code, language }) => {
  const [copySuccess, setCopySuccess] = useState(false);

  return (
    <p style={{ position: 'relative', backgroundColor: 'none' }}>
      <CopyToClipboard
        text={code}
        onCopy={() => {
          setCopySuccess(true);
          console.log(copySuccess);
        }}
      >
        <Button
          style={{
            position: 'absolute',
            top: '10px',
            right: '10px',
            backgroundColor: 'transparent',
            border: 'none',
            cursor: 'pointer',
            zIndex: 1,
          }}
        >
          <IconButton className='copyAll'>
            <CopyAll />
          </IconButton>
        </Button>
      </CopyToClipboard>
      <SyntaxHighlighter style={prism} language={language}>
        {code}
      </SyntaxHighlighter>
      {copySuccess && <div>Copied!</div>}
    </p>
  );
};

export default CodeRenderer;
