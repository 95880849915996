import { NewWorkflowContext } from '../Context';
import { DeleteOutline, SplitscreenOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  IconButton,
  Typography,
} from '@mui/material';
import { useContext } from 'react';
import { Handle, Position } from 'reactflow';

function ActionNode(node) {
  const { data } = node || {};
  const { status, hasError } = data.state || {};
  const { onNodeSelect, onNodeDelete } = useContext(NewWorkflowContext);

  const onDelete = node => {
    const result = window.confirm('Are you sure you want to delete the node?');
    if (result) {
      onNodeDelete(node);
    }
  };

  return (
    <div style={{ display: 'flex', width: '300px' }}>
      <Handle
        type='target'
        position={Position.Top}
        onConnect={params => console.log('handle onConnect', params)}
        isConnectable={true}
      />
      <Button
        style={{ border: 'none', textAlign: 'left' }}
        onClick={() => onNodeSelect(node)}
      >
        <Card>
          <CardContent
            sx={{
              display: 'flex',
              flexFlow: 'row',
              alignItems: 'center',
              height: 'auto',
              width: '270px',
              borderColor: hasError ? 'red !important' : '',
            }}
          >
            <div style={{ width: '50px' }}>
              <Typography
                className='card-title'
                style={{ transform: 'translate(-10px,-10px)' }}
              >
                {data.index}
              </Typography>
              <SplitscreenOutlined
                sx={{
                  width: '55px',
                  height: '45px',
                  paddingRight: '20px',
                  color: '#444',
                }}
              />
            </div>
            <Box
              style={{
                display: 'flex',
                flexFlow: 'column',
                border: 'none',
                textAlign: 'left',
              }}
            >
              <Typography
                className='card-title'
                sx={{ alignSelf: 'flex-start' }}
              >
                {data.stepName || <i style={{ color: '#999' }}>Empty Step</i>}
              </Typography>
              <Typography
                className='card-sub-title'
                sx={{ alignSelf: 'flex-start' }}
              >
                {data.component}
              </Typography>
            </Box>
          </CardContent>
        </Card>
      </Button>
      <div style={{ width: '25px' }}>
        {!status ? (
          <IconButton onClick={() => onDelete(node)}>
            <DeleteOutline sx={{ fontSize: '18px', color: '#444' }} />
          </IconButton>
        ) : (
          <></>
        )}
        {!hasError && status === 'busy' ? (
          <CircularProgress size={16} sx={{ ml: 1 }} />
        ) : (
          <></>
        )}
      </div>
      <Handle
        type='source'
        position={Position.Bottom}
        id={data.id}
        style={{ background: '#555' }}
        isConnectable={true}
      />
    </div>
  );
}

export default ActionNode;
