import AlphaNumericTextField from '../../components/AlphaNumericTextField';
import datasources from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
import ZendeskHelpPane from './components/ZendeskHelpPane';
import {
  Box,
  Breadcrumbs,
  Button,
  Container,
  FormControl,
  FormControlLabel,
  FormHelperText,
  FormLabel,
  Grid,
  Link,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

const EditZendeskSupportDataSourcePage = () => {
  const { datasourceId } = useParams();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [submitType, setSubmitType] = useState('');
  const [datasourceName, setDatasourceName] = useState('');
  const [tenant, setTenant] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [cronSchedule, setCronSchedule] = useState('once');
  const [authOption, setAuthOption] = React.useState('0');

  // Fetch datasources
  useEffect(() => {
    datasources
      .get(datasourceId)
      .then(datasource => {
        setDatasourceName(datasource.name);
        setTenant(datasource.connectionInfo.tenant);
        setAuthOption(datasource.connectionInfo.authOption);
        setUsername(datasource.connectionInfo.username);
        setPassword(datasource.connectionInfo.password);
        setCronSchedule(datasource.cronSchedule);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch data sources');
      });
  }, [datasourceId]);

  const handleChangeAuthOption = event => {
    setAuthOption(event.target.value);
  };

  const handleSubmit = event => {
    event.preventDefault();

    if (submitType === 'delete') {
      const result = window.confirm('Are you sure you want to proceed?');
      if (result) {
        setIsSubmitting(true);

        datasources
          .remove(datasourceId)
          .then(() => {
            toastSuccess('Data source successfully marked for deletion');

            setTimeout(() => {
              window.location.href = '/data-sources';
            }, 500);
          })
          .catch(error => {
            console.log('error', error.message);
            toastError(error.message ?? 'Failed to delete data sources');
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      } else {
        // User clicked "Cancel"
        console.log('Cancelled.');
      }
    } else {
      setIsSubmitting(true);

      datasources
        .update({
          id: datasourceId,
          name: datasourceName,
          cronSchedule,
          connectionInfo: {
            authOption,
            tenant,
            username,
            password,
            cronSchedule,
          },
        })
        .then(() => {
          toastSuccess('Datasource successfully updated');
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to update datasource');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <Container className='newDSMuiContainer-root'>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
        <Link component={RouterLink} to='/' color='inherit'>
          Home
        </Link>
        <Link component={RouterLink} to='/data-sources' color='inherit'>
          Data Sources
        </Link>
        <Typography variant='body1' color='text.primary'>
          {datasourceName ?? 'Zendesk Support'}
        </Typography>
      </Breadcrumbs>
      <Grid item xs={12}>
        <Typography className='header' alignContent='center'>
          {datasourceName ?? 'Zendesk Support'}
        </Typography>
      </Grid>

      <Grid container xs={12}>
        <Grid item xs={12} sm={6} md={6} lg={5} xl={5} mb={4}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
              <Typography>Name</Typography>
              <AlphaNumericTextField
                required
                fullWidth
                name='projectName'
                placeholder='Application name'
                sx={{ mb: 2 }}
                value={datasourceName}
                onChange={event => setDatasourceName(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>Tenant</Typography>
              <TextField
                fullWidth
                name='tenant'
                placeholder='tenant (e.g. https://<tenant>.zendesk.com)'
                sx={{ mb: 2 }}
                value={tenant}
                onChange={event => setTenant(event.target.value)}
              />

              <FormControl sx={{ mt: 0.5, mb: 2 }}>
                <FormLabel id='zendesk-auth-option-controlled-radio-buttons-group'>
                  Select Authentication Method
                </FormLabel>
                <RadioGroup
                  aria-labelledby='zendesk-auth-option-controlled-radio-buttons-group'
                  name='zendesk-auth-option-controlled-radio-buttons-group'
                  value={authOption}
                  onChange={handleChangeAuthOption}
                >
                  <FormControlLabel
                    value='0'
                    control={<Radio />}
                    label='Username / Password Authentication'
                  />
                  <FormControlLabel
                    value='1'
                    control={<Radio />}
                    label='API Token Authentication'
                  />
                </RadioGroup>
              </FormControl>
              {authOption === '0' ? (
                <>
                  <Typography>Username</Typography>
                  <TextField
                    fullWidth
                    name='username'
                    placeholder='Username'
                    sx={{ mb: 2 }}
                    value={username}
                    onChange={event => setUsername(event.target.value)}
                  />

                  <Typography>Password</Typography>
                  <TextField
                    fullWidth
                    name='password'
                    placeholder='Password'
                    sx={{ mb: 2 }}
                    value={password}
                    type='password'
                    onChange={event => setPassword(event.target.value)}
                  />
                </>
              ) : (
                <>
                  <Typography>Email: {'{your_email}/token}'}</Typography>
                  <TextField
                    fullWidth
                    name='username'
                    placeholder='your_email}/token'
                    sx={{ mb: 2 }}
                    value={username}
                    onChange={event => setUsername(event.target.value)}
                  />

                  <Typography>Password: Your API token</Typography>
                  <TextField
                    fullWidth
                    name='password'
                    placeholder='Your API token'
                    sx={{ mb: 2 }}
                    value={password}
                    type='password'
                    onChange={event => setPassword(event.target.value)}
                  />
                </>
              )}

              <Typography>Cron schedule</Typography>
              <Select
                labelId='cron-schedule-label'
                name='cronSchedule'
                value={cronSchedule}
                onChange={event => setCronSchedule(event.target.value)}
                disabled={isSubmitting}
              >
                <MenuItem value='once'>Once</MenuItem>
                <MenuItem value='daily'>Daily</MenuItem>
                <MenuItem value='weekly'>Weekly</MenuItem>
                <MenuItem value='monthly'>Monthly</MenuItem>
              </Select>
              <FormHelperText sx={{ mb: 2 }}>
                &nbsp;&nbsp;&nbsp;&nbsp;The cron schedule determines when the
                scraping task will run.
              </FormHelperText>

              <Button
                type='submit'
                className='createButton'
                value='update'
                onClick={() => setSubmitType('update')}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Update'}
              </Button>
              <br />

              <Button
                type='submit'
                className='deleteButton'
                value='delete'
                onClick={() => setSubmitType('delete')}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Delete'}
              </Button>
            </Box>
          </form>
        </Grid>
        <Grid item xs={12} sm={5} md={5} lg={4} xl={3}>
          <ZendeskHelpPane></ZendeskHelpPane>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditZendeskSupportDataSourcePage;
