import AlphaNumericTextField from '../../components/AlphaNumericTextField';
import { DATASOURCE_TYPES } from '../../constants';
import applicationService from '../../service/applications';
import datasourceService from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
import { splitAndFilterEmptyLines } from '../../utils/utils';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

const NewCustomKBSearchApplicationPage = () => {
  const [sources, setSources] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [applicationName, setApplicationName] = useState('');

  const [systemPrompt, setSystemPrompt] = useState('');
  const [noKnowledgeAnswer, setNoKnowledgeAnswer] = useState('');

  const [greetingQuestions, setGreetingQuestions] = useState('');
  const [customPlaceholder, setCustomPlaceholder] = useState('');
  const [customStyle, setCustomStyle] = useState('');
  const [analytics, setAnalytics] = useState(true);
  const [selectedSources, setSelectedSources] = useState([]);

  // Fetch datasourceService
  useEffect(() => {
    datasourceService
      .list()
      .then(data => {
        setSources(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch data sources');
      });
  }, [setSources]);

  const handleSourceChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedSources(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSubmit = event => {
    event.preventDefault();

    setIsSubmitting(true);

    applicationService
      .create({
        name: applicationName,
        type: 'CUSTOM_SEARCH',
        systemPrompt,
        noKnowledgeAnswer,
        greetingQuestions: splitAndFilterEmptyLines(greetingQuestions),
        customStyle,
        analytics,
        customPlaceholder,
        datasourceNames: selectedSources,
        properties: {},
      })
      .then(({ applicationId }) => {
        toastSuccess('Application successfully created');

        window.location.href = `/applications/custom_search/${applicationId}`;
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to create application');
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  return (
    <Container className='newDSMuiContainer-root'>
      <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
        <Link component={RouterLink} to='/' color='inherit'>
          Home
        </Link>
        <Link component={RouterLink} to='/applications' color='inherit'>
          Applications
        </Link>
        <Link component={RouterLink} to='/applications/new' color='inherit'>
          New Application
        </Link>
        <Typography variant='body1' color='text.primary'>
          Custom KBSearch
        </Typography>
      </Breadcrumbs>
      <Grid item xs={12}>
        <Typography className='header' alignContent='center'>
          Custom KB Search
        </Typography>
      </Grid>

      <Grid container xs={12}>
        <Grid item xs={12} md={6} lg={5}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
              <Typography>Name*</Typography>
              <AlphaNumericTextField
                required
                fullWidth
                name='projectName'
                placeholder='Application name'
                sx={{ mb: 2 }}
                value={applicationName}
                onChange={event => setApplicationName(event.target.value)}
                disabled={isSubmitting}
              />

              <Typography>Data sources*</Typography>
              <Select
                id='source-multiple-chip'
                placeholder='Select'
                label='Source'
                displayEmpty
                multiple
                required
                value={selectedSources}
                onChange={handleSourceChange}
                input={<OutlinedInput />}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return '';
                  }

                  return selected.map(value => (
                    <Chip key={value} label={value} sx={{ mr: 1 }} />
                  ));
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value=''>
                  <em>data sources</em>
                </MenuItem>
                {sources
                  .filter(s =>
                    [
                      DATASOURCE_TYPES.WEB,
                      DATASOURCE_TYPES.S3,
                      DATASOURCE_TYPES.FILES,
                    ].includes(s.type)
                  )
                  .map(({ name, ready }) => {
                    return ready ? (
                      <MenuItem key={name} value={name}>
                        <span>{name}</span>
                      </MenuItem>
                    ) : (
                      <MenuItem
                        key={name}
                        value={name}
                        // disabled
                      >
                        <span>{name}</span>
                      </MenuItem>
                    );
                  })}
              </Select>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                      checked={analytics}
                      onChange={() => setAnalytics(!analytics)}
                    />
                  }
                  label='Enable Analytics'
                />
              </FormGroup>
              <br />

              <Typography>Greeting questions</Typography>
              <TextField
                placeholder='Greeting questions'
                variant='outlined'
                multiline
                value={greetingQuestions}
                onChange={event => setGreetingQuestions(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                minRows={5}
                required={false}
                size='small'
              />

              <Typography>Custom placeholder text</Typography>
              <TextField
                placeholder='Custom placeholder text'
                variant='outlined'
                value={customPlaceholder}
                onChange={event => setCustomPlaceholder(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                required={false}
                size='small'
              />

              <Typography>Custom No Knowledge answer</Typography>
              <TextField
                placeholder='Custom No Knowledge answer'
                variant='outlined'
                multiline
                value={noKnowledgeAnswer}
                onChange={event => setNoKnowledgeAnswer(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                minRows={10}
                required={false}
                size='small'
              />

              <Typography>Custom system prompt</Typography>
              <TextField
                placeholder='Custom system prompt'
                variant='outlined'
                multiline
                value={systemPrompt}
                onChange={event => setSystemPrompt(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                minRows={10}
                required={false}
                size='small'
              />
              <br />
              <Typography>Custom style</Typography>
              <TextField
                placeholder={`.input-box {
    border: 2px solid #ddd;
    background-color: #f5f5f5;
    color: #333;
    font-size: 16px;
    padding: 12px 20px;
    border-radius: 8px;
    }

.input-box:focus {
    outline: none;
    box-shadow: 0 0 5px rgba(61, 13, 150, 0.7);
}`}
                variant='outlined'
                multiline
                value={customStyle}
                onChange={event => setCustomStyle(event.target.value)}
                sx={{ flexGrow: 1, marginBottom: '15px' }}
                minRows={10}
                required={false}
                size='small'
              />
              <br />
              <Button
                type='submit'
                className='createButton'
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Create'}
              </Button>
              <br />
              <br />
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default NewCustomKBSearchApplicationPage;
