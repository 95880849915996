import applicationService from '../../service/applications';
import datasourceService from '../../service/datasources';
import { toastError, toastSuccess } from '../../utils/toastUI';
import {
  Box,
  Breadcrumbs,
  Button,
  Checkbox,
  Chip,
  Container,
  FormControlLabel,
  FormGroup,
  Grid,
  Link,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';

const EditHistoricalSupportDataApplicationPage = ({ leftNavOpen }) => {
  const { applicationId } = useParams();
  const [sources, setSources] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [applicationName, setApplicationName] = useState('');
  const [analytics, setAnalytics] = useState(true);
  const [enabled, setEnabled] = useState(true);
  const [selectedSources, setSelectedSources] = useState([]);
  const [submitType, setSubmitType] = useState('');

  // Fetch application
  useEffect(() => {
    if (applicationId) {
      applicationService
        .get(applicationId)
        .then(data => {
          setApplicationName(data.name);
          setAnalytics(!!data.analytics);
          setEnabled(!data.disabled);
          setSelectedSources(
            (data.datasourceNames.length && data.datasourceNames) ||
              data.selectedSources
          );
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to fetch application');
        });
    }
  }, [applicationId, setApplicationName, setSelectedSources]);

  // Fetch datasources
  useEffect(() => {
    datasourceService
      .list()
      .then(data => {
        setSources(data);
      })
      .catch(error => {
        console.log('error', error.message);
        toastError(error.message ?? 'Failed to fetch data sources');
      });
  }, [setSources]);

  const handleSourceChange = event => {
    const {
      target: { value },
    } = event;
    setSelectedSources(
      // On autofill we get a stringified value.
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (submitType === 'delete') {
      const result = window.confirm('Are you sure you want to proceed?');
      if (result) {
        setIsSubmitting(true);

        applicationService
          .remove(applicationId)
          .then(() => {
            toastSuccess('Application successfully deleted');

            setTimeout(() => {
              window.location.href = '/applications';
            }, 500);
          })
          .catch(error => {
            console.log('error', error.message);
            toastError(error.message ?? 'Failed to delete application');
          })
          .finally(() => {
            setIsSubmitting(false);
          });
      }
    } else {
      setIsSubmitting(true);

      applicationService
        .update({
          id: applicationId,
          datasourceNames: selectedSources,
          analytics,
          disabled: !enabled,
        })
        .then(() => {
          toastSuccess('Application successfully updated');
        })
        .catch(error => {
          console.log('error', error.message);
          toastError(error.message ?? 'Failed to update application');
        })
        .finally(() => {
          setIsSubmitting(false);
        });
    }
  };

  return (
    <Container
      className={
        leftNavOpen
          ? 'newDSMuiContainer-root-application-left-nav-open'
          : 'newDSMuiContainer-root-application'
      }
    >
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs aria-label='breadcrumb' sx={{ mb: 3 }}>
            <Link component={RouterLink} to='/' color='inherit'>
              Home
            </Link>
            <Link component={RouterLink} to='/applications' color='inherit'>
              Applications
            </Link>
            <Typography variant='body1' color='text.primary'>
              Historical Support Data
            </Typography>
          </Breadcrumbs>
        </Grid>

        <Grid item xs={12}>
          <Typography className='header' alignContent='center'>
            {applicationName || 'Custom KBSearch'}
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6}>
          <form onSubmit={handleSubmit}>
            <Box sx={{ display: 'flex', flexDirection: 'column' }} mt={1}>
              <Typography>Name*</Typography>
              <TextField
                fullWidth
                name='projectName'
                placeholder='Application name'
                sx={{ mb: 2 }}
                value={applicationName}
                onChange={event => setApplicationName(event.target.value)}
                disabled
              />

              <Typography>Data sources*</Typography>
              <Select
                id='source-multiple-chip'
                placeholder='Select'
                label='Source'
                displayEmpty
                required
                value={selectedSources}
                onChange={handleSourceChange}
                input={<OutlinedInput />}
                renderValue={selected => {
                  if (selected.length === 0) {
                    return '';
                  }

                  return selected.map(value => (
                    <Chip key={value} label={value} sx={{ mr: 1 }} />
                  ));
                }}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem disabled value=''>
                  <em>data sources</em>
                </MenuItem>
                {sources.map(({ name, ready }) => {
                  return ready ? (
                    <MenuItem key={name} value={name}>
                      <span>{name}</span>
                    </MenuItem>
                  ) : (
                    <MenuItem
                      key={name}
                      value={name}
                      // disabled
                    >
                      <span>{name}</span>
                    </MenuItem>
                  );
                })}
              </Select>
              <FormGroup>
                <FormControlLabel
                  disabled
                  control={
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                      checked={analytics}
                      onChange={() => setAnalytics(!analytics)}
                    />
                  }
                  label='Enable Analytics'
                />
              </FormGroup>
              <br />

              <FormGroup>
                <FormControlLabel
                  control={
                    <Checkbox
                      sx={{ '& .MuiSvgIcon-root': { fontSize: 23 } }}
                      checked={enabled}
                      onChange={() => setEnabled(!enabled)}
                    />
                  }
                  label='Enabled'
                />
              </FormGroup>
              <br />

              <Button
                type='submit'
                className='createButton'
                value='update'
                onClick={() => setSubmitType('update')}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Update'}
              </Button>
              <br />

              <Button
                type='submit'
                className='deleteButton'
                value='delete'
                onClick={() => setSubmitType('delete')}
                disabled={isSubmitting}
              >
                {isSubmitting ? 'Submitting...' : 'Delete'}
              </Button>
              <br />
              <br />
            </Box>
          </form>
        </Grid>
      </Grid>
    </Container>
  );
};

export default EditHistoricalSupportDataApplicationPage;
