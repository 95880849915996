import TextField from '@mui/material/TextField';
import React, { useState } from 'react';

const AlphaNumericTextField = props => {
  const [isValid, setIsValid] = useState(true);

  const handleInputChange = event => {
    const value = event.target.value;
    const alphanumericRegex = /^[a-zA-Z0-9 _-]*$/;
    setIsValid(alphanumericRegex.test(value));
    if (props.onChange) {
      props.onChange(event);
    }
  };

  return (
    <TextField
      {...props}
      inputProps={{
        pattern: '^[a-zA-Z0-9 _-]*$', // For mobile browsers support
        onChange: handleInputChange,
      }}
      error={!isValid}
      helperText={!isValid && 'Please enter only alphanumeric characters.'}
    />
  );
};

export default AlphaNumericTextField;
