import { MoreVert } from '@mui/icons-material';
import { Divider, IconButton } from '@mui/material';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import MenuItem from '@mui/material/MenuItem';
import MenuList from '@mui/material/MenuList';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import * as React from 'react';

// import { useNavigate } from 'react-router-dom';

export default function MenuListComposition({ application, row, onClick }) {
  // const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleClose = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    onClick(row);
  };

  const handleTicketInsights = event => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
    onClick(row);

    window.dispatchEvent(new CustomEvent('info-pane-open'));

    var customEvent = new CustomEvent('render-info-pane', {
      detail: {
        type: 'ticket-insights',
        application: application,
        data: row,
      },
    });
    // Dispatch the custom event on the window
    window.dispatchEvent(customEvent);

    // navigate(`/playground?tab=0&application=${row.applicationId}&question=${row.question}`);
  };

  // const handleWorkflow = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpen(false);
  //   onClick(row);

  //   navigate(`/workflow?application=${application.id}&id=${row.id}`);
  // };

  // const handleComingSoon = (event) => {
  //   if (anchorRef.current && anchorRef.current.contains(event.target)) {
  //     return;
  //   }

  //   setOpen(false);
  //   alert("Coming Soon");
  // };

  React.useEffect(() => {
    function handleListKeyDown(event) {
      if (event.key === 'Tab') {
        event.preventDefault();
        setOpen(false);
      } else if (event.key === 'Escape') {
        setOpen(false);
      }
    }
    document.addEventListener('keydown', handleListKeyDown);

    return () => {
      document.removeEventListener('keydown', handleListKeyDown);
    };
  });

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <div>
      <IconButton
        ref={anchorRef}
        id='composition-button'
        aria-controls={open ? 'composition-menu' : undefined}
        aria-expanded={open ? 'true' : undefined}
        aria-haspopup='true'
        onClick={handleToggle}
      >
        <MoreVert />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        placement='bottom-start'
        transition
        disablePortal
        sx={{ zIndex: 1000000 }}
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === 'bottom-start' ? 'left top' : 'left bottom',
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList
                  autoFocusItem={open}
                  id='composition-menu'
                  aria-labelledby='composition-button'
                  sx={{
                    boxShadow: '0 0 10px #eee',
                    background: 'white',
                    zIndex: 100000,
                  }}
                >
                  <MenuItem onClick={handleTicketInsights}>
                    View Insights
                  </MenuItem>
                  <Divider></Divider>
                  {/* <MenuItem onClick={handleWorkflow}>Open in Workflow</MenuItem>
                  <Divider></Divider> */}
                  <MenuItem onClick={handleClose}>Cancel</MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
}
